import { useEffect, useState } from "react";
import api from "@services/api";
import { Mixpanel } from "@services/mixpanel";
import Loader from "@components/utils/Loader";
import { useTranslation } from "react-i18next";
import { toast } from "react-hot-toast";
import { MIXPANEL_EVENTS } from "../../../utils/mixpanelEvents";
import { useSelector } from "react-redux";

const PURCHASE_INVOICES = "PURCHASE_INVOICES";

const Invoices = ({ setTab, setNewDataCount }) => {
  const { t } = useTranslation();
  const [invoices, setInvoices] = useState();
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [loading, setLoading] = useState(false);
  const { organization } = useSelector((state) => state.Auth);

  useEffect(() => {
    try {
      const syncInvoices = async () => {
        setLoading(true);
        const res = await api.get(`/xero/invoices-sales/`);
        setLoading(false);
        setInvoices(res.data);
      };
      syncInvoices();
    } catch (error) {
      setLoading(false);
      toast.error(t("toast.error.invoices_imported"));
    }
  }, []);

  useEffect(() => {
    setSelectedInvoices(invoices || []);
  }, [invoices]);

  const importInvoices = async () => {
    try {
      setLoading(true);
      const res = await api.post("/xero/invoices/convert-link", { invoices: selectedInvoices });
      const { data } = await api.post(`/file/multiple`, { files: res.data, folder: "invoices" });
      const response = await api.post("/xero/invoices-sales/import", { invoices: selectedInvoices, files: data, OrganizationId: organization?._id });

      setNewDataCount((prevData) => ({
        ...prevData,
        newInvoices: response.count,
      }));

      toast.success(t("toast.sucess.invoices_imported"));
      setLoading(false);
      setTab(PURCHASE_INVOICES);
    } catch (error) {
      toast.error(t("toast.error.invoices_imported"));
      setLoading(false);
    }
  };

  if (loading) return <Loader />;
  return (
    <div className="border rounded-md mt-4  ">
      <div className="flex flex-col justify-between px-3.5 py-3.5 cursor-pointer">
        <div className="flex  justify-between  ">
          <div className="flex items-center gap-2 w-full">
            <div className="text-dull-black text-xl font-bold "> {t("sales_invoices")}</div>
          </div>

          <div className="w-full flex items-center justify-end gap-2">
            <button
              className={`flex-1 max-w-[10rem] btn-secondary btn-small`}
              onClick={() => {
                setTab(PURCHASE_INVOICES);
                Mixpanel.track(MIXPANEL_EVENTS["btn_skip_xero-invoices"]);
              }}>
              {t("skip")}
            </button>
            <button
              disabled={selectedInvoices?.length === 0}
              className={`flex-1 max-w-[10rem] btn-secondary btn-small`}
              onClick={async (e) => {
                e.stopPropagation();
                await importInvoices();
                Mixpanel.track(MIXPANEL_EVENTS["btn_import_xero-invoices"]);
              }}>
              {t("import_xero.import_invoices", { invoicesLenght: selectedInvoices.length })}
            </button>
          </div>
        </div>
      </div>

      {!invoices || invoices.length === 0 ? (
        <div className="px-3.5 py-3.5"> {t("import_xero.no_invoices")} </div>
      ) : (
        <div className="overflow-x-auto my-4 flow-root  align-middle overflow-hidden rounded-lg border mx-3">
          <table className="min-w-full">
            <thead className="border-b">
              <tr>
                <th scope="col" className="w-2 px-2 py-2">
                  <input
                    type="checkbox"
                    checked={selectedInvoices.length === invoices.length}
                    onClick={() => {
                      if (selectedInvoices.length === invoices.length) {
                        setSelectedInvoices([]);
                      } else {
                        setSelectedInvoices(invoices);
                      }
                    }}
                  />
                </th>
                <th scope="col" className="whitespace-nowrap px-2 py-2 text-left text-sm font-bold text-gray-900 uppercase">
                  {t("type")}
                </th>
                <th scope="col" className="whitespace-nowrap px-2 py-2text-left text-sm font-bold text-gray-900 uppercase">
                  {t("reference")}
                </th>
                <th scope="col" className="whitespace-nowrap px-2 py-2 text-left text-sm font-bold text-gray-900 uppercase">
                  {t("status")}
                </th>
                <th scope="col" className="whitespace-nowrap px-2 py-2 text-left text-sm font-bold text-gray-900 uppercase">
                  {t("date")}
                </th>
                <th scope="col" className="whitespace-nowrap px-2 py-2 text-left text-sm font-bold text-gray-900 uppercase">
                  {t("due_date")}
                </th>
                <th scope="col" className="whitespace-nowrap px-2 py-2 text-left text-sm font-bold text-gray-900 uppercase">
                  {t("contact")}
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {invoices.map((invoice, index) => (
                <tr
                  key={index}
                  className="hover:bg-[#dee2e6]"
                  onClick={() => {
                    if (selectedInvoices.find((c) => c.invoiceID === invoice.invoiceID)) {
                      setSelectedInvoices(selectedInvoices.filter((c) => c.invoiceID !== invoice.invoiceID));
                    } else {
                      setSelectedInvoices([...selectedInvoices, invoice]);
                    }
                  }}>
                  <td className="whitew-2 px-2 py-2">
                    <input type="checkbox" checked={selectedInvoices.find((c) => c.invoiceID === invoice.invoiceID)} />
                  </td>
                  <td className="whitespace-nowrap px-2  text-sm">{invoice.type}</td>
                  <td className="whitespace-nowrap px-2  text-sm">{invoice.invoiceNumber}</td>
                  <td className="whitespace-nowrap px-2  text-sm">{invoice.status}</td>
                  <td className="whitespace-nowrap px-2  text-sm">{invoice.date}</td>
                  <td className="whitespace-nowrap px-2  text-sm">{invoice.dueDate}</td>
                  <td className="whitespace-nowrap px-2  text-sm">{invoice.contact.name}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Invoices;
