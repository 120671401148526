import { useState, useEffect } from "react";
import { ResponsiveBar } from "@nivo/bar";
import api from "@services/api";
import Loader from "@components/utils/Loader";
import { currencyToStr, getAccountName } from "@utils";
import { useTranslation } from "react-i18next";
import { Select } from "../../components/utils/Select";
import { Mixpanel } from "../../services/mixpanel";
import StripeLogo from "../../assets/stripe-logo.png";
import { FaCashRegister, FaTrophy } from "react-icons/fa6";
import { MIXPANEL_EVENTS } from "../../utils/mixpanelEvents";
import { useSelector } from "react-redux";

const List = () => {
  useEffect(() => {
    Mixpanel.track(MIXPANEL_EVENTS.page_view_analytics_accounting);
  }, []);
  const { organization } = useSelector((state) => state.Auth);

  return (
    <div>
      <div className="grid grid-cols-1">
        <div className="flex gap-10 overflow-hidden">
          <AccountsTotalPerCurrency organization={organization} />
          <StripeBalances organization={organization} />
          <AccountsBalance organization={organization} />
          <PettyCash organization={organization} />
        </div>
      </div>
      <CashFlow organization={organization} />
    </div>
  );
};

const AccountsBalance = ({ organization }) => {
  const [accounts, setAccounts] = useState([]);

  useEffect(() => {
    if (!organization) return;
    const getAccounts = async () => {
      const { data } = await api.post("/account/search", { OrganizationId: organization._id });
      setAccounts(data);
    };
    getAccounts();
  }, [organization]);

  if (!accounts) return <Loader />;

  return (
    <div className="flex gap-4 pb-3 overflow-x-auto">
      {accounts.map((account) => {
        return (
          <div key={account._id} className="border border-black-20 bg-white rounded-lg p-5 h-36">
            <div className="flex justify-between items-center flex-col h-full">
              <div className="flex justify-center items-center gap-2">
                <img src={account?.Requisition?.NordigenInstitution?.logo} width={20} height={20} />
                <div>{account.Requisition.NordigenInstitution.name}</div>
              </div>
              <div className="text-xl font-bold">{account.amount + currencyToStr(account.currency)}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const StripeBalances = ({ organization }) => {
  const [accounts, setAccounts] = useState([]);

  useEffect(() => {
    if (!organization) return;
    const getAccounts = async () => {
      const { data } = await api.post("/stripe/requisitions/search", { OrganizationId: organization._id });
      setAccounts(data);
    };
    getAccounts();
  }, [organization]);

  if (!accounts) return <Loader />;
  return (
    <div className="flex gap-4 pb-3">
      {accounts.map((account) => (
        <div key={account._id} className="border border-black-20 bg-white rounded-lg w-max p-5 h-36">
          <div className="flex justify-between items-center flex-col h-full">
            <div className="flex justify-center items-center gap-2">
              <img src={StripeLogo} width={20} height={20} />
              <div>{account.name}</div>
            </div>
            <div className="text-xl font-bold">{account.amount + currencyToStr(account.currency)}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

const AccountsTotalPerCurrency = ({ organization }) => {
  const { t } = useTranslation();
  const [totalPerCurrency, setTotalPerCurrency] = useState([]);

  useEffect(() => {
    if (!organization) return;
    const get = async () => {
      const { data } = await api.post("/analytics/balances", { OrganizationId: organization._id });
      setTotalPerCurrency(data);
    };
    get();
  }, [organization]);

  if (!totalPerCurrency) return <Loader />;
  return (
    <div className="border border-black-20 bg-white rounded-lg flex flex-col justify-between p-4 w-max h-36">
      <div className="text-sm"> {t("analytics.total_cash_bank")}</div>

      <div className="flex gap-7">
        {totalPerCurrency.map((item) => (
          <div key={item.currency} className="text-2xl font-semibold">
            {item.amount + currencyToStr(item.currency)}
          </div>
        ))}
      </div>
    </div>
  );
};

const PettyCash = ({ organization }) => {
  const { t } = useTranslation();
  const [pettyCash, setpettyCash] = useState(null);

  useEffect(() => {
    if (!organization) return;
    const getPettyCash = async () => {
      try {
        const { data, ok } = await api.post("/analytics/pettycash", { OrganizationId: organization._id });
        if (!ok) return setpettyCash(null);
        setpettyCash(data);
      } catch (e) {
        console.log(e);
        setpettyCash(null);
      }
    };
    getPettyCash();
  }, [organization]);

  if (!pettyCash) return null;

  return (
    <div className="flex gap-4 pb-3">
      <div className="border border-black-20 bg-white rounded-lg w-max p-5 h-36">
        <div className="flex justify-between items-center flex-col h-full">
          <div className="flex justify-center items-center gap-2">
            <FaCashRegister />
            <div>{t("petty_cash")}</div>
          </div>
          <div className="text-xl font-bold">
            <div key={pettyCash.amount}>
              {pettyCash.amount}
              {currencyToStr(pettyCash.currency)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CashFlow = ({ organization }) => {
  const { t } = useTranslation();
  const [data, setData] = useState(null);
  const [accounts, setAccounts] = useState([]);

  const [filter, setFilter] = useState({
    accountsId: [],
  });

  useEffect(() => {
    if (!organization) return;
    const getAccounts = async () => {
      const { data } = await api.post("/account/search", { OrganizationId: organization._id });
      setAccounts(data);
    };
    getAccounts();
  }, [organization]);

  useEffect(() => {
    if (!organization) return;
    get();
  }, [filter.accountsId, organization]);

  async function get() {
    const res = await api.post("/analytics/cashflow", {
      accountsId: filter.accountsId,
      OrganizationId: organization._id,
    });
    const result = res.data.map((e) => {
      const percent = ((parseFloat(e.Benefit) / parseFloat(e.Revenue)) * 100).toFixed(2);
      return { ...e, Month: `${e.Month} (${percent}%)` };
    });

    setData(result);
  }

  if (!data) return <Loader />;

  return (
    <div className="mt-6">
      <div className="h-[408px] bg-white mt-4 p-2 rounded-xl py-6 border border-black-20">
        <div className="flex gap-8 items-center">
          <div className="text-xl font-bold">{t("analytics.cashflow")}</div>
          <div className="w-full md:w-60 ">
            <Select
              options={accounts}
              onChange={(selectedOptions) => {
                setFilter({ accountsId: selectedOptions.map((option) => option._id) });
              }}
              placeholder={t("analytics.choose_bank")}
              value={accounts.filter((option) => filter.accountsId.includes(option._id))}
              multiple
              getLabel={(option) => <AccountOption account={option} />}
            />
          </div>
        </div>

        <Graph data={data} keys={["Revenue", "Cost", "Benefit"]} legendY={t("cashflow")} />
      </div>
    </div>
  );
};

const AccountOption = ({ account }) => {
  const logo = account?.Requisition?.NordigenInstitution?.logo;

  return (
    <span className="flex">
      {logo && <img src={logo} height={20} width={20} />}
      <span className="ml-2">{getAccountName(account)}</span>
      <span className="ml-1">
        {account.amount} {currencyToStr(account.currency)}
      </span>
    </span>
  );
};

const Graph = ({ data, keys, indexBy = "Month", groupMode = "grouped", legendX, legendY }) => (
  <ResponsiveBar
    data={data}
    keys={keys}
    indexBy={indexBy}
    margin={{ top: 50, right: 10, bottom: 50, left: 65 }}
    padding={0.3}
    groupMode={groupMode}
    valueScale={{ type: "linear" }}
    indexScale={{ type: "band", round: true }}
    colors={{ scheme: "nivo" }}
    borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
    axisBottom={{ tickSize: 5, tickPadding: 5, tickRotation: 0, legend: legendX, legendPosition: "middle", legendOffset: 32 }}
    axisLeft={{ tickSize: 5, tickPadding: 5, tickRotation: 0, legend: legendY, legendPosition: "middle", legendOffset: -60 }}
    labelSkipWidth={12}
    labelSkipHeight={12}
    labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
    role="application"
    ariaLabel={legendY}
    barAriaLabel={(e) => e.id + ": XXXX" + e.formattedValue + " in: " + e.indexValue}
    layout={"vertical"}
    legends={[
      {
        anchor: "top",
        direction: "row",
        translateY: -35,
        itemWidth: 150,
        itemHeight: 20,
        symbolSize: 20,
        symbolShape: "circle",
        textColor: "#999",
        effects: [
          {
            on: "hover",
            style: {
              itemTextColor: "#000",
            },
          },
        ],
      },
    ]}
  />
);

export default List;
