import { useEffect, useState } from "react";
import api from "@services/api";
import { useDispatch, useSelector } from "react-redux";
import { setUser, setOrganization } from "@redux/auth/actions";
import { useNavigate, Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Loader from "../../components/utils/Loader";
import { Mixpanel } from "../../services/mixpanel";
import Organizations from "../../components/header/Organizations";
import { STRIPE_CHECKOUT_LINK_BUSINESS, STRIPE_CHECKOUT_LINK_ESSENTIAL, STRIPE_CHECKOUT_LINK_LITE } from "../../config";
import Check from "@assets/check.png";
import { FaArrowRight } from "react-icons/fa";
import { MIXPANEL_EVENTS } from "../../utils/mixpanelEvents";

const Checkout = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, organization } = useSelector((state) => state.Auth);
  const [loading, setLoading] = useState(true);

  async function fetchData() {
    try {
      setLoading(true);
      const res = await api.get("/user/signin_token");
      if (!res.ok || !res.user) return setLoading(false);
      if (res.token) api.setToken(res.token);
      dispatch(setUser(res.user));
      Mixpanel.identify(res.user._id);
      Mixpanel.people.set({ ...res.user, $email: res.user.email });
      i18n.changeLanguage(res.user.language);
      Mixpanel.track(MIXPANEL_EVENTS.loadSuccess);
      const { data: organizations } = await api.post("/organization/search");
      dispatch(setOrganization(organizations[0]));
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  async function logout() {
    await api.post(`/user/logout`);
    dispatch(setUser(null));
    navigate("/auth");
  }

  if (loading) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <Loader />
      </div>
    );
  }

  if (!user) return <Navigate to="/auth" replace />;
  if (organization && organization.plan === "premium") return <Navigate to="/" replace />;

  const remainingDays = Math.ceil((new Date(organization?.freeTrialEndAt) - new Date()) / (1000 * 60 * 60 * 24));
  const freeTrialIsEnded = organization?.plan === "free" && organization?.freeTrialEndAt && new Date(organization?.freeTrialEndAt) < new Date();

  return (
    <div className="bg-black-20 w-full min-h-full flex flex-col items-center relative">
      {freeTrialIsEnded ? (
        <div className="text-base text-center text-blue-500">{t("free_trial_ended")}</div>
      ) : (
        <div className="text-base text-center text-blue-500">
          <b>{remainingDays}</b>&nbsp;{t("free_trial_remaining")}
        </div>
      )}

      <div className="flex flex-col items-center mt-4">
        <button className="text-black hover:underline absolute top-4 right-4 text-sm underline" onClick={logout}>
          {t("logout")}
        </button>
      </div>
      <div className="py-8 flex flex-col max-[950px]:items-center min-[950px]:flex-row justify-between w-full h-full gap-8 max-w-[1280px] px-5">
        <div className="w-full  min-[950px]:w-1/3 max-w-[650px] rounded-lg shadow-[0_0_25px_0px_rgba(0,0,0,0.10)] px-8 py-4 bg-black-10 flex flex-col justify-between gap-5">
          <div className="flex flex-col justify-between gap-8">
            <div className="flex items-center">
              <img src="/favicon.png" alt="finotor" className="w-10 h-10 aspect-square mx-auto" />
            </div>
            <div className="text-center h-20">
              <div className="uppercase text-blue text-lg font-bold mb-2">{t("checkout.lite_title")}</div>
              <div className="text-sm text-black-90">{t("checkout.lite_description")}</div>
            </div>
            <div className="flex justify-center gap-2">
              <div className="text-black-100 text-[56px] font-bold -mb-4">19€</div>
              <div className="text-black-90 self-end text-lg text-center">/{t("checkout.per_month")}</div>
            </div>
            <a href={`${STRIPE_CHECKOUT_LINK_LITE}?prefilled_email=${user.email}&client_reference_id=${organization._id}`} target="_blank" rel="noreferrer">
              <button
                onClick={() => Mixpanel.track(MIXPANEL_EVENTS.btn_sub_19)}
                className="text-blue font-bold border border-1.5 border-blue rounded-lg w-[200px] mx-auto py-4 px-4 flex gap-2 items-center justify-center hover:border-blue-600 hover:text-blue-600">
                {t("checkout.start")}
                <span>
                  <FaArrowRight />
                </span>
              </button>
            </a>
            <div className="text-sm">
              <div className="text-black-50 font-bold">{t("checkout.include")} :</div>
              <ul className="list-none mt-4 font-bold text-black-50">
                <li className="mb-6 flex gap-4 items-start">
                  <img src={Check} alt="" height={24} width={24} />
                  {t("checkout.one_user")}
                </li>
                <li className="mb-6 flex gap-4 items-start">
                  <img src={Check} alt="" height={24} width={24} />
                  {t("checkout.create_unlimited_invoices")}
                </li>
                <li className="mb-6 flex gap-4 items-start">
                  <img src={Check} alt="" height={24} width={24} />
                  {t("checkout.create_unlimited_clients")}
                </li>
                <li className="mb-6 flex gap-4 items-start">
                  <img src={Check} alt="" height={24} width={24} />
                  {t("checkout.create_unlimited_products")}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="w-full  min-[950px]:w-1/3 max-w-[650px] rounded-lg shadow-[0_0_25px_0px_rgba(0,0,0,0.10)] px-8 py-4 bg-black-10 flex flex-col justify-between gap-5">
          <div className="flex flex-col justify-between gap-8">
            <div className="flex items-center">
              <img src="/favicon.png" alt="finotor" className="w-10 h-10 aspect-square mx-auto" />
            </div>
            <div className="text-center h-20">
              <div className="uppercase text-blue text-lg font-bold mb-2">{t("checkout.essential_title")}</div>
              <div className="text-sm text-black-90">{t("checkout.essential_description")}</div>
            </div>
            <div className="flex justify-center gap-2">
              <div className="text-black-100 text-[56px] font-bold -mb-4">49€</div>
              <div className="text-black-90 self-end text-lg text-center">/{t("checkout.per_month")}</div>
            </div>
            <a href={`${STRIPE_CHECKOUT_LINK_ESSENTIAL}?prefilled_email=${user.email}&client_reference_id=${organization._id}`} target="_blank" rel="noreferrer">
              <button
                onClick={() => Mixpanel.track(MIXPANEL_EVENTS.btn_sub_49)}
                className="text-white font-bold border border-1.5 border-green-400 bg-green-400 rounded-lg w-[200px] mx-auto py-4 px-4 flex gap-2 items-center justify-center hover:bg-green-300">
                {t("checkout.start")}
                <span>
                  <FaArrowRight />
                </span>
              </button>
            </a>
            <div className="text-sm">
              <div className="text-black-50 font-bold">{t("checkout.include")} :</div>
              <ul className="list-none mt-4 font-bold text-black-50">
                <li className="mb-6 flex gap-4 items-start">
                  <img src={Check} alt="" height={24} width={24} />
                  {t("checkout.five_user")}
                </li>
                <li className="mb-6 flex gap-4 items-start">
                  <img src={Check} alt="" height={24} width={24} />
                  {t("checkout.create_unlimited_invoices")}
                </li>
                <li className="mb-6 flex gap-4 items-start">
                  <img src={Check} alt="" height={24} width={24} />
                  {t("checkout.create_unlimited_clients")}
                </li>
                <li className="mb-6 flex gap-4 items-start">
                  <img src={Check} alt="" height={24} width={24} />
                  {t("checkout.create_unlimited_products")}
                </li>
                <li className="mb-6 flex gap-4 items-start">
                  <img src={Check} alt="" height={24} width={24} />
                  {t("checkout.connect_bank")}
                </li>
                <li className="mb-6 flex gap-4 items-start">
                  <img src={Check} alt="" height={24} width={24} />
                  {t("checkout.connect_stripe_paypal")}
                </li>
                <li className="mb-6 flex gap-4 items-start">
                  <img src={Check} alt="" height={24} width={24} />
                  {t("checkout.manual_bank_reconciliation")}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="w-full  min-[950px]:w-1/3 max-w-[650px] rounded-lg shadow-[0_0_25px_0px_rgba(0,0,0,0.10)] px-8 py-4 bg-black-10 flex flex-col justify-between gap-5">
          <div className="flex flex-col justify-between gap-8">
            <div className="flex items-center">
              <img src="/favicon.png" alt="finotor" className="w-10 h-10 aspect-square mx-auto" />
            </div>
            <div className="text-center h-20 ">
              <div className="uppercase text-blue text-lg font-bold mb-2">{t("checkout.business_title")}</div>
              <div className="text-sm text-black-90">{t("checkout.business_description")}</div>
            </div>
            <div className="flex justify-center gap-2">
              <div className="text-black-100 text-[56px] font-bold -mb-4">99€</div>
              <div className="text-black-90 self-end text-lg text-center">/{t("checkout.per_month")}</div>
            </div>
            <a href={`${STRIPE_CHECKOUT_LINK_BUSINESS}?prefilled_email=${user.email}&client_reference_id=${organization._id}`} target="_blank" rel="noreferrer">
              <button
                onClick={() => Mixpanel.track(MIXPANEL_EVENTS.btn_sub_99)}
                className="text-blue font-bold border border-1.5 border-blue rounded-lg w-[200px] mx-auto py-4 px-4 flex gap-2 items-center justify-center hover:border-blue-600 hover:text-blue-600">
                {t("checkout.start")}
                <span>
                  <FaArrowRight />
                </span>
              </button>
            </a>
            <div className="text-sm">
              <div className="text-black-50 font-bold">{t("checkout.include")} :</div>
              <ul className="list-none mt-4 font-bold text-black-50">
                <li className="mb-6 flex gap-4">
                  <img src={Check} alt="" height={24} width={24} />
                  {t("checkout.unlimited_users")}
                </li>
                <li className="mb-6 flex gap-4 items-start">
                  <img src={Check} alt="" height={24} width={24} />
                  {t("checkout.ai_integration")}
                </li>
                <li className="mb-6 flex gap-4 items-start">
                  <img src={Check} alt="" height={24} width={24} />
                  {t("checkout.dedicated_consultant")}
                </li>
                <li className="mb-6 flex gap-4 items-start">
                  <img src={Check} alt="" height={24} width={24} />
                  {t("checkout.consulting")}
                </li>
                <li className="mb-6 flex gap-4 items-start">
                  <img src={Check} alt="" height={24} width={24} />
                  {t("checkout.access_courses")}
                </li>
                <li className="mb-6 flex gap-4 items-start">
                  <img src={Check} alt="" height={24} width={24} />
                  {t("checkout.balance_sheet")}
                </li>
                <li className="mb-6 flex gap-4 items-start">
                  <img src={Check} alt="" height={24} width={24} />
                  {t("checkout.profit_loss")}
                </li>
                <li className="mb-6 flex gap-4 items-start">
                  <img src={Check} alt="" height={24} width={24} />
                  {t("checkout.business_report")}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
