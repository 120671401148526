import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import api from "@services/api";
import categories from "@data/categories.js";
import { Mixpanel } from "@services/mixpanel";
import { useTranslation } from "react-i18next";
import { toast } from "react-hot-toast";
import { AiFillFilePdf } from "react-icons/ai";
import { MdDeleteOutline } from "react-icons/md";

import Loader from "@components/utils/Loader";
import { Combobox } from "@components/utils/Combobox";
import FileInput from "@components/utils/FileInput";
import Modal from "@components/utils/Modal";
import { Select } from "@components/utils/Select";
import { Tooltip } from "react-tooltip";
import { currencyToStr, getAccountName } from "@utils";
import { useSelector } from "react-redux";
import { MIXPANEL_EVENTS } from "../../utils/mixpanelEvents";

const CreateTransaction = ({ isOpen, onClose, onSave, setShowCreateClientModal, showCreateSupplierModal, createdClient, createdSupplier, pettyCashAccount, accountOptions }) => {
  const [clientOptions, setClientOptions] = useState([]);
  const [supplierOptions, setSupplierOptions] = useState([]);
  const [invoiceOptions, setInvoiceOptions] = useState([]);
  const [transaction, setTransaction] = useState(null);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [selectedAccount, setSelectedAccount] = useState(null);
  const user = useSelector((state) => state.Auth.user);

  const getClients = async () => {
    const { data } = await api.post(`/client/search`, { per_page: 1000 });
    setClientOptions(data.clients);
  };

  const getSuppliers = async () => {
    const { data } = await api.post(`/supplier/search`, { per_page: 1000 });
    setSupplierOptions(data.suppliers);
  };

  useEffect(() => {
    getClients();
    getSuppliers();
    const newTransaction = {
      date: new Date().toISOString().slice(0, -8),
      status: "TO_RECONCILE",
      amount: 0,
      type: "SUPPLIER",
      organizationName: user.UserOrganizations?.[0].organizationName,
    };
    setTransaction(newTransaction);
    setSelectedAccount(pettyCashAccount ? { ...pettyCashAccount } : { ...accountOptions[0] });
  }, [isOpen, pettyCashAccount]);

  useEffect(() => {
    if (createdClient) {
      setTransaction((transaction) => ({ ...transaction, ClientId: createdClient?._id || null }));
    }
  }, [createdClient]);

  useEffect(() => {
    const getInvoices = async () => {
      const { data } = await api.post("/invoice/search", { per_page: 1000, statuses: ["SENT", "PAID", "DUE"] });
      setInvoiceOptions(data.invoices);
    };
    getInvoices();
  }, [transaction]);

  useEffect(() => {
    if (createdSupplier) {
      setTransaction((transaction) => ({ ...transaction, SupplierId: createdSupplier?._id || null }));
    }
  }, [createdSupplier]);

  useEffect(() => {
    setTransaction((t) => ({
      ...t,
      AccountId: selectedAccount?._id,
      bankLogo: selectedAccount?.Requisition?.NordigenInstitution.logo ?? null,
      bankName: selectedAccount?.Requisition?.NordigenInstitution.name ?? null,
      currency: selectedAccount?.currency,
      accountOwner: selectedAccount?.ownerName,
      accountDetails: selectedAccount?.details,
    }));
  }, [selectedAccount]);

  const handleSave = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      const { ok, code } = await api.post(`/transaction`, { ...transaction });
      if (!ok) throw { code };
      onSave();
      toast.success(t("toast.sucess.created"));
      handleClose();
    } catch (e) {
      console.error(t("toast.error"), e);
      toast.error(e.code);
    }
    setLoading(false);
  };

  const handleSaveAndReconcile = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      const { ok, code } = await api.post(`/transaction`, { ...transaction, status: "RECONCILED" });
      if (!ok) throw { code };
      onSave();
      toast.success(t("toast.success.created_reconciled"));
      handleClose();
    } catch (e) {
      console.error(t("toast.error"), e);
      toast.error(e.code);
    }
    setLoading(false);
  };

  const handleClose = () => {
    setTransaction(null);
    onClose();
  };

  const clientOrSupplierOption = (item) => {
    if (!item) return null;
    return item.entityType === "INDIVIDUAL" ? item.firstname + " " + item.lastname : item.companyName;
  };

  const canCreate = () => {
    return transaction.AccountId && transaction.date && transaction.amount && transaction.name;
  };
  const canReconcile = () => {
    return (
      transaction.AccountId &&
      transaction.date &&
      transaction.amount &&
      transaction.name &&
      transaction.category &&
      (transaction.ClientId || transaction.SupplierId || transaction.EmployeeId)
    );
  };

  if (loading) return <Loader />;
  if (!transaction) return null;

  return (
    <Modal isOpen={isOpen} onClose={handleClose} innerClassName="flex max-w-[50rem] w-[60vw] max-h-[calc(100vh-5rem)]">
      <div className="w-full max-h-full flex flex-col overflow-y-hidden transform rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
        <div className="px-8 py-5 text-left text-lg font-semibold border-b">{t("transactions.create_transaction")}</div>
        <div className="py-4 px-8 overflow-auto">
          <div className="flex flex-col gap-4">
            <div className="grid grid-cols-2 grid-rows-3 gap-4">
              <div className="flex flex-col row-start-1">
                <div className="text-base font-semibold text-gray-500 capitalize"> {t("date")} </div>
                <div className="placeholder:text-gray-300 pr-3 py-2">
                  <input
                    type="datetime-local"
                    className="h-10 py-2 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700"
                    value={transaction.date}
                    onChange={(e) => {
                      setTransaction({ ...transaction, date: e.target.value });
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-col row-start-1">
                <div className="text-base font-semibold text-gray-500 capitalize"> {t("name")} </div>
                <div className="placeholder:text-gray-300 pr-3 py-2">
                  <input
                    type="text"
                    maxLength={50}
                    className="h-10 py-2 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700"
                    value={transaction.name}
                    onChange={(e) => {
                      setTransaction({ ...transaction, name: e.target.value });
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-col row-start-2">
                <div className="text-base font-semibold text-gray-500 capitalize"> {t("account")} </div>
                <div className="border-gray-300 placeholder:text-gray-300 pr-3 py-2">
                  <Select
                    options={pettyCashAccount ? [...accountOptions, pettyCashAccount] : accountOptions}
                    value={selectedAccount}
                    onChange={(e) => setSelectedAccount({ ...e })}
                    placeholder={t("account")}
                    getLabel={(account) => <AccountOption account={account} />}
                    width="w-full"
                    nullable={false}
                  />
                </div>
              </div>
              <div className="flex flex-col row-start-2">
                <div className="text-base font-semibold text-gray-500 capitalize"> {t("amount")} </div>
                <div className="border-gray-300 placeholder:text-gray-300 pr-3 py-2">
                  <input
                    type="number"
                    className="h-10 py-2 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700 mr-2"
                    max={0}
                    value={transaction.amount}
                    onChange={(e) => setTransaction({ ...transaction, amount: e.target.value })}
                  />
                  <span>{currencyToStr(transaction.currency)}</span>
                </div>
              </div>
              <div className="flex flex-col row-start-3">
                <label htmlFor="categories" className="text-base font-semibold text-gray-500 capitalize">
                  {t("category")}
                </label>
                <Combobox
                  value={categories.find((category) => category.id == transaction.category)}
                  options={categories}
                  onChange={(category) => setTransaction({ ...transaction, category: category?.id || null })}
                  getLabel={(e) => (e ? e.id + " - " + t(`transactions.${e.id}`) : null)}
                  placeholder={t("transactions.edit.placeholder.select_category")}
                />
              </div>
            </div>
            <hr className="mb-2" />
            <div className="grid grid-cols-2 grid-rows-1 gap-4">
              <div className="flex flex-col row-start-1">
                <div className="text-base font-semibold text-gray-500 capitalize"> {t("type")} </div>
                <Select
                  options={["CLIENT", "SUPPLIER"]}
                  value={transaction.type ? transaction.type : transaction.amount < 0 ? "SUPPLIER" : "CLIENT"}
                  onChange={(e) => {
                    if (e === "CLIENT")
                      setTransaction((transaction) => ({
                        ...transaction,
                        type: e,
                        SupplierId: null,
                      }));
                    else if (e === "SUPPLIER")
                      setTransaction((transaction) => ({
                        ...transaction,
                        type: e,
                        ClientId: null,
                      }));
                  }}
                  getLabel={(e) => t(e.toLowerCase())}
                  placeholder="Select a type"
                  nullable={false}
                />
              </div>
              <div className="flex flex-col row-start-1">
                {transaction.type === "SUPPLIER" && (
                  <>
                    <div className="text-base font-semibold text-gray-500 capitalize"> {t("supplier")} </div>
                    <Combobox
                      options={supplierOptions}
                      value={supplierOptions.find((supplier) => supplier._id === transaction.SupplierId)}
                      onChange={(e) =>
                        setTransaction((transaction) => ({
                          ...transaction,
                          SupplierId: e?._id || null,
                          category: transaction.category || e?.defaultCategory,
                        }))
                      }
                      placeholder={t("supplier")}
                      getLabel={(supplier) => clientOrSupplierOption(supplier) + (supplier.similarCount > 0 ? " (" + t("suggested") + ")" : "")}
                      nullable={true}
                      listHeader={
                        <button className="px-3 py-2 w-full flex items-center justify-between font-semibold text-sm" onClick={() => showCreateSupplierModal()}>
                          {t("supplier.create_supplier")} <span>+</span>
                        </button>
                      }
                      displayValue={(supplier) => clientOrSupplierOption(supplier)}
                    />
                  </>
                )}
                {transaction.type === "CLIENT" && (
                  <>
                    <div className="text-base font-semibold text-gray-500 capitalize"> {t("client")} </div>
                    <Combobox
                      options={clientOptions}
                      value={clientOptions.find((client) => client._id === transaction.ClientId)}
                      onChange={(e) => {
                        setTransaction((transaction) => ({
                          ...transaction,
                          ClientId: e?._id || null,
                          category: transaction.category || e?.defaultCategory,
                        }));
                      }}
                      placeholder={t("client")}
                      getLabel={(client) => clientOrSupplierOption(client) + (client.similarCount > 0 ? " (" + t("suggested") + ")" : "")}
                      listHeader={
                        <button className="px-3 py-2 w-full flex items-center justify-between font-semibold text-sm" onClick={() => setShowCreateClientModal()}>
                          {t("clients.create_a_client")} <span>+</span>
                        </button>
                      }
                      displayValue={(client) => clientOrSupplierOption(client)}
                    />
                  </>
                )}
              </div>
            </div>

            {transaction.amount > 0 ? (
              <div className="flex flex-col row-start-1">
                <div className="text-base font-semibold text-gray-500 "> {t("sales_invoices")} </div>

                <Select
                  options={invoiceOptions}
                  value={transaction?.Invoices || []}
                  onChange={(selectedOptions) => {
                    setTransaction({
                      ...transaction,
                      Invoices: selectedOptions,
                    });
                  }}
                  multiple
                  placeholder={t("invoice")}
                  getLabel={(invoice) => (invoice ? invoice.reference + " " + (invoice.name || "") : null)}
                  listHeader={
                    <Link to={`/invoices?modal=open`} className="px-4 py-2 flex items-center justify-between font-semibold text-sm">
                      {t("invoices.create")} <span>+</span>
                    </Link>
                  }
                  by={(a, b) => a._id === b._id}
                />
              </div>
            ) : (
              <div className="flex flex-col row-start-1">
                <div className="text-base font-semibold text-gray-500 capitalize"> {t("purchase_invoices")} </div>
                <div className="flex gap-3 ">
                  <TableAssociatedInvoices transaction={transaction} setTransaction={setTransaction} />
                </div>
              </div>
            )}
            <hr className="mb-2" />

            <div className="flex flex-col">
              <div className="text-base font-semibold text-gray-500 capitalize"> {t("memo_note")} </div>
              <textarea
                type="text"
                value={transaction.memo ?? ""}
                onChange={(e) => setTransaction({ ...transaction, memo: e.target.value })}
                className="border-[1px] border-gray-300 placeholder:text-gray-300 rounded-lg px-3 py-2"
                placeholder={t("memo_note.transaction.placeholder")}
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between w-full mt-3 border-t py-3 px-8">
          <button type="submit" className="btn-secondary" onClick={handleClose}>
            {t("cancel")}
          </button>
          <div className="flex gap-2">
            <button
              disabled={!canCreate()}
              className="btn-primary w-max"
              onClick={(e) => {
                Mixpanel.track(MIXPANEL_EVENTS.btn_transaction_created);
                handleSave(e);
              }}
              data-tooltip-id={`tooltip-create`}
              data-tooltip-content={canCreate() ? "" : t("transaction.create_disable")}>
              {t("transactions.create_transaction")}
            </button>
            <button
              disabled={!canReconcile()}
              className="btn-primary w-max"
              onClick={(e) => {
                Mixpanel.track(MIXPANEL_EVENTS.btn_transaction_created_reconciled);
                handleSaveAndReconcile(e);
              }}
              data-tooltip-id={`tooltip-reconcile`}
              data-tooltip-content={canReconcile() ? "" : t("transaction.reconcile_disable")}>
              {t("transactions.create_reconcile")}
            </button>
            <Tooltip id={`tooltip-create`} opacity={1} style={{ zIndex: 1000, backgroundColor: "#40a5c6", borderRadius: "6px" }} />
            <Tooltip id={`tooltip-reconcile`} place={"top-end"} opacity={1} style={{ zIndex: 1000, backgroundColor: "#40a5c6", borderRadius: "6px" }} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

const TableAssociatedInvoices = ({ transaction, setTransaction }) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [purchaseInvoices, setPurchaseInvoices] = useState([]);

  const getPurchaseInvoices = async () => {
    const { data } = await api.post("/purchase-invoice/search", { per_page: 1000 });
    setPurchaseInvoices(data.purchaseInvoices);
  };

  const removePurchaseInvoice = (index) => {
    setTransaction({
      ...transaction,
      PurchaseInvoices: [...transaction.PurchaseInvoices.slice(0, index), ...transaction.PurchaseInvoices.slice(index + 1)],
    });
  };

  useEffect(() => {
    getPurchaseInvoices();
  }, []);

  return (
    <div className="flex w-full gap-3">
      <div className=" mt-4  w-1/2 align-middle overflow-visible  flex flex-col gap-3">
        <div className="flex flex-col gap-3">
          <div className="flex gap-3 ">
            <Select
              options={purchaseInvoices}
              value={transaction?.PurchaseInvoices || []}
              onChange={(selectedOptions) => {
                setTransaction({
                  ...transaction,
                  PurchaseInvoices: selectedOptions,
                });
              }}
              multiple
              placeholder={t("choose_invoice")}
              getLabel={(purchaseInvoice) => {
                const amount = purchaseInvoice.amount;
                let supplierName = purchaseInvoice.Supplier?.companyName ?? t("not_defined");
                let date;
                if (purchaseInvoice.issueDate) {
                  date = new Date(purchaseInvoice.issueDate);
                }
                return (
                  <div className="flex w-full gap-1">
                    <span className="flex-1">{supplierName}</span>
                    <span className="px-2 bg-blue-100">{amount} €</span>
                    {date ? <span className="px-2 bg-green-100">{date.toLocaleDateString()}</span> : null}
                  </div>
                );
              }}
              by={(a, b) => a._id === b._id}
            />
          </div>
          <div className="flex gap-3">
            <FileInput
              onChange={async (file) => {
                if (!file) return;
                setLoading(true);
                const { data } = await api.post("/purchase-invoice", {
                  reference: decodeURIComponent(file.target.value.split("/").pop().split(".").shift()),
                  file: file.target.value,
                });
                setLoading(false);

                toast.success(t("toast.sucess.created"));

                setTransaction({
                  ...transaction,
                  PurchaseInvoices: [data, ...transaction.PurchaseInvoices],
                });
                getPurchaseInvoices();
              }}
              name={transaction._id}
              folder="purchaseInvoiceTransaction"
              width="w-full"
            />
            {loading && <Loader size="small" />}
          </div>
        </div>

        {transaction.PurchaseInvoices?.map((invoiceAssociated, index) => {
          return (
            <div key={invoiceAssociated._id} className="hover:bg-[#dee2e6] rounded-lg border flex">
              <div className="w-1/4 relative">
                {invoiceAssociated.file && (
                  <a href={invoiceAssociated.file} target="_blank" rel="noopener noreferrer" className="flex flex-col">
                    {invoiceAssociated.file.indexOf(".pdf") !== -1 ? (
                      <div className="absolute top-0 left-0 w-full bg-gray-300 opacity-80 hover:opacity-90 flex items-center justify-center cursor-pointer text-[12px] text-black font-normal h-full">
                        <AiFillFilePdf size={48} />
                      </div>
                    ) : (
                      <img className="h-full" src={invoiceAssociated.file} alt={`Invoice Image 1`} />
                    )}
                  </a>
                )}
              </div>

              <div className="w-3/4 p-2">
                <div className="font-medium"> {invoiceAssociated.reference} </div>
                <div className="text-sm">{invoiceAssociated.amount}</div>
                {invoiceAssociated.issueDate && (
                  <div className="text-sm">
                    {t("purchase_invoices.issued_on")} {new Date(invoiceAssociated.issueDate).toLocaleDateString()}
                  </div>
                )}

                <div>
                  <button type="button" onClick={() => removePurchaseInvoice(index)}>
                    <MdDeleteOutline />
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
const AccountOption = ({ account }) => {
  const logo = account?.Requisition?.NordigenInstitution?.logo;
  if (!account) return;
  return (
    <span className="flex">
      {logo && <img src={logo} height={20} width={20} />}
      <span className="ml-2">{getAccountName(account)}</span>
    </span>
  );
};
export default CreateTransaction;
