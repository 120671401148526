import React, { useState } from "react";
import { classNames } from "../../utils";
import { useTranslation } from "react-i18next";
import List from "./list";
import ProfitLoss from "./profitLoss";
import Tax from "@scenes/analytics/tax";
import Ledger from "./ledger";
import { ENVIRONMENT } from "../../config";

const Index = () => {
  const { t } = useTranslation();

  const TABS = {
    ACCOUNTING: t("analytics.tab_accounting"),
    PROFIT_LOSS: t("analytics.tab_profit_loss"),
    POTENTIAL: t("analytics.tab_potential"),
    TAX: t("analytics.tab_tax_report"),
    LEDGER: t("analytics.tab_ledger"),
  };

  const [tab, setTab] = useState(TABS.ACCOUNTING);

  const render = () => {
    if (tab === TABS.ACCOUNTING) return <List />;
    if (tab === TABS.PROFIT_LOSS) return <ProfitLoss />;
    if (tab === TABS.TAX) return <Tax />;
    if (tab === TABS.LEDGER) return <Ledger />;

    if (tab === TABS.POTENTIAL) return <div className="text-center mt-10"> {t("coming_soon")} </div>;
    return <div />;
  };

  return (
    <div className="mx-auto">
      <div className="py-6 px-6">
        <div className="">
          <nav className="mt-2 flex flex-row w-full justify-start items-center border-b border-gray-100 bg-white">
            <TabItem title={TABS.ACCOUNTING} tab={TABS.ACCOUNTING} setTab={setTab} active={tab === TABS.ACCOUNTING} />
            <TabItem
              title={TABS.PROFIT_LOSS}
              tab={TABS.PROFIT_LOSS}
              setTab={setTab}
              active={tab === TABS.PROFIT_LOSS}
              RightComponent={<span className="bg-blue-700 text-white px-2 uppercase text-xs rounded-md">beta</span>}
            />
            <TabItem
              title={TABS.TAX}
              tab={TABS.TAX}
              setTab={setTab}
              active={tab === TABS.TAX}
              RightComponent={<span className="bg-blue-700 text-white px-2 uppercase text-xs rounded-md">beta</span>}
            />
            {/* <TabItem title={TABS.POTENTIAL} tab={TABS.POTENTIAL} setTab={setTab} active={tab === TABS.POTENTIAL} /> */}
            <TabItem
              title={TABS.LEDGER}
              tab={TABS.LEDGER}
              setTab={setTab}
              active={tab === TABS.LEDGER}
              RightComponent={<span className="bg-blue-700 text-white px-2 uppercase text-xs rounded-md">beta</span>}
            />
          </nav>
          <div className="w-full py-2">{render()}</div>
        </div>
      </div>
    </div>
  );
};

const TabItem = ({ tab, title, setTab, active, RightComponent }) => {
  return (
    <button
      onClick={() => setTab(tab)}
      className={classNames(
        "group inline-flex items-center px-4 py-2 gap-2 cursor-pointer text-sm  hover:border-gray-300 text-gray-500 hover:text-gray-700  border-b-2 border-transparent h-full ",
        active ? " !border-black" : "",
      )}>
      <div className={classNames("flex items-center gap-2", active ? "text-black-100" : "")}>{title}</div>
      {RightComponent ?? null}
    </button>
  );
};

export default Index;
