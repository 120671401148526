import React from "react";

const ProgressBar = ({ percentage }) => (
  <div className="w-full bg-white h-1 rounded-lg overflow-hidden">
    <div className="bg-white rounded-lg" style={{ width: `${Math.min(100, percentage)}%` }}>
      <div className="flex justify-center items-center h-1 text-sm font-medium bg-blue rounded-lg" />
    </div>
  </div>
);

export default ProgressBar;
