import { readFileAsync } from "@utils/index";
import api from "@services/api";
import { useState } from "react";

const FileInputBtn = ({ onChange, name, folder }) => {
  const [loading, setLoading] = useState(false);

  return (
    <div className="relative w-7 h-7">
      <div className="absolute top-0 right-0"></div>
      <label
        htmlFor={`openFile-${name}`}
        className=" rounded-xl  overflow-hidden border border-gray-200 absolute top-0 right-0 px-3 py-2 w-full h-full hover:backdrop-blur-sm cursor-pointer flex flex-col items-center justify-center">
        <span className="text-sm">+</span>
      </label>
      <input
        id={`openFile-${name}`}
        type="file"
        className="hidden"
        disabled={loading}
        onChange={async (e) => {
          setLoading(true);
          const f = e.target.files[0];
          const rawBody = await readFileAsync(f);
          const { data } = await api.post(`/file`, {
            file: { rawBody, name: f.name },
            folder,
          });
          onChange({ target: { value: data, name } });
          setLoading(false);
        }}
      />
    </div>
  );
};

export default FileInputBtn;
