const ENVIRONMENT = getEnvironment();

let apiURL = null;

if (ENVIRONMENT === "development") {
  apiURL = "http://localhost:8080";
}
if (ENVIRONMENT === "staging") {
  apiURL = "https://api-staging.finotor.com";
}

if (ENVIRONMENT === "production") {
  apiURL = "https://api.finotor.com";
}

const SENTRY_URL = "https://deb05f98fc170637c511874b0eef5862@sentry.selego.co/92";
const STRIPE_OAUTH_CLIENT_ID = "ca_PWEqnH9NVoJsU0Ivhz6ur3QNUoAhRyiJ";

let STRIPE_CHECKOUT_LINK_BUSINESS = "https://checkout.finotor.com/b/test_bIY7sCbEPdDw6I05kl";
let STRIPE_CHECKOUT_LINK_ESSENTIAL = "https://checkout.finotor.com/b/test_bIY7sCbEPdDw6I05kl";
let STRIPE_CHECKOUT_LINK_LITE = "https://checkout.finotor.com/b/test_bIY7sCbEPdDw6I05kl";
if (ENVIRONMENT === "staging") {
  STRIPE_CHECKOUT_LINK_BUSINESS = "https://checkout.finotor.com/b/test_eVafZ824fdDwaYgeUW";
  STRIPE_CHECKOUT_LINK_ESSENTIAL = "https://checkout.finotor.com/b/test_eVafZ824fdDwaYgeUW";
  STRIPE_CHECKOUT_LINK_LITE = "https://checkout.finotor.com/b/test_eVafZ824fdDwaYgeUW";
}
if (ENVIRONMENT === "production") {
  STRIPE_CHECKOUT_LINK_BUSINESS = "https://checkout.finotor.com/b/5kAbLr0jB2aX3MQ6ot";
  STRIPE_CHECKOUT_LINK_ESSENTIAL = "https://checkout.finotor.com/b/7sIcPv2rJ02Pbfi9AH";
  STRIPE_CHECKOUT_LINK_LITE = "https://checkout.finotor.com/b/aEU16N5DV8zlcjm8wE";
}

function getEnvironment() {
  if (window.location.href.indexOf("staging") !== -1) return "staging";
  if (window.location.href.indexOf("localhost") !== -1 || window.location.href.indexOf("127.0.0.1") !== -1) return "development";
  return "production";
}

export { apiURL, SENTRY_URL, ENVIRONMENT, STRIPE_OAUTH_CLIENT_ID, STRIPE_CHECKOUT_LINK_BUSINESS, STRIPE_CHECKOUT_LINK_ESSENTIAL, STRIPE_CHECKOUT_LINK_LITE };
