import { useEffect, useState } from "react";
import api from "@services/api";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Mixpanel } from "../../services/mixpanel";
import { STRIPE_OAUTH_CLIENT_ID } from "../../config";
import AddBank from "@scenes/bank/AddBank.jsx";
import { FaUniversity } from "react-icons/fa";
import XeroLogo from "@assets/xero-logo.svg";
import StripeLogo from "@assets/stripe-logo.png";
import { IoClose } from "react-icons/io5";
import Loader from "../../components/utils/Loader";
import { IoArrowForwardOutline } from "react-icons/io5";
import { FaCashRegister } from "react-icons/fa6";
import CreatePettyCash from "@components/modals/createPettyCash";
import { MIXPANEL_EVENTS } from "../../utils/mixpanelEvents";
import { useSelector } from "react-redux";
import { PiWarningDiamond } from "react-icons/pi";
import { Tooltip } from "react-tooltip";

const List = () => {
  const { t } = useTranslation();
  const [requisitions, setRequisitions] = useState([]);
  const [stripeRequisitions, setStripeRequisitions] = useState([]);
  const [pettyCash, setPettyCash] = useState(null);
  const [openAddBank, setOpenAddBank] = useState(false);
  const [openAddPettyCash, setOpenAddPettyCash] = useState(false);
  const query = new URLSearchParams(useLocation().search);
  const [stripe, setStripe] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { organization } = useSelector((state) => state.Auth);

  const [syncingNordigen, setSyncingNordigen] = useState(false);

  const fetchStripeBank = async () => {
    try {
      const stripeBank = await api.post("/nordigen-institution/search", { search: "stripe" });
      setStripe(stripeBank.data.banks[0]);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchRequisitions = async () => {
    try {
      setLoading(true);
      const res = await api.post("/requisition/search", { OrganizationId: organization._id });
      const stripeRequisitions = await api.post("/stripe/requisitions/search", { OrganizationId: organization._id });
      setStripeRequisitions(stripeRequisitions.data);
      setRequisitions(res.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };
  const fetchPettyCash = async () => {
    const res = await api.post("/account/search-pettycash", { OrganizationId: organization._id });
    setPettyCash(res.data);
  };
  const deletePettyCash = async () => {
    try {
      const confirm = window.confirm(t("confirm_delete_message"));
      if (!confirm) return;
      const { ok, code } = await api.remove(`/account/${pettyCash?._id}`);
      if (!ok) throw code;
      toast.success(t("toast.sucess.removed"));
      setPettyCash(null);
    } catch (e) {
      console.error(t("toast.error"), e);
      toast.error(e.code);
    }
  };

  async function syncNordigen() {
    try {
      if (syncingNordigen) return;
      setSyncingNordigen(true);
      toast.success(t("toast.sucess.synch_bank"));
      const { ok } = await api.post("/requisition/sync");
      if (!ok) throw new Error(t("toast.error.went_wrong"));

      await fetchRequisitions();
      toast.success(t("toast.sucess.synced_bank"));

      const res = await api.post("/transaction/sync");
      if (!res.ok) throw new Error(t("toast.error.went_wrong"));
      toast.success(t("toast.sucess.synced_transactions"));
    } catch (e) {
      toast.error(t("toast.error"), e.code);
    } finally {
      setSyncingNordigen(false);
    }
  }

  const connectXero = async () => {
    const res = await api.get(`/xero/connect`);
    window.location.href = res.url;
  };
  const handleClickPettyCash = () => {
    if (!pettyCash) {
      setOpenAddPettyCash(true);
      return;
    }
    deletePettyCash();
  };

  useEffect(() => {
    if (!organization) return;
    // means we are coming from the bank connection with nordigen
    if (query.get("ref")) syncNordigen();
    fetchRequisitions();
    fetchStripeBank();
    fetchPettyCash();
    Mixpanel.track(MIXPANEL_EVENTS.page_view_bank);
  }, [organization]);

  return (
    <>
      <div className="mx-auto">
        <div className="py-6 px-6">
          <div className="flex flex-col bg-white rounded-xl">
            <div className="flex justify-between items-start p-6">
              <div className="">
                <h1 className="text-2xl font-bold">{t("myintegrations.title")}</h1>
                <p className="mt-2 text-slate-600">{t("myintegrations.intro")}</p>
              </div>
            </div>
            <div className={`grid gap-3 px-6 ${pettyCash ? "grid-cols-3" : "grid-cols-2 xl:grid-cols-4"}`}>
              <button
                className="flex flex-col items-center justify-center bg-white rounded-xl text-center border border-gray-200 hover:border-gray-300"
                onClick={() => {
                  connectXero();
                  Mixpanel.track(MIXPANEL_EVENTS.btn_connect_xero);
                }}>
                <div className="px-6 pt-2 pb-1">
                  <img src={XeroLogo} alt="xero" height={30} width={30} />
                </div>
                <span className="btn-tertiary w-full pt-1 pb-2 text-gray-400">{t("integrations.connect_xero")}</span>
              </button>
              <button
                className="flex flex-col items-center justify-center bg-white rounded-xl text-center border border-gray-200 hover:border-gray-300"
                onClick={() => setOpenAddBank(true)}>
                <div className=" px-6 pt-2 pb-1">
                  <FaUniversity className="mx-auto" size={30} />
                </div>
                <span className="btn-tertiary w-full px-6 pt-1 pb-2 text-gray-400">{t("myintegrations.add_new_bank")}</span>
              </button>
              <button
                className="flex flex-col items-center justify-center bg-white rounded-xl text-center border border-gray-200 hover:border-gray-300"
                onClick={() =>
                  window.open(
                    `https://dashboard.stripe.com/oauth/authorize?response_type=code&client_id=${STRIPE_OAUTH_CLIENT_ID}&scope=read_write&redirect_uri=https://app.finotor.com/bank/add?stripe_redirect=true`,
                    "_blank",
                  )
                }>
                <div className="px-6 pt-2 pb-1">
                  <img src={StripeLogo} alt={"stripe"} height={30} width={30} />
                </div>
                <span className="btn-tertiary w-full pt-1 pb-2 text-gray-400">{t("myintegrations.addbank.connect_stripe")}</span>
              </button>
              {!pettyCash && (
                <button
                  className="flex flex-col items-center justify-center bg-white rounded-xl text-center border border-gray-200 hover:border-gray-300"
                  onClick={() => handleClickPettyCash()}>
                  <div className=" px-6 pt-2 pb-1">
                    <FaCashRegister className="mx-auto" size={30} />
                  </div>
                  <span className="btn-tertiary w-full px-6 pt-1 pb-2 text-gray-400">{pettyCash ? t("myintegrations.delete_petty_cash") : t("myintegrations.add_petty_cash")}</span>
                </button>
              )}
            </div>
            {loading ? (
              <Loader />
            ) : requisitions.length || stripeRequisitions.length ? (
              <table className="border-[#E0E0E1] mt-5 mb-5  ">
                <thead className=" h-10 border-b  w-full border-[#E0E0E1] py-4">
                  <tr>
                    <th className="px-9">{t("category")}</th>
                    <th className="px-3">Source</th>
                  </tr>
                </thead>
                <tbody className="">
                  {requisitions.map((requisition) => (
                    <RequisitionCard
                      key={`${requisition.NordigenInstitution._id}-${requisition.nordigenRequisitionId}`}
                      requisition={requisition}
                      onDelete={() => fetchRequisitions()}
                    />
                  ))}
                  {stripeRequisitions.map((requisition) => (
                    <StripeRequisitionCard key={requisition._id} requisition={requisition} stripe={stripe} onDelete={() => fetchRequisitions()} />
                  ))}
                  {pettyCash && (
                    <tr className="h-20 border-b w-full border-[#E0E0E1] last:border-none py-4">
                      <td className="min-w-fit w-1/3 px-12">{t("petty_cash")}</td>
                      <td className="px-4">{pettyCash.ownerName}</td>
                      <td>
                        <button
                          className="m-2 p-2 cursor-pointer hover:text-red-500"
                          onClick={() => {
                            deletePettyCash();
                          }}>
                          <IoClose />
                        </button>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            ) : (
              <div className="px-6 my-16 text-center">
                <div className="text-3xl font-bold mb-6">{t("myintegrations.no_integration_found")}</div>
                <div className="w-fit mx-auto text-left text-black-90">
                  <div className="text-lg mb-4 ">{t("myintegrations.dont_forget")}</div>
                  <ul className="text-base list-none text-left mb-4">
                    <li className="pl-4 mb-2 flex gap-2 items-center cursor-pointer group" onClick={() => setOpenAddBank(true)}>
                      <IoArrowForwardOutline className="text-blue group-hover:translate-x-1 transition" />
                      <span className="group-hover:text-black">{t("myintegrations.connect_bank")}</span>
                    </li>
                    <li
                      className="pl-4 mb-2 flex gap-2 items-center cursor-pointer group"
                      onClick={() =>
                        window.open(
                          `https://dashboard.stripe.com/oauth/authorize?response_type=code&client_id=${STRIPE_OAUTH_CLIENT_ID}&scope=read_write&redirect_uri=https://app.finotor.com/bank/add?stripe_redirect=true`,
                          "_blank",
                        )
                      }>
                      <IoArrowForwardOutline className="text-blue group-hover:translate-x-1 transition" />
                      <span className="group-hover:text-black">{t("myintegrations.connect_stripe")}</span>
                    </li>
                    <li className="pl-4 mb-2 flex gap-2 items-center cursor-pointer group" onClick={() => navigate("/clients")}>
                      <IoArrowForwardOutline className="text-blue group-hover:translate-x-1 transition" />
                      <span className="group-hover:text-black">{t("myintegrations.create_import_client")}</span>
                    </li>
                    <li className="pl-4 mb-2 flex gap-2 items-center cursor-pointer group" onClick={() => navigate("/products")}>
                      <IoArrowForwardOutline className="text-blue group-hover:translate-x-1 transition" />
                      <span className="group-hover:text-black">{t("myintegrations.create_product")}</span>
                    </li>
                    <li className="pl-4 mb-2 flex gap-2 items-center cursor-pointer group" onClick={() => navigate("/purchase-invoice")}>
                      <IoArrowForwardOutline className="text-blue group-hover:translate-x-1 transition" />
                      <span className="group-hover:text-black">{t("myintegrations.create_purchase_invoice")}</span>
                    </li>
                    <li className="pl-4 mb-2 flex gap-2 items-center">
                      <IoArrowForwardOutline className="text-blue" />
                      {t("myintegrations.integrate_expense_report")}
                    </li>
                  </ul>
                  <div className="text-lg">{t("myintegrations.ask_help")}</div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <AddBank isOpen={openAddBank} closeModal={() => setOpenAddBank(false)} />
      <CreatePettyCash
        open={openAddPettyCash}
        onClose={() => setOpenAddPettyCash(false)}
        onCreated={() => {
          fetchPettyCash();
        }}
      />
    </>
  );
};

const RequisitionCard = ({ requisition, onDelete }) => {
  const { t } = useTranslation();
  const handleDelete = async () => {
    try {
      const confirm = window.confirm(t("confirm_delete_message"));
      if (!confirm) return;
      const { ok } = await api.remove(`/requisition/${requisition.nordigenRequisitionId}`);
      if (!ok) throw new Error("Something went wrong");
      onDelete();
      toast.success("Bank account deleted");
    } catch (error) {
      console.log(error);
      toast.error(error);
    }
  };

  return (
    <tr className="h-20 border-b w-full border-[#E0E0E1] last:border-none py-4">
      <td className="min-w-fit w-1/3 px-12">{t("banking")}</td>
      <td>
        <div className="flex justify-between flex-wrap items-center h-full content-center pl-3">
          <div className="flex justify-start items-start h-10">
            <img src={requisition.NordigenInstitution.logo} alt={requisition.NordigenInstitution.name} height={40} width={40} />
            <div className="ml-3">
              <div className="font-semibold">{requisition.NordigenInstitution.name}</div>
              <div className="text-xs">{requisition.Accounts.length} accounts</div>
            </div>
          </div>
          <div className="flex items-center justify-center gap-5">
            {requisition.Accounts.find((account) => ["SUSPENDED", "EXPIRED"].includes(account.status)) && (
              <button
                className="btn-primary btn-small focus:bg-blue"
                onClick={async () => {
                  const res = await api.post("/nordigen-institution/renew", {
                    NordigenInstitutionId: requisition.NordigenInstitutionId,
                  });
                  window.open(res.link, "_blank");
                }}>
                {t("myintegrations.renew_account")}
              </button>
            )}
          </div>
          <div className="flex items-center justify-center gap-5">
            {requisition.Accounts.find((account) => ["ERROR", "ERROR_0", "ERROR_1", "ERROR_2", "ERROR_3"].includes(account.status)) && (
              <>
                <PiWarningDiamond
                  className="text-orange-500 text-xl"
                  data-tooltip-id={`warning-${requisition.nordigenRequisitionId.toString()}`}
                  data-tooltip-content={t("myintegrations.instability_warning")}
                />
                <Tooltip id={`warning-${requisition.nordigenRequisitionId.toString()}`} opacity={1} style={{ zIndex: 1000, backgroundColor: "#40a5c6", borderRadius: "6px" }} />
              </>
            )}
          </div>
        </div>
      </td>
      <td>
        <button className="m-2 p-2 cursor-pointer hover:text-red-500" onClick={handleDelete}>
          <IoClose />
        </button>
      </td>
    </tr>
  );
};

const StripeRequisitionCard = ({ requisition, onDelete, stripe }) => {
  const { t } = useTranslation();

  const handleDelete = async () => {
    try {
      const confirm = window.confirm(t("confirm_delete_message"));
      if (!confirm) return;
      const { ok } = await api.remove(`/stripe/requisition/${requisition.stripeId}`);
      if (!ok) throw new Error("Something went wrong");
      onDelete();
      toast.success("Stripe connection deleted");
    } catch (error) {
      console.log(error);
      toast.error(error);
    }
  };

  if (!stripe) return null;

  return (
    <tr className="h-20 border-b w-full border-[#E0E0E1] last:border-none py-4">
      <td className="min-w-fit w-1/3 px-12">{t("billing")}</td>
      <td>
        <div className="flex justify-between flex-wrap items-center pl-3">
          <div className="flex justify-start items-center h-10">
            <img src={StripeLogo} alt={stripe.name} height={40} width={40} />
            <div className="ml-3">
              <div className="font-semibold">{stripe.name}</div>
              <div className="text-xs">{requisition.name}</div>
            </div>
          </div>
          <div className="flex items-center justify-center gap-5">
            {/* todo : remove once the migration from RentSaver to Finotor is done */}
            {!requisition.accessToken && (
              <button
                className="btn-primary btn-small focus:bg-blue"
                onClick={() =>
                  window.open(
                    `https://dashboard.stripe.com/oauth/authorize?response_type=code&client_id=${STRIPE_OAUTH_CLIENT_ID}&scope=read_write&redirect_uri=https://app.finotor.com/bank/add?stripe_redirect=true`,
                    "_blank",
                  )
                }>
                {t("myintegrations.renew_account")}
              </button>
            )}
          </div>
        </div>
      </td>
      <td>
        <button className="m-2 p-2 cursor-pointer hover:text-red-500" onClick={handleDelete}>
          <IoClose />
        </button>
      </td>
    </tr>
  );
};

export default List;
