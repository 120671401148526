import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import api from "@services/api";
import { FaDownload } from "react-icons/fa6";
import { FaInfoCircle } from "react-icons/fa";
import { ResponsivePie } from "@nivo/pie";
import { Combobox } from "@components/utils/Combobox.jsx";
import { currencyToStr } from "@utils";
import { Listbox, Switch } from "@headlessui/react";
import { useSelector } from "react-redux";
import { Mixpanel } from "../../services/mixpanel";
import { MIXPANEL_EVENTS } from "../../utils/mixpanelEvents";

const years = Array.from({ length: new Date().getFullYear() - 2020 + 1 }, (_, i) => new Date().getFullYear() - i);

const ProfitLoss = () => {
  const { t } = useTranslation();
  const [profitLoss, setProfitLoss] = useState({});
  const [profitLossDetails, setProfitLossDetails] = useState({});
  const [filter, setFilter] = useState();
  const [accountIds, setAccountIds] = useState(null);
  const { organization } = useSelector((state) => state.Auth);

  useEffect(() => {
    Mixpanel.track(MIXPANEL_EVENTS.page_view_analytics_profit_loss);
  }, []);

  const getAccountIds = async () => {
    const { data: accounts } = await api.post("/account/search", { OrganizationId: organization._id });
    setAccountIds(accounts.map(({ _id }) => _id));
  };
  const getProfitAndLoss = async () => {
    const { data } = await api.post("/analytics/profit_loss", { accountsId: accountIds, OrganizationId: organization._id, ...filter });
    setProfitLoss(data);
  };
  const getProfitAndLossDetails = async () => {
    const { data: details } = await api.post("/analytics/profit_loss_details", { accountsId: accountIds, OrganizationId: organization._id, ...filter });
    setProfitLossDetails(details);
  };
  useEffect(() => {
    if (!organization) return;
    getAccountIds();

    const currentDate = new Date();
    currentDate.setUTCMonth(currentDate.getMonth() + 1);
    currentDate.setUTCDate(0);
    currentDate.setUTCHours(0, 0, 0, 0);

    const fromDate = new Date();
    fromDate.setUTCMonth(fromDate.getMonth() - 4);
    fromDate.setUTCDate(0);
    fromDate.setUTCHours(0, 0, 0, 0);

    setFilter({ currency: "EUR", by: "month", to: currentDate, since: fromDate });
  }, [organization]);

  useEffect(() => {
    if (accountIds && filter) {
      getProfitAndLoss();
      getProfitAndLossDetails();
    }
  }, [accountIds, filter]);

  const handleSetToDate = (e) => {
    const newDate = new Date(e.target.value);
    newDate.setUTCMonth(newDate.getMonth() + 1);
    newDate.setUTCDate(0);
    newDate.setUTCHours(0, 0, 0, 0);
    setFilter((date) => ({ ...date, to: newDate }));
  };
  const handleSetSinceDate = (e) => {
    const newDate = new Date(e.target.value);
    newDate.setUTCMonth(newDate.getMonth());
    newDate.setUTCDate(1);
    newDate.setUTCHours(0, 0, 0, 0);
    setFilter((date) => ({ ...date, since: newDate }));
  };
  const handleChangeDateFormat = (checked) => {
    if (!checked) {
      const currentDate = new Date();
      currentDate.setUTCMonth(currentDate.getMonth() + 1);
      currentDate.setUTCDate(0);
      currentDate.setUTCHours(0, 0, 0, 0);

      const fromDate = new Date();
      fromDate.setUTCMonth(fromDate.getMonth() - 4);
      fromDate.setUTCDate(1);
      fromDate.setUTCHours(0, 0, 0, 0);

      return setFilter({ ...filter, by: "month", to: currentDate, since: fromDate });
    }
    const since = new Date("2023-01");
    since.setUTCMonth(since.getMonth());
    since.setUTCDate(1);
    since.setUTCHours(0, 0, 0, 0);

    const to = new Date("2024-12");
    to.setUTCMonth(to.getMonth() + 1);
    to.setUTCDate(0);
    to.setUTCHours(0, 0, 0, 0);
    setFilter((date) => ({ ...date, by: "year", since: since, to: to }));
  };

  return (
    <div className="flex flex-col gap-6 items-start text-left text-black-100 ">
      <div className="text-blue text-sm flex gap-1 items-center">
        <FaInfoCircle />
        {t("beta_version")}
      </div>
      <div className="font-semibold items-center w-full">
        <div>{t("analytics.filter")}</div>
        <div className="flex gap-2 items-center text-xs text-black-90">
          <div className="flex gap-2 items-center font-semibold">
            <span>{t("month")}</span>
            <Switch
              checked={filter?.by === "year"}
              onChange={handleChangeDateFormat}
              className="group inline-flex h-6 w-11 items-center rounded-full bg-gray-200 transition aria-checked:bg-blue-600">
              <span className="w-4 aspect-square translate-x-1 rounded-full bg-white transition group-aria-checked:translate-x-6" />
            </Switch>
            <span>{t("year")}</span>
          </div>
          {filter?.by === "month" ? (
            <>
              <input className="rounded-lg px-2" type={filter?.by} value={filter?.since.toISOString().slice(0, 7)} onChange={handleSetSinceDate} />
              <span>-</span>
              <input className="rounded-lg px-2" type={filter?.by} value={filter?.to.toISOString().slice(0, 7)} onChange={handleSetToDate} />
            </>
          ) : (
            <>
              <Listbox value={filter?.since.getFullYear()} onChange={(value) => handleSetSinceDate({ target: { value: `${value}-12` } })}>
                <div className="relative">
                  <div className="w-full flex gap-4 py-2 pl-3 pr-8 items-center justify-between bg-white border border-gray-300 rounded-md h-10 overflow-hidden hover:border-sky-700">
                    <Listbox.Button>{filter?.since.getFullYear()}</Listbox.Button>
                    <Listbox.Options className={`absolute z-20  my-1 list-none top-0 max-h-64 overflow-y-auto border border-gray-300 rounded-md bg-white divide-y divide-gray-200`}>
                      {years.map((year) => (
                        <Listbox.Option className="py-2 pl-3 pr-8 hover:bg-gray-200" key={year} value={year}>
                          {year}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </div>
                </div>
              </Listbox>
              -
              <Listbox value={filter?.to.getFullYear()} onChange={(value) => handleSetToDate({ target: { value: `${value}-12` } })}>
                <div className="relative">
                  <div className="w-full flex gap-4 py-2 pl-3 pr-8 items-center justify-between bg-white border border-gray-300 rounded-md h-10 overflow-hidden hover:border-sky-700">
                    <Listbox.Button>{filter?.to.getFullYear()}</Listbox.Button>
                    <Listbox.Options className={`absolute z-20  my-1 list-none top-0 max-h-64 overflow-y-auto border border-gray-300 rounded-md bg-white divide-y divide-gray-200`}>
                      {years.map((year) => (
                        <Listbox.Option className="py-2 pl-3 pr-8 hover:bg-gray-200" key={year} value={year}>
                          {year}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </div>
                </div>
              </Listbox>
            </>
          )}
          <div className="w-40">
            <Combobox
              id="currency"
              options={["EUR", "USD", "GBP", "CHF", "CAD", "AUD", "YEN", "JPY", "NZD", "SEK", "CNH", "PLN", "AED"]}
              value={filter?.currency}
              onChange={(value) => setFilter({ ...filter, currency: value })}
              placeholder={t("myintegrations.currency_placeholder")}
              nullable={false}
            />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 w-full">
        <div className="p-5 bg-white border border-black-20 rounded-xl">
          <div className="text-sm mb-1">{t("revenue")}</div>
          <div className="font-semibold text-2xl mb-4">
            {Math.round(profitLoss?.totalRevenue * 100) / 100 || "0"} {currencyToStr(filter?.currency)}
          </div>
          <ul className="list-none">
            {profitLoss?.revenue?.map((category) => (
              <li key={category.category} className="grid grid-cols-2 gap-2 items-end my-1">
                <div className="flex justify-between gap-1 items-end text-sm">
                  {t(`transactions.${category.category}`)} <span>:</span>
                </div>
                <div className="font-semibold">
                  {Math.round(category.amount * 100) / 100} {currencyToStr(filter?.currency)}
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="p-5 bg-white border border-black-20 rounded-xl">
          <div className="text-sm mb-1">{t("cost")}</div>
          <div className="font-semibold text-2xl mb-4">
            {Math.round(profitLoss?.totalCost * 100) / 100 || "0"} {currencyToStr(filter?.currency)}
          </div>
          <ul className="list-none">
            {profitLoss?.cost?.map((category) => (
              <li key={category.category} className="grid grid-cols-2 gap-2 items-end my-1">
                <div className="flex justify-between gap-1 items-end text-sm">
                  {t(`transactions.${category.category}`)} <span>:</span>
                </div>
                <div className="font-semibold">
                  {Math.round(category.amount * 100) / 100} {currencyToStr(filter?.currency)}
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="p-5 bg-white border border-black-20 rounded-xl">
          <div className="text-sm mb-1">{t("profit")}</div>
          <div className="font-semibold text-2xl">
            {Math.round(profitLoss?.profit * 100) / 100 || "0"} {currencyToStr(filter?.currency)}
          </div>
        </div>
      </div>
      <Pies data={profitLoss}></Pies>
      <Details profitLossDetails={profitLossDetails} currency={currencyToStr(filter?.currency)} />
    </div>
  );
};

const Details = ({ profitLossDetails, currency }) => {
  const { t } = useTranslation();

  const [dataTable, setDataTable] = useState([]);
  const [dataToCompare, setDataToCompare] = useState([]);
  const [compareMode, setCompareMode] = useState(false);

  useEffect(() => {
    //Table construction for display
    const table = [];
    const dates = [""];
    const revenues = ["revenue"];
    const costs = ["cost"];
    const profit = ["profit"];

    //Fill table header
    profitLossDetails?.byDate?.toReversed().forEach((value) => dates.push(value.date));
    table.push(dates);

    //Revenue by category
    //For each category we look if there is a revenue for each month of the table
    profitLossDetails?.byCategory?.forEach(({ category, value }) => {
      const line = [];
      table[0].map((date, index) => {
        if (index === 0) return;
        let found = false;
        value.map(({ Date, Revenue }) => {
          if (Date === date) {
            line.push(Number(Revenue));
            found = true;
          }
        });
        if (!found) line.push(0);
      });
      if (line.some((value) => value !== 0)) table.push([category, ...line]);
    });
    //Total Revenue for each month (sum of all category's revenue)
    table[0].forEach((date, index) => {
      if (index === 0) return;
      let MonthRevenue = 0;
      for (let i = 1; i < table.length; i++) {
        MonthRevenue += table[i][index];
      }
      revenues.push(Math.round(MonthRevenue * 100) / 100);
    });
    table.push(revenues);
    const beginCost = table.length;

    //Cost by category
    //For each category we look if there is a cost for each month of the table
    profitLossDetails?.byCategory?.forEach(({ category, value }) => {
      const line = [];
      table[0].map((date, index) => {
        if (index === 0) return;
        let found = false;
        value.map(({ Date, Cost }) => {
          if (Date === date) {
            line.push(Number(Cost) * -1);
            found = true;
          }
        });
        if (!found) line.push(0);
      });
      if (line.some((value) => value !== 0)) table.push([category, ...line]);
    });
    //Total Cost for each month (sum of all category's cost)
    table[0].forEach((date, index) => {
      if (index === 0) return;
      let MonthCost = 0;
      for (let i = beginCost; i < table.length; i++) {
        MonthCost += table[i][index];
      }
      costs.push(Math.round(MonthCost * 100) / 100);
    });
    table.push(costs);

    //Total Profit for each month ( revenue + cost)
    table[0].forEach((date, index) => {
      if (index === 0) return;
      let MonthProfit = table[beginCost - 1][index] + table[table.length - 1][index];

      profit.push(Math.round(MonthProfit * 100) / 100);
    });
    table.push(profit);

    setDataTable(table);
  }, [profitLossDetails]);
  useEffect(() => {
    if (dataToCompare.length === 0) setCompareMode(false);
  }, [dataToCompare]);

  const isTotalRow = (row) => {
    return ["revenue", "cost", "profit"].includes(row[0]);
  };

  return (
    <div className="bg-white w-full py-3 rounded-xl">
      <div className="flex justify-between items-center px-5 pb-3 border-b border-black-20">
        <div className="font-semibold">{t("details")}</div>
        <div className="flex gap-4 items-center">
          <div className="flex gap-2 items-center font-semibold">
            <Switch
              checked={compareMode}
              onChange={setCompareMode}
              className="group inline-flex h-6 w-11 items-center rounded-full bg-gray-200 transition aria-checked:bg-blue-600">
              <span className="w-4 aspect-square translate-x-1 rounded-full bg-white transition group-aria-checked:translate-x-6" />
            </Switch>
            <span>Compare</span>
          </div>
          <button className=" flex gap-2 items-center px-3 py-2.5 border border-black-90 rounded-lg text-black-90 hover:border-black-100 hover:text-black-100">
            {`${t("download")} (${t("coming")})`}
            <FaDownload className="text-blue" />
          </button>
        </div>
      </div>
      <div className="p-5 relative overflow-hidden bg-white">
        <div className="relative overflow-hidden overflow-x-auto bg-white">
          {profitLossDetails?.byDate?.length && profitLossDetails?.byCategory?.length ? (
            <table>
              <thead className="p-2">
                <tr>
                  {dataTable[0]?.map((colHeader, index) => {
                    if (index === 0 || !compareMode || (compareMode && dataToCompare.find((i) => i === index))) {
                      return (
                        <th key={colHeader} className={`${index === 0 ? "sticky left-0 z-10" : ""} p-4 bg-white`}>
                          <div className="flex gap-2 items-center">
                            <span>{colHeader}</span>
                            {index !== 0 && (
                              <input
                                type="checkbox"
                                value={dataToCompare.find((i) => i === index)}
                                onClick={() => {
                                  const i = dataToCompare.find((v) => v === index);
                                  const newToCompare = i ? [...dataToCompare.filter((d) => d !== i)] : [...dataToCompare, index];
                                  setDataToCompare(newToCompare);
                                }}
                              />
                            )}
                          </div>
                        </th>
                      );
                    }
                  })}
                </tr>
              </thead>
              <tbody>
                {dataTable?.map((row, index) => {
                  if (index === 0) return;
                  return (
                    <tr className={`${isTotalRow(row) ? "bg-black-20 font-semibold" : "bg-white text-black-90"} border-b border-black-20`} key={`row-${index}`}>
                      {row.map((value, indexCol) => {
                        if (indexCol === 0 || !compareMode || (compareMode && dataToCompare.find((i) => i === indexCol))) {
                          if (indexCol == 0)
                            return (
                              <td key={`${index}-${indexCol}`} className={`${isTotalRow(row) ? "bg-black-20" : "bg-white"} p-4 sticky left-0 z-10`}>
                                {isTotalRow(row) ? t(`${value}`) : t(`transactions.${value}`)}
                              </td>
                            );
                          return (
                            <td key={`${index}-${indexCol}`} className={`p-4 ${value < 0 ? "text-red-600" : ""}`}>
                              {value ? `${value} ${currency}` : "-"}
                            </td>
                          );
                        }
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <div className="text-center font-semibold text-xl">{t("no_data")}</div>
          )}
        </div>
      </div>
    </div>
  );
};
const palette = ["#132a39", "#274e63", "#277293", "#40a5c6", "#b5e0ec", "#c8a800", "#fad200", "#fbdb32", "#fce466"];
const Pies = ({ data }) => {
  const { t } = useTranslation();
  const [arrangedRevenue, setArrangedRevenue] = useState(null);
  const [arrangedCost, setArrangedCost] = useState(null);

  useEffect(() => {
    setArrangedRevenue(
      data?.revenue
        ?.map(({ category, amount }, index) => ({
          id: t(`transactions.${category}`),
          label: t(`transactions.${category}`),
          value: data?.totalRevenue ? amount / data?.totalRevenue : 0,
          color: palette[index % 10],
        }))
        .sort((a, b) => b.value - a.value),
    );
    setArrangedCost(
      data?.cost
        ?.map(({ category, amount }, index) => ({
          id: t(`transactions.${category}`),
          label: t(`transactions.${category}`),
          value: data?.totalCost ? amount / data?.totalCost : 0,
          color: palette[index % 10],
        }))
        .sort((a, b) => b.value - a.value),
    );
  }, [data]);

  return (
    <div className="grid grid-cols-1 h-fit md:grid-cols-2 w-full gap-5 relative">
      <div className="bg-white flex flex-col h-fit w-full rounded-lg relative">
        <div className="text-sm px-5 py-3 font-bold border-b border-black-20">{t("revenue")}</div>
        <div className="absolute top-[150px] left-0 right-0 grid text-center place-content-center font-bold">
          <div>{t("amount")}</div>
          <div>{Math.round(data.totalRevenue * 100) / 100} €</div>
        </div>
        <div className="flex flex-col justify-between h-full">
          <div className="h-[250px]">
            <ResponsivePie
              data={arrangedRevenue ?? []}
              margin={{ top: 10, right: 50, bottom: 10, left: 50 }}
              valueFormat=" >-.2%"
              innerRadius={0.7}
              padAngle={0}
              cornerRadius={0}
              enableArcLinkLabels={false}
              enableArcLabels={false}
              colors={arrangedRevenue?.map(({ color }) => color)}
            />
          </div>
          <div className=" text-[11px] relative left-0 right-0 bottom-1 p-3 flex flex-wrap justify-center gap-3">
            {arrangedRevenue?.map((legend) => (
              <div key={legend.label} className="flex gap-1 items-center">
                <div className="w-4 aspect-square rounded-sm" style={{ backgroundColor: legend.color }}></div>
                {legend.label} ({Math.round(legend.value * 10000) / 100}%)
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="bg-white flex flex-col w-full rounded-lg relative">
        <div className="text-sm px-5 py-3 font-bold border-b border-black-20">{t("cost")}</div>
        <div className="absolute top-[150px] left-0 right-0 grid text-center place-content-center font-bold">
          <div>{t("amount")}</div>
          <div>{Math.round(data.totalCost * 100) / 100} €</div>
        </div>
        <div className="flex flex-col justify-between h-full">
          <div className="h-[250px]">
            <ResponsivePie
              data={arrangedCost ?? []}
              margin={{ top: 10, right: 50, bottom: 10, left: 50 }}
              valueFormat=" >-.2%"
              innerRadius={0.7}
              padAngle={0}
              cornerRadius={0}
              enableArcLinkLabels={false}
              enableArcLabels={false}
              colors={arrangedCost?.map(({ color }) => color)}
            />
          </div>
          <div className="text-[11px] relative left-0 right-0 bottom-1 p-3 flex flex-wrap justify-center gap-3">
            {arrangedCost?.map((legend) => (
              <div key={legend.label} className="flex gap-1 items-center">
                <div className="w-4 aspect-square rounded-sm" style={{ backgroundColor: legend.color }}></div>
                {legend.label} ({Math.round(legend.value * 10000) / 100}%)
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProfitLoss;
