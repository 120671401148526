export const authActions = {
  SETUSER: "SETUSER",
  SETORGANIZATION: "SETORGANIZATION",
};

export function setUser(user) {
  return { type: authActions.SETUSER, user };
}

export function setOrganization(organization) {
  return { type: authActions.SETORGANIZATION, organization };
}
