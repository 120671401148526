import mixpanel from "mixpanel-browser";
import { ENVIRONMENT } from "../config";
mixpanel.init("a70c1ab78b4d882e9e50d3a330a4734a");

let env_check = ENVIRONMENT === "production";

let actions = {
  identify: (id) => {
    if (env_check) mixpanel.identify(id);
  },
  alias: (id) => {
    if (env_check) mixpanel.alias(id);
  },
  track: (name, props) => {
    if (env_check) mixpanel.track(name, props);
  },
  people: {
    set: (props) => {
      if (env_check) mixpanel.people.set(props);
    },
  },
};

export let Mixpanel = actions;
