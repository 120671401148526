// prettier-ignore
const categories = [
  {
    "id": "2000",
    "name": "Sales",
    "group": "Revenue",
    "description": "Income from sales"
  },
  {
    "id": "2100",
    "name": "Sales",
    "group": "Revenue",
    "description": "Payout Stripe"
  },
  {
    "id": "2200",
    "name": "Subscriptions",
    "group": "Revenue",
    "description": "Income from subscriptions"
  },
  {
    "id": "2600",
    "name": "Other Revenue",
    "group": "Revenue",
    "description": "Any other income that does not relate to normal business activities and is not recurring"
  },
  {
    "id": "2999",
    "name": "Interest Income",
    "group": "Revenue",
    "description": "Interest income"
  },
  {
    "id": "3000",
    "name": "Cost of Goods Sold",
    "group": "Direct Costs",
    "description": "Freight and delivery for goods sold"
  },
  {
    "id": "4000",
    "name": "Advertising",
    "group": "Expense",
    "description": "Expenses incurred for advertising while trying to increase sales"
  },
  {
    "id": "4040",
    "name": "Bank Fees",
    "group": "Expense",
    "description": "Fees charged by your bank for transactions regarding your bank account(s)."
  },
  {
    "id": "4050",
    "name": "Stripe Processing fees",
    "group": "Expense",
    "description": "Fee for Stripe processing online payments"
  },
  {
    "id": "4060",
    "name": "Paypal Processing fees",
    "group": "Expense",
    "description": "Fee for Paypal processing online payments"
  },
  {
    "id": "4080",
    "name": "Cleaning",
    "group": "Expense",
    "description": "Expenses incurred for cleaning business property."
  },
  {
    "id": "4120",
    "name": "Consulting & Accounting",
    "group": "Expense",
    "description": "Expenses related to paying consultants"
  },
  {
    "id": "4160",
    "name": "Depreciation",
    "group": "Expense",
    "description": "The amount of the asset's cost (based on the useful life) that was consumed during the period"
  },
  {
    "id": "4200",
    "name": "Entertainment",
    "group": "Expense",
    "description": "Expenses paid by company for the business but are not deductable for income tax purposes."
  },
  {
    "id": "4250",
    "name": "Freight & Courier",
    "group": "Expense",
    "description": "Expenses incurred on courier & freight costs"
  },
  {
    "id": "4290",
    "name": "General Expenses",
    "group": "Expense",
    "description": "General expenses related to the running of the business."
  },
  {
    "id": "4330",
    "name": "Insurance",
    "group": "Expense",
    "description": "Expenses incurred for insuring the business' assets"
  },
  {
    "id": "4370",
    "name": "Interest Expense",
    "group": "Expense",
    "description": "Any interest expenses paid to your tax authority, business bank accounts or credit card accounts."
  },
  {
    "id": "4380",
    "name": "IT",
    "group": "Expense",
    "description": "Office expense IT related"
  },
  {
    "id": "4410",
    "name": "Legal expenses",
    "group": "Expense",
    "description": "Expenses incurred on any legal matters"
  },
  {
    "id": "4450",
    "name": "Light, Power, Heating",
    "group": "Expense",
    "description": "Expenses incurred for lighting, powering or heating the premises"
  },
  {
    "id": "4490",
    "name": "Motor Vehicle Expenses",
    "group": "Expense",
    "description": "Expenses incurred on the running of company motor vehicles"
  },
  {
    "id": "4530",
    "name": "Office Expenses",
    "group": "Expense",
    "description": "General expenses related to the running of the business office."
  },
  {
    "id": "4540",
    "name": "Postage",
    "group": "Expense",
    "description": "Purchase of stamps and mailing costs"
  },
  {
    "id": "4610",
    "name": "Printing & Stationery",
    "group": "Expense",
    "description": "Expenses incurred by the entity as a result of printing and stationery"
  },
  {
    "id": "4620",
    "name": "Purchase of food",
    "group": "Expense",
    "description": "Food Purchase Expense: In Retail Stores and Supermarkets"
  },
  {
    "id": "4690",
    "name": "Rent",
    "group": "Expense",
    "description": "The payment to lease a building or area."
  },
  {
    "id": "4730",
    "name": "Repairs and Maintenance",
    "group": "Expense",
    "description": "Expenses incurred on a damaged or run down asset that will bring the asset back to its original condition."
  },
  {
    "id": "4740",
    "name": "Restaurant & Bar",
    "group": "Expense",
    "description": "Professional consumption in restaurants & bars"
  },
  {
    "id": "4760",
    "name": "Staff training",
    "group": "Expense",
    "description": "Professional training of staff"
  },
  {
    "id": "4770",
    "name": "Wages and Salaries",
    "group": "Expense",
    "description": "Payment to employees in exchange for their resources"
  },
  {
    "id": "4780",
    "name": "Superannuation",
    "group": "Expense",
    "description": "Superannuation contributions"
  },
  {
    "id": "4850",
    "name": "Subscriptions",
    "group": "Expense",
    "description": "E.g. Softs, Magazines, professional bodies"
  },
  {
    "id": "4890",
    "name": "Telephone & Internet",
    "group": "Expense",
    "description": "Expenditure incurred from any business-related phone calls, phone lines, or internet connections"
  },
  {
    "id": "4930",
    "name": "Travel - National",
    "group": "Expense",
    "description": "Expenses incurred from domestic travel which has a business purpose"
  },
  {
    "id": "4940",
    "name": "Travel - International",
    "group": "Expense",
    "description": "Expenses incurred from international travel which has a business purpose"
  },
  {
    "id": "4970",
    "name": "Bank Revaluations",
    "group": "Bank Revaluations",
    "description": "Bank account revaluations due for foreign exchange rate changes"
  },
  {
    "id": "4980",
    "name": "Unrealised Currency Gains",
    "group": "Unrealized Currency Gains",
    "description": "Unrealised currency gains on outstanding items"
  },
  {
    "id": "4990",
    "name": "Realised Currency Gains",
    "group": "Realized Currency Gains",
    "description": "Gains or losses made due to currency exchange rate changes"
  },
  {
    "id": "5050",
    "name": "Corporation Tax Expense",
    "group": "Expense",
    "description": "A percentage of total earnings paid to the government."
  },
  {
    "id": "5060",
    "name": "Fines and Penalties",
    "group": "Expense",
    "description": "Fines and penalties for late reporting and non-compliance"
  },
  {
    "id": "6100",
    "name": "Accounts Receivable",
    "group": "Accounts Receivable",
    "description": "Outstanding invoices the company has issued out to the client but has not yet received in cash at balance date."
  },
  {
    "id": "6150",
    "name": "Petty Cash",
    "group": "Current Asset",
    "description": "Cash withdrawal and deposit",
  },
  {
    "id": "6200",
    "name": "Prepayments",
    "group": "Current Asset",
    "description": "An expenditure that has been paid for in advance."
  },
  {
    "id": "6300",
    "name": "Inventory",
    "group": "Inventory",
    "description": "Value of tracked items for resale."
  },
  {
    "id": "7010",
    "name": "Buildings",
    "group": "Fixed Asset",
    "description": "buildings"
  },
  {
    "id": "7020",
    "name": "depreciation on buildings",
    "group": "Fixed Asset",
    "description": "Accumalated depreciation on buildings"
  },
  {
    "id": "7030",
    "name": "plant and machinery",
    "group": "Fixed Asset",
    "description": "Installations and machines involved or not in the production"
  },
  {
    "id": "7100",
    "name": "Office Equipment",
    "group": "Fixed Asset",
    "description": "Office equipment that is owned and controlled by the business"
  },
  {
    "id": "7110",
    "name": "Less Accumulated Depreciation on Office Equipment",
    "group": "Fixed Asset",
    "description": "The total amount of office equipment cost that has been consumed by the entity (based on the useful life)"
  },
  {
    "id": "7200",
    "name": "Computer Equipment",
    "group": "Fixed Asset",
    "description": "Computer equipment that is owned and controlled by the business"
  },
  {
    "id": "7210",
    "name": "Less Accumulated Depreciation on Computer Equipment",
    "group": "Fixed Asset",
    "description": "The total amount of computer equipment cost that has been consumed by the business (based on the useful life)"
  },
  {
    "id": "8000",
    "name": "Accounts Payable",
    "group": "Accounts Payable",
    "description": "Outstanding invoices the company has received from suppliers but has not yet paid at balance date"
  },
  {
    "id": "8030",
    "name": "Wages Payable",
    "group": "Wages Payable",
    "description": "Xero automatically updates this account for payroll entries created using Payroll and will store the payroll amount to be paid to the employee for the pay run. This account enables you to maintain separate accounts for employee Wages Payable amounts and Accounts Payable amounts"
  },
  {
    "id": "8080",
    "name": "Internal transfer",
    "group": "Internal transfer",
    "description": "Transfers between bank accounts"
  },
  {
    "id": "8200",
    "name": "Sales Tax",
    "group": "Sales Tax",
    "description": "The balance in this account represents Sales Tax owing to or from your tax authority. At the end of the tax period, it is this account that should be used to code against either the 'refunds from' or 'payments to' your tax authority that will appear on the bank statement. Xero has been designed to use only one sales tax account to track sales taxes on income and expenses, so there is no need to add any new sales tax accounts to Xero."
  },
  {
    "id": "8250",
    "name": "Employee Tax Payable",
    "group": "Current Liability",
    "description": "The amount of tax that has been deducted from wages or salaries paid to employes and is due to be paid"
  },
  {
    "id": "8260",
    "name": "Superannuation Payable",
    "group": "Current Liability",
    "description": "The amount of superannuation that is due to be paid"
  },
  {
    "id": "8300",
    "name": "Corporation Tax Payable",
    "group": "Current Liability",
    "description": "The amount of income tax that is due to be paid, also resident withholding tax paid on interest received."
  },
  {
    "id": "8400",
    "name": "Accruals",
    "group": "Historical Adjustment",
    "description": "For accountant adjustments"
  },
  {
    "id": "8500",
    "name": "Other Creditors",
    "group": "Current Liability",
    "description": "An entry that allows an unknown transaction to be entered, so the accounts can still be worked on in balance and the entry can be dealt with later."
  },
  {
    "id": "8600",
    "name": "Rounding",
    "group": "Rounding",
    "description": "An adjustment entry to allow for rounding"
  },
  {
    "id": "8770",
    "name": "Tracking Transfers",
    "group": "Tracking",
    "description": "Transfers between tracking categories"
  },
  {
    "id": "8810",
    "name": "Owner A Funds Introduced",
    "group": "Current Liability",
    "description": "Funds contributed by the owner"
  },
  {
    "id": "8820",
    "name": "Directors count account",
    "group": "Current Liability",
    "description": "Directors count account"
  },
  {
    "id": "9000",
    "name": "Loan",
    "group": "Non-current Liability",
    "description": "Money that has been borrowed from a creditor"
  },
  {
    "id": "9600",
    "name": "Retained Earnings",
    "group": "Retained Earnings",
    "description": "Do not Use"
  },
  {
    "id": "9700",
    "name": "Owner A Share Capital",
    "group": "Equity",
    "description": "The value of shares purchased by the shareholders"
  }
];
export default categories;
