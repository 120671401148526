import { useEffect, useState } from "react";
import { Formik, Field } from "formik";
import { useTranslation } from "react-i18next";
import validator from "validator";
import { Link, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import queryString from "query-string";

import LoadingButton from "@components/utils/LoadingButton";
import api from "@services/api";
import { setUser } from "@redux/auth/actions";
import Loader from "../../components/utils/Loader";

const SignupInvite = () => {
  const { t } = useTranslation();

  const params = queryString.parse(location.search);
  const { redirect, invitationToken } = params;
  const [errorToken, setErrorToken] = useState();
  const [data, setData] = useState();

  const dispatch = useDispatch();
  const user = useSelector((state) => state.Auth.user);

  useEffect(() => {
    (async () => {
      try {
        if (!invitationToken) return setErrorToken(true);
        const { data } = await api.post(`/user/invite_verify`, { invitationToken });
        setData(data);
      } catch (error) {
        setErrorToken(true);
      }
    })();
  }, []);

  if (errorToken) return <Navigate to="/auth" />;
  if (!data) return <Loader />;
  if (user) return <Navigate to={redirect || "/"} />;

  return (
    <div className="flex items-center justify-center flex-col h-full w-full">
      <div className="max-w-lg mx-auto w-full p-4">
        <div className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-8 text-left align-middle shadow-xl transition-all">
          <div className="text-3xl font-bold mb-8">{t("signup_title")}</div>
          <Formik
            enableReinitialize
            initialValues={data}
            onSubmit={async (values, actions) => {
              try {
                const response = await api.post(`/user/signup_invite`, {
                  ...values,
                  invitationToken,
                });
                if (!response.ok) return toast.error("Invalid logins");
                dispatch(setUser(response.data));
              } catch (e) {
                console.log(e);
                toast.error("Invalid logins");
              }
            }}>
            {({ values, errors, isSubmitting, handleChange, handleSubmit }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <div className="flex items-center space-x-4 mb-6">
                    <div>
                      <label className="block text-sm mb-1" htmlFor="firstname">
                        {t("firstname")}
                      </label>
                      <Field
                        className="border-[1px] w-full border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-3 py-2"
                        validate={(v) => !v && t("error_required_field")}
                        id="firstname"
                        placeholder="Jean"
                        value={values.firstname}
                        onChange={handleChange}
                      />
                      {/* Error */}
                      {errors.firstname && <p className="text-[12px] text-[#FD3131]">{errors.firstname}</p>}
                    </div>
                    <div>
                      <label className="block text-sm mb-1" htmlFor="lastname">
                        {t("lastname")}
                      </label>
                      <Field
                        className="border-[1px] w-full border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-3 py-2"
                        validate={(v) => !v && t("error_required_field")}
                        id="lastname"
                        placeholder="Michel"
                        value={values.lastname}
                        onChange={handleChange}
                      />
                      {/* Error */}
                      {errors.lastname && <p className="text-[12px] text-[#FD3131]">{errors.lastname}</p>}
                    </div>
                  </div>
                  <div className="mb-6">
                    <label className="block text-sm mb-1" htmlFor="email">
                      {t("email")}
                    </label>
                    <Field
                      className="border-[1px] w-full border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-3 py-2"
                      validate={(v) => !validator.isEmail(v) && t("error_email_field")}
                      type="email"
                      id="email"
                      placeholder="example@mail.com"
                      value={values.email}
                      disabled
                    />
                    {/* Error */}
                    {errors.email && <p className="text-[12px] text-[#FD3131]">{errors.email}</p>}
                  </div>
                  <div className="mb-6">
                    <label className="block text-sm mb-1" htmlFor="password">
                      {t("signup_password")}
                    </label>
                    <Field
                      className="border-[1px] w-full border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-3 py-2"
                      validate={(v) => v && validator.isEmpty(v) && t("error_required_field")}
                      type="password"
                      id="password"
                      placeholder={t("enter_password")}
                      value={values.password}
                      onChange={handleChange}
                    />
                    {/* Error */}
                    {errors.password && <p className="text-[12px] text-[#FD3131]">{errors.password}</p>}
                  </div>
                  {/* SignIn Button */}
                  <LoadingButton
                    className="font-[Helvetica] w-[220px] bg-blue-500 hover:bg-blue-400 text-white rounded-[30px] m-auto block text-[16px] p-[8px] min-h-[42px] "
                    loading={isSubmitting}
                    disabled={!values.firstname || !values.lastname || values.email === "" || values.password === ""}
                    type="submit"
                    color="primary">
                    {t("signup")}
                  </LoadingButton>
                </form>
              );
            }}
          </Formik>
          <div className="text-sm mt-4">
            {t("have_account")}{" "}
            <Link to="/auth" className="font-medium hover:underline">
              {t("signin")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupInvite;
