import { useState, useEffect } from "react";
import api from "@services/api";
import toast, { LoaderIcon } from "react-hot-toast";
import Modal from "@components/utils/Modal";
import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import { Mixpanel } from "@services/mixpanel";
import { MIXPANEL_EVENTS } from "../../utils/mixpanelEvents";

const CreateClient = ({ onCreated, open, onClose }) => {
  const { t } = useTranslation();
  const [values, setValues] = useState({});

  useEffect(() => {
    if (open) {
      Mixpanel.track(MIXPANEL_EVENTS.btn_open_client_create_modal);
    }
    setValues({ entityType: "INDIVIDUAL" });
  }, [open]);

  return (
    <div>
      <Modal isOpen={open} onClose={() => onClose()} innerClassName="flex max-w-[50rem] w-[50vw] max-h-[calc(100vh-5rem)]">
        <div className="w-full max-h-full flex flex-col overflow-y-hidden transform rounded-2xl py-4 px-4 bg-white text-left align-middle shadow-xl transition-all">
          <div className="px-4 text-left text-[26px] font-semibold"> {t("clients.create_a_client")} </div>
          <div className="py-4 px-4 overflow-auto">
            <div className="grid grid-cols-2 grid-rows-1 gap-6 mb-4">
              <div className="flex flex-col row-start-1">
                <div className="text-base mb-1 font-semibold"> {t("type")} </div>
                <div className="inline-flex items-center gap-4">
                  <div className="flex items-center">
                    <input
                      type="radio"
                      id="INDIVIDUAL"
                      name="entityType"
                      value="INDIVIDUAL"
                      checked={values.entityType === "INDIVIDUAL"}
                      onChange={() => setValues({ ...values, entityType: "INDIVIDUAL" })}
                    />
                    <label htmlFor="INDIVIDUAL" className="ml-2">
                      {t("individual")}
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      type="radio"
                      id="COMPANY"
                      name="entityType"
                      value="COMPANY"
                      checked={values.entityType === "COMPANY"}
                      onChange={() => setValues({ ...values, entityType: "COMPANY" })}
                    />
                    <label htmlFor="COMPANY" className="ml-2">
                      {t("company")}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            {values.entityType === "COMPANY" ? (
              <CompanyForm values={values} setValues={setValues} onClose={onClose} onCreated={onCreated} />
            ) : (
              <IndividualForm values={values} setValues={setValues} onClose={onClose} onCreated={onCreated} />
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

const CompanyForm = ({ values, setValues, onClose, onCreated }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { organization } = useSelector((state) => state.Auth);

  const handleSubmit = async (event) => {
    try {
      setIsLoading(true);
      event.preventDefault();
      const submittedValues = {
        entityType: "COMPANY",
        companyName: values.companyName,
      };
      const { data } = await api.post("/client", { ...submittedValues, OrganizationId: organization._id });
      toast.success(t("toast.sucess.created"));
      onClose();
      onCreated(data);
    } catch (e) {
      console.error(t("toast.error"), e);
      toast.error(e.code);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
      <div className="grid grid-cols-2 grid-rows-1 gap-6">
        <div className="flex flex-col row-start-1">
          <div className="text-base mb-1 font-semibold"> {t("company_name")} </div>
          <input
            required
            type="text"
            defaultValue={values?.companyName}
            onChange={(e) => setValues({ ...values, companyName: e.target.value })}
            className="border-[1px] border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-3 py-2"
            placeholder="Tesla"
          />
        </div>
      </div>
      <div className="flex justify-between mt-4 gap-6">
        <button type="button" className="btn-secondary" onClick={() => onClose()}>
          {t("cancel")}
        </button>
        {!isLoading ? (
          <button type="submit" className="btn-primary" onClick={() => Mixpanel.track(MIXPANEL_EVENTS.btn_create_client, { entityType: "COMPANY" })}>
            {t("clients.create_company")}
          </button>
        ) : (
          <div className="btn-primary cursor-not-allowed flex items-center gap-2">
            <LoaderIcon className="animate-spin" />
            {t("clients.create_company")}
          </div>
        )}
      </div>
    </form>
  );
};

const IndividualForm = ({ values, setValues, onClose, onCreated }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { organization } = useSelector((state) => state.Auth);

  const handleSubmit = async (event) => {
    try {
      setIsLoading(true);
      event.preventDefault();
      const submittedValues = {
        entityType: "INDIVIDUAL",
        firstname: values.firstname,
        lastname: values.lastname,
      };
      const { data } = await api.post("/client", { ...submittedValues, OrganizationId: organization._id });
      toast.success(t("toast.sucess.created"));
      onClose();
      onCreated(data);
    } catch (e) {
      console.error(t("toast.error"), e);
      toast.error(e.code);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
      <div className="grid grid-cols-2 grid-rows-1 gap-6">
        <div className="flex flex-col row-start-1">
          <div className="text-base mb-1 font-semibold"> {t("first_name")} </div>
          <input
            required
            type="text"
            defaultValue={values?.firstname}
            onChange={(e) => setValues({ ...values, firstname: e.target.value })}
            className="border-[1px] border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-3 py-2"
            placeholder="Elon"
          />
        </div>
        <div className="flex flex-col row-start-1">
          <div className="text-base mb-1 font-semibold"> {t("last_name")} </div>
          <input
            required
            type="text"
            defaultValue={values?.lastname}
            onChange={(e) => setValues({ ...values, lastname: e.target.value })}
            className="border-[1px] border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-3 py-2"
            placeholder="Musk"
          />
        </div>
      </div>
      <div className="flex justify-between mt-4 gap-6">
        <button type="submit" className="btn-secondary" onClick={() => onClose()}>
          {t("cancel")}
        </button>
        {!isLoading ? (
          <button type="submit" className="btn-primary" onClick={() => Mixpanel.track(MIXPANEL_EVENTS.btn_create_client, { entityType: "INDIVIDUAL" })}>
            {t("clients.create_client")}
          </button>
        ) : (
          <div className="btn-primary cursor-not-allowed flex items-center gap-2">
            <LoaderIcon className="animate-spin" />
            {t("clients.create_client")}
          </div>
        )}
      </div>
    </form>
  );
};
export default CreateClient;
