import { useState, useEffect } from "react";
import api from "@services/api";
import toast from "react-hot-toast";
import Modal from "@components/utils/Modal";
import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import { Mixpanel } from "../../services/mixpanel.js";
import { MIXPANEL_EVENTS } from "../../utils/mixpanelEvents.js";

const CreateProduct = ({ onCreated, open, onClose }) => {
  const { t } = useTranslation();
  const [values, setValues] = useState({});
  const { organization } = useSelector((state) => state.Auth);

  async function onSave(event) {
    try {
      event.preventDefault();
      const { data } = await api.post("/product", { ...values, OrganizationId: organization._id });
      toast.success(t("toast.sucess.created"));
      onClose();
      onCreated(data);
    } catch (e) {
      console.error(t("toast.error"), e);
      toast.error(e.code);
    }
  }

  useEffect(() => {
    if (open) {
      Mixpanel.track(MIXPANEL_EVENTS.btn_open_product_create_modal);
    }
    setValues();
  }, [open]);

  return (
    <div>
      <Modal isOpen={open} onClose={() => onClose()} className="" innerClassName="flex max-w-[25rem] w-[25vw] min-w-[16rem] max-h-[calc(100vh-5rem)]">
        <div className="px-8 py-4 bg-white rounded-xl w-full">
          <div className="text-left text-[26px] font-semibold"> {t("products.creating_a_product")} </div>
          <form className="flex flex-col gap-4 mt-4" onSubmit={onSave}>
            <div className="grid grid-cols-2 grid-rows-1 gap-6">
              <div className="flex flex-col row-start-1">
                <div className="text-base mb-1 font-semibold capitalize"> {t("name")} </div>
                <input
                  required
                  type="text"
                  onChange={(e) => setValues({ ...values, name: e.target.value })}
                  className="border-[1px] border-gray-300 placeholder:text-gray-300 placeholder:capitalize rounded-lg text-gray-800 px-3 py-2"
                  placeholder={t("name")}
                />
              </div>
              <div className="flex flex-col row-start-1">
                <div className="text-base mb-1 font-semibold"> {t("reference")} </div>
                <input
                  required
                  type="text"
                  onChange={(e) => setValues({ ...values, reference: e.target.value })}
                  className="border-[1px] border-gray-300 placeholder:text-gray-300 placeholder:capitalize rounded-lg text-gray-800 px-3 py-2"
                  placeholder={t("reference")}
                />
              </div>
            </div>
            <div className="flex justify-between mt-4">
              <button type="button" className="btn-secondary" onClick={() => onClose()}>
                {t("cancel")}
              </button>
              <button type="submit" className="btn-primary" onClick={() => Mixpanel.track(MIXPANEL_EVENTS.btn_create_product)}>
                {t("create")}
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default CreateProduct;
