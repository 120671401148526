import { useState, useEffect } from "react";
import api from "@services/api";
import toast from "react-hot-toast";
import Modal from "@components/utils/Modal";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import { Mixpanel } from "../../services/mixpanel";
import { MIXPANEL_EVENTS } from "../../utils/mixpanelEvents";

const inviteUser = ({ onCreated }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({});
  const { search } = useLocation();
  const navigate = useNavigate();
  const { organization } = useSelector((state) => state.Auth);

  useEffect(() => {
    setOpen(new URLSearchParams(search).get("modal") === "invite-user");
  }, [search]);

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      const submittedValues = {
        firstname: values.firstname,
        lastname: values.lastname,
        email: values.email,
        OrganizationId: organization._id,
      };
      const { data } = await api.post("/user/invite", { ...submittedValues });
      toast.success(t("toast.sucess.user_invited"));
      setOpen(false);
      onCreated?.();
    } catch (e) {
      console.error(t("toast.error"), e);
      toast.error(e.code);
    }
  };

  return (
    <div>
      <button
        className="btn-primary focus:bg-blue float-right mb-2"
        onClick={() => {
          navigate("/settings?modal=invite-user");
          setOpen(true);
          Mixpanel.track(MIXPANEL_EVENTS.btn_open_organization_invite_modal);
        }}>
        {t("organization.invite_user")}
      </button>
      <Modal isOpen={open} onClose={() => navigate("/settings")} innerClassName="flex max-w-[50rem] w-[50vw] max-h-[calc(100vh-5rem)]">
        <div className="w-full max-h-full flex flex-col overflow-y-hidden transform rounded-2xl py-4 px-4 bg-white text-left align-middle shadow-xl transition-all">
          <div className="px-4 text-left text-[26px] font-semibold"> {t("organization.invite")} </div>
          <div className="py-4 px-4 overflow-auto">
            <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
              <div className="grid grid-cols-2 grid-rows-1 gap-6">
                <div className="flex flex-col row-start-1">
                  <div className="text-base mb-1 font-semibold"> {t("first_name")} </div>
                  <input
                    required
                    type="text"
                    defaultValue={values?.firstname}
                    onChange={(e) => setValues({ ...values, firstname: e.target.value })}
                    className="border-[1px] border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-3 py-2"
                    placeholder="Elon"
                  />
                </div>
                <div className="flex flex-col row-start-1">
                  <div className="text-base mb-1 font-semibold"> {t("last_name")} </div>
                  <input
                    required
                    type="text"
                    defaultValue={values?.lastname}
                    onChange={(e) => setValues({ ...values, lastname: e.target.value })}
                    className="border-[1px] border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-3 py-2"
                    placeholder="Musk"
                  />
                </div>
                <div className="flex flex-col">
                  <div className="text-base mb-1 font-semibold"> {t("email")} </div>
                  <input
                    required
                    type="text"
                    defaultValue={values?.email}
                    onChange={(e) => setValues({ ...values, email: e.target.value })}
                    className="border-[1px] border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-3 py-2"
                    placeholder="elonmusk@mail.com"
                  />
                </div>
              </div>
              <div className="flex justify-between mt-4 gap-6">
                <button type="submit" className="btn-secondary" onClick={() => setOpen(false)}>
                  {t("cancel")}
                </button>
                <button type="submit" className="btn-primary" onClick={() => Mixpanel.track(MIXPANEL_EVENTS.btn_invite_user)}>
                  {t("organization.invite")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default inviteUser;
