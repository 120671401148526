import { useState, useRef, useEffect } from "react";
import { IoEllipsisVertical } from "react-icons/io5";

const ActionsMenu = ({ actions, Icon = IoEllipsisVertical }) => {
  const [open, setOpen] = useState(false);
  const menuStyling = {
    menuVisibility: `${open ? "block p-2 sm:p-2" : "hidden"}`,
    menuTransition: `${open ? "translate-x-0" : "translate-x-[105%]"}`,
  };

  const wrapperRef = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <div className="flex gap-2 h-full  items-center">
      <div className="p-2 rounded-full hover:bg-gray-200 cursor-pointer" onClick={() => setOpen((open) => !open)}>
        <Icon />
      </div>
      <div
        className={`absolute  translate-y-[100%] translate-x-[-60%] flex flex-col gap-2 rounded overflow-hidden z-10 bg-[#fff] 
            ${menuStyling.menuVisibility} ${menuStyling.menuTransition}] transition duration-100 ease-in shadow-menuShadow `}
        open={open}
        ref={wrapperRef}>
        {actions &&
          Array.isArray(actions) &&
          actions.length > 0 &&
          actions.map((action) => (
            <div
              key={action.label}
              className="text-[15px] font-[Arial] text-left text-[#888888] cursor-pointer border-l-4 border-[#ffffff] hover:border-[#4d90fb] hover:bg-[#d3bfc731] hover:text-[#333]"
              onClick={action.callback}>
              {action.label}
            </div>
          ))}
      </div>
    </div>
  );
};
export default ActionsMenu;
