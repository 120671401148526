import { useEffect, useState } from "react";
import api from "@services/api";
import toast from "react-hot-toast";
import Loader from "@components/utils/Loader";
import Modal from "@components/utils/Modal";
import { useTranslation } from "react-i18next";
import { Mixpanel } from "../../services/mixpanel";
import { MIXPANEL_EVENTS } from "../../utils/mixpanelEvents";

const Edit = ({ employee, setEmployee, onSave }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState();

  useEffect(() => {
    setValues({ ...employee });
  }, [employee]);

  if (!employee || !values) return <div />;

  async function handleSubmit(e) {
    try {
      e.preventDefault();
      setLoading(true);
      const { ok, code } = await api.put(`/employee/${employee._id}`, { ...values });
      if (!ok) throw { code };
      toast.success(t("toast.sucess.updated_sucessfully"));
      setEmployee(null);
      onSave();
    } catch (e) {
      console.error(t("toast.error"), e);
      toast.error(e.code);
    }
    setLoading(false);
  }

  if (loading) return <Loader />;

  return (
    <Modal isOpen={true} onClose={() => setEmployee(null)} className="" innerClassName="flex max-w-[50rem] w-[80vw] max-h-[calc(100vh-5rem)]">
      <div className="w-full max-h-full flex flex-col overflow-y-hidden transform rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
        <div className="px-8 py-5 text-left text-lg font-semibold border-b"> {t("employees.edit")} </div>

        <form onSubmit={handleSubmit} className="overflow-auto flex flex-col">
          <div className="flex-1 flex flex-col gap-4 py-4 px-8 overflow-auto">
            <div className="flex flex-col gap-2">
              <div className="grid grid-cols-2 grid-rows-1 gap-4">
                <div className="flex flex-col">
                  <div className="text-base mb-1 font-semibold"> {t("first_name")} </div>
                  <input
                    required
                    type="text"
                    value={values.firstname || ""}
                    onChange={(e) => setValues({ ...values, firstname: e.target.value })}
                    className="border-[1px] border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-3 py-2"
                    placeholder={t("employees.edit.first_name_placeholder")}
                  />
                </div>
                <div className="flex flex-col">
                  <div className="text-base mb-1 font-semibold"> {t("last_name")} </div>
                  <input
                    required
                    type="text"
                    value={values.lastname || ""}
                    onChange={(e) => setValues({ ...values, lastname: e.target.value })}
                    className="border-[1px] border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-3 py-2"
                    placeholder={t("employees.edit.last_name_placeholder")}
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 grid-rows-1 gap-4">
                <div className="flex flex-col">
                  <div className="text-base mb-1 font-semibold"> {t("email")} </div>
                  <input
                    required
                    type="text"
                    value={values.email || ""}
                    onChange={(e) => setValues({ ...values, email: e.target.value })}
                    className="border-[1px] border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-3 py-2"
                    placeholder={t("employees.edit.email_placeholder")}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-between w-full mt-3 border-t py-3 px-8">
            <button type="button" className="btn-secondary" onClick={() => setEmployee(null)}>
              {t("cancel")}
            </button>
            <button type="submit" className="btn-primary" onClick={() => Mixpanel.track(MIXPANEL_EVENTS.btn_save_employee)}>
              {t("save")}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default Edit;
