import { useEffect } from "react";
export default function Modal({ isOpen, children, onClose, className = "w-full md:w-[calc(100%_-_60px)]", innerClassName = "w-full max-h-screen" }) {
  if (!isOpen) return null;

  return (
    <div className="fixed flex flex-col items-center justify-center w-full h-full top-0 left-0 z-50">
      <div className="absolute inset-0 z-0 overflow-y-auto bg-black bg-opacity-30 backdrop-blur-sm" onClick={onClose} />
      <div className={`${className} md:px-4 absolute z-10`}>
        <div className={`${innerClassName} z-10 bg-white rounded-lg absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 overflow-y-auto overscroll-y-contain`}>
          {children}
        </div>
      </div>
    </div>
  );
}
