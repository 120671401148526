import { useState, useEffect } from "react";
import api from "@services/api";
import toast from "react-hot-toast";
import EditEmployee from "./editEmployee";
import CreateEmployee from "@components/modals/createEmployee";
import ActionsMenu from "@components/utils/ActionsMenu";
import { Paginator } from "@components/utils/Pagination";
import { SearchBar } from "@components/utils/SearchBar";
import TruncatedText from "@components/utils/TruncatedText";
import { useTranslation } from "react-i18next";
import { Mixpanel } from "../../services/mixpanel";
import { MIXPANEL_EVENTS } from "../../utils/mixpanelEvents";
import { useSelector } from "react-redux";

const List = () => {
  const { t } = useTranslation();
  const [employees, setEmployees] = useState();
  const [selectedEmployee, setSelectedEmployee] = useState();
  const [filter, setFilter] = useState({ search: "", page: 1, per_page: 10 });
  const [total, setTotal] = useState(0);
  const [showCreateEmployee, setShowCreateEmployee] = useState(false);
  const { organization } = useSelector((state) => state.Auth);

  useEffect(() => {
    if (!organization) return;
    get();
  }, [filter, organization]);

  useEffect(() => {
    Mixpanel.track(MIXPANEL_EVENTS.page_view_employees);
  }, []);

  async function get() {
    const { data } = await api.post("/employee/search", {
      per_page: filter.per_page,
      page: filter.page,
      search: filter.search,
      OrganizationId: organization._id,
    });
    setEmployees(data.employees);
    setTotal(data.total);
  }

  async function handleDelete(id) {
    const confirm = window.confirm(t("confirm_message"));
    if (!confirm) return;
    await api.remove(`/employee/${id}`);
    toast.success(t("toast.sucess.removed"));
    get();
  }

  return (
    <div className="mx-auto">
      <div className="py-6 px-6">
        <div className="flex flex-col bg-white rounded-xl p-6">
          <div className="flex justify-between items-start">
            <div className="">
              <h1 className="text-2xl font-bold">{t("employees")}</h1>
              <p className="mt-2 text-slate-600">{t("employees.intro")}</p>
            </div>
            <div className="flex gap-2">
              <button className="btn-primary hover:bg-gray-700 focus:bg-blue float-right mb-2"> {t("import_from_csv")} </button>
              <button
                className="btn-primary focus:bg-blue float-right mb-2"
                onClick={() => {
                  setShowCreateEmployee(true);
                }}>
                {t("employees.create")}
              </button>
            </div>
          </div>
          <EditEmployee employee={selectedEmployee} setEmployee={() => setSelectedEmployee(null)} onSave={get} />
          <div className="flex flex-col space-y-5 mt-8">
            <SearchBar search={filter.search} setFilter={setFilter} />
            <Table
              employees={employees}
              onEdit={(client) => {
                setSelectedEmployee(client);
              }}
              onDelete={handleDelete}
            />
            <Paginator page={filter.page} setPage={(p) => setFilter({ ...filter, page: p })} last={Math.ceil(total / filter.per_page)} />
          </div>
        </div>
      </div>
      <CreateEmployee
        onCreated={(el) => {
          get();
          setSelectedEmployee(el);
        }}
        open={showCreateEmployee}
        onClose={() => setShowCreateEmployee(false)}
      />
    </div>
  );
};

const Table = ({ employees, onEdit, onDelete }) => {
  const { t } = useTranslation();
  return (
    <div className="overflow-x-auto flow-root min-w-full align-middle overflow-hidden rounded-lg border">
      <table className="min-w-full">
        <thead className="border-b">
          <tr>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-bold border-r uppercase">
              {t("name")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-500 uppercase">
              {t("email")}
            </th>

            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-500 uppercase">
              {t("actions")}
            </th>
          </tr>
        </thead>
        <tbody className="bg-white">
          {employees &&
            employees.map((employee) => (
              <tr key={employee._id}>
                <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900 border-r">
                  <span
                    className="cursor-pointer font-bold underline capitalize"
                    onClick={() => {
                      onEdit(employee);
                      Mixpanel.track(MIXPANEL_EVENTS.page_view_employees_edit);
                    }}>
                    <TruncatedText str={employee.firstname + " " + employee.lastname} />
                  </span>
                </td>
                <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">{employee.email || "-"}</td>

                <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                  <ActionsMenu
                    actions={[
                      {
                        label: t("edit"),
                        callback: () => {
                          onEdit(employee);
                          Mixpanel.track(MIXPANEL_EVENTS.page_view_employees_edit);
                        },
                      },
                      { label: t("delete"), callback: () => onDelete(employee._id) },
                    ]}
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default List;
