import { Routes, Route } from "react-router-dom";

import List from "./list";

const Index = () => {
  return (
    <Routes>
      <Route path="/" element={<List />} />
    </Routes>
  );
};
export default Index;
