import { useState, useEffect } from "react";
import api from "@services/api";
import toast from "react-hot-toast";
import Modal from "@components/utils/Modal";
import { useTranslation } from "react-i18next";
import { Mixpanel } from "@services/mixpanel";
import { MIXPANEL_EVENTS } from "../../utils/mixpanelEvents";

const CreateEmployee = ({ onCreated, open, onClose }) => {
  const { t } = useTranslation();
  const [values, setValues] = useState({});
  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      const { data } = await api.post("/employee", { ...values });
      toast.success(t("toast.sucess.created"));
      onClose();
      onCreated(data);
    } catch (e) {
      console.error(t("toast.error"), e);
      toast.error(e.code);
    }
  };

  useEffect(() => {
    if (open) {
      Mixpanel.track(MIXPANEL_EVENTS.btn_open_employee_create_modal);
    }
  }, [open]);

  return (
    <div>
      <Modal isOpen={open} onClose={() => onClose()} innerClassName="flex max-w-[50rem] w-[50vw] max-h-[calc(100vh-5rem)]">
        <div className="w-full max-h-full flex flex-col overflow-y-hidden transform rounded-2xl py-4 px-4 bg-white text-left align-middle shadow-xl transition-all">
          <div className="px-4 text-left text-[26px] font-semibold"> {t("employees.create")} </div>
          <div className="py-4 px-4 overflow-auto">
            <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
              <div className="grid grid-cols-2 grid-rows-1 gap-6">
                <div className="flex flex-col row-start-1">
                  <div className="text-base mb-1 font-semibold"> {t("first_name")} </div>
                  <input
                    required
                    type="text"
                    defaultValue={values?.firstname}
                    onChange={(e) => setValues({ ...values, firstname: e.target.value })}
                    className="border-[1px] border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-3 py-2"
                    placeholder="Elon"
                  />
                </div>
                <div className="flex flex-col row-start-1">
                  <div className="text-base mb-1 font-semibold"> {t("last_name")} </div>
                  <input
                    required
                    type="text"
                    defaultValue={values?.lastname}
                    onChange={(e) => setValues({ ...values, lastname: e.target.value })}
                    className="border-[1px] border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-3 py-2"
                    placeholder="Musk"
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 grid-rows-1 gap-4">
                <div className="flex flex-col">
                  <div className="text-base mb-1 font-semibold"> {t("email")} </div>
                  <input
                    required
                    type="text"
                    value={values.email || ""}
                    onChange={(e) => setValues({ ...values, email: e.target.value })}
                    className="border-[1px] border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-3 py-2"
                    placeholder={t("employees.edit.email_placeholder")}
                  />
                </div>
              </div>
              <div className="flex justify-between mt-4 gap-6">
                <button type="submit" className="btn-secondary" onClick={() => onClose()}>
                  {t("cancel")}
                </button>
                <button type="submit" className="btn-primary" onClick={() => Mixpanel.track(MIXPANEL_EVENTS.btn_create_employee, { entityType: "INDIVIDUAL" })}>
                  {t("employees.create_employee")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CreateEmployee;
