import { useState, useEffect } from "react";
import api from "@services/api";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const Stats = () => {
  const organization = useSelector((state) => state.Auth.organization);

  return (
    <div className="flex gap-4">
      <Billed organization={organization} />
      <Paid organization={organization} />
      <Overdue organization={organization} />
      <Draft organization={organization} />
    </div>
  );
};

const Billed = ({ organization }) => {
  const [billed, setBilled] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (!organization) return;
    getTotalBilled();
  }, [organization]);

  const getTotalBilled = async () => {
    const { data } = await api.post("/analytics/invoices", { statuses: ["PAID", "DUE", "SENT"], OrganizationId: organization._id });
    setBilled(data);
  };

  if (!billed) return null;

  return (
    <div className="border border-black-30 shadow-md rounded-lg px-5 py-3">
      <div className="flex justify-between items-center flex-col gap-2">
        <div className="flex gap-2 justify-center items-center">
          <div className="text-sm text-gray-500">{t("invoices.stats.billed")}</div>
          <div className="text-sm bg-purple-200 px-2 rounded-full flex justify-center items-center">{billed.count}</div>
        </div>
        {/* TODO: do it by currency */}
        <div className="text-lg font-semibold">{billed.total} €</div>
      </div>
    </div>
  );
};

const Paid = ({ organization }) => {
  const [paid, setPaid] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (!organization) return;
    getTotalPaid();
  }, [organization]);

  const getTotalPaid = async () => {
    const { data } = await api.post("/analytics/invoices", { statuses: ["PAID"], OrganizationId: organization._id });
    setPaid(data);
  };

  if (!paid) return null;

  return (
    <div className="border border-black-30 shadow-md rounded-lg px-5 py-3">
      <div className="flex justify-between items-center flex-col gap-2">
        <div className="flex gap-2 justify-center items-center">
          <div className="text-sm text-gray-500">{t("invoices.stats.paid")}</div>
          <div className="text-sm bg-green-200 px-2 rounded-full flex justify-center items-center">{paid.count}</div>
        </div>
        {/* TODO: do it by currency */}
        <div className="text-lg font-semibold">{paid.total} €</div>
      </div>
    </div>
  );
};

const Overdue = ({ organization }) => {
  const [overdue, setOverdue] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (!organization) return;
    getOverdue();
  }, [organization]);

  const getOverdue = async () => {
    const { data } = await api.post("/analytics/invoices", {
      statuses: ["DUE", "SENT"],
      overdue: true,
      OrganizationId: organization._id,
    });
    setOverdue(data);
  };

  if (!overdue) return null;

  return (
    <div className="border border-black-30 shadow-md rounded-lg px-5 py-3">
      <div className="flex justify-between items-center flex-col gap-2">
        <div className="flex gap-2 justify-center items-center">
          <div className="text-sm text-gray-500">{t("invoices.stats.overdue")}</div>
          <div className="text-sm bg-orange-200 px-2 rounded-full flex justify-center items-center">{overdue.count}</div>
        </div>
        {/* TODO: do it by currency */}
        <div className="text-lg font-semibold">{overdue.total} €</div>
      </div>
    </div>
  );
};

const Draft = ({ organization }) => {
  const [draft, setDraft] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (!organization) return;
    getDraft();
  }, [organization]);

  const getDraft = async () => {
    const { data } = await api.post("/analytics/invoices", {
      statuses: ["DRAFT"],
      OrganizationId: organization._id,
    });
    setDraft(data);
  };

  if (!draft) return null;

  return (
    <div className="border border-black-30 shadow-md rounded-lg px-5 py-3">
      <div className="flex justify-between items-center flex-col gap-2">
        <div className="flex gap-2 justify-center items-center">
          <div className="text-sm text-gray-500">{t("invoices.stats.draft")}</div>
          <div className="text-sm bg-gray-200 px-2 rounded-full flex justify-center items-center">{draft.count}</div>
        </div>
        {/* TODO: do it by currency */}
        <div className="text-lg font-semibold">{draft.total} €</div>
      </div>
    </div>
  );
};

export default Stats;
