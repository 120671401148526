import { truncate } from "@utils";
import { useState } from "react";

const TruncatedText = ({ str, n = 20, description }) => {
  const [isHovered, setIsHovered] = useState(false);

  if (!str) return "-";
  if (str.length <= n) return str;
  return (
    <div className="group flex relative">
      <span onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
        {truncate(str, n)}
      </span>
      <div className={`z-10 w-full whitespace-normal bg-gray-800 text-sm rounded-md absolute left-1/2 -translate-x-1/2 top-full m-4 mx-auto ${isHovered ? "" : "hidden"}`}>
        <div className="px-[8px] py-[4px] text-gray-100">{str}</div>
        {description ? <div className="px-[8px] py-[4px] text-xs text-gray-400 border-t-2 border-gray-600">{description}</div> : null}
      </div>
    </div>
  );
};

export default TruncatedText;
