import { useTranslation } from "react-i18next";
import { Combobox } from "@components/utils/Combobox";

const InvoiceParameter = ({ invoice, setInvoice, handleSave }) => {
  const { t } = useTranslation();
  return (
    <div className="">
      <h3 className="font-semibold text-md mb-4 text-black">{t("invoices.editor.parameter_title")}</h3>
      <LanguageParameter invoice={invoice} setInvoice={setInvoice} />
      <RecurrenceParameter invoice={invoice} setInvoice={setInvoice} />
      <button className="btn-primary w-max" onClick={handleSave}>
        {t("save")}
      </button>
    </div>
  );
};

const LanguageParameter = ({ invoice, setInvoice }) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="text-sm font-semibold mb-2">{t("invoices.editor.language")}</div>
      <div className="mb-4">
        <div className="flex gap-5 items-center justify-start">
          <label className="flex items-center gap-0.5">
            <input className="" type="radio" value="EN" checked={invoice.language === "EN"} onChange={() => setInvoice({ ...invoice, language: "EN" })} />
            <span className="ml-0.5 cursor-pointer">EN</span>
          </label>
          <label className="flex items-center gap-0.5">
            <input type="radio" value="FR" checked={invoice.language === "FR"} onChange={() => setInvoice({ ...invoice, language: "FR" })} />
            <span className="ml-0.5 cursor-pointer">FR</span>
          </label>
        </div>
      </div>
    </div>
  );
};

const RecurrenceParameter = ({ invoice, setInvoice }) => {
  const { t } = useTranslation();

  const handleRecurrenceChange = (value) => {
    if (value === "UNIQUE") {
      setInvoice({ ...invoice, type: value, ...{ InvoiceRecurrence: undefined } });
    } else {
      setInvoice({
        ...invoice,
        type: value,
        InvoiceRecurrence: {
          frequency: "MONTHS",
          interval: 1,
          startingAt: new Date(),
        },
      });
    }
  };

  return (
    <div>
      <div className="text-sm font-semibold mb-2">{t("invoices.editor.recurrence_title")}</div>
      <div className="mb-4">
        <Combobox
          value={invoice.type}
          options={["UNIQUE", "RECURRENT"]}
          onChange={(value) => {
            handleRecurrenceChange(value);
          }}
          placeholder={t("invoices.editor.select_type_recurrence")}
          getLabel={(option) => (option ? t(`invoices.editor.${option}_type`.toLowerCase()) : null)}
        />
      </div>
      {invoice.type === "RECURRENT" && <RepeatOccurence invoice={invoice} setInvoice={setInvoice} />}
    </div>
  );
};

const RepeatOccurence = ({ invoice, setInvoice }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col">
      <span className="text-sm font-semibold mb-1">{t("invoices.editor.repeat")}</span>
      <div className="flex items-center mb-2">
        <input
          type="number"
          className="w-10 border border-gray-300 rounded-md text-center"
          value={invoice.InvoiceRecurrence?.interval ?? 0}
          onChange={(e) => {
            setInvoice({
              ...invoice,
              InvoiceRecurrence: { ...invoice.InvoiceRecurrence, interval: e.target.value },
            });
          }}
        />
        <Combobox
          value={invoice.InvoiceRecurrence?.frequency}
          options={["WEEKS", "MONTHS", "YEARS"]}
          onChange={(value) => {
            setInvoice({
              ...invoice,
              InvoiceRecurrence: { ...invoice.InvoiceRecurrence, frequency: value },
            });
          }}
          placeholder={t("invoices.editor.select_repeat_frequency")}
          getLabel={(option) => (option ? t(`invoices.editor.${option}_repeat`.toLowerCase()) : null)}
        />
      </div>
      <h4 className="text-sm font-semibold mb-1">{t("invoices.editor.starting_date")}</h4>
      <input
        onChange={(date) =>
          setInvoice({ ...invoice, InvoiceRecurrence: { ...invoice.InvoiceRecurrence, startingAt: date.target.value ? new Date(date.target.value) : new Date() } })
        }
        onFocus={(date) => date.target.showPicker()}
        type="date"
        value={invoice.InvoiceRecurrence?.startingAt && new Date(invoice.InvoiceRecurrence.startingAt).toISOString().slice(0, 10)}
        placeholder="dd-mm-yyyy"
        className="border px-4 py-2 rounded-md"
      />
    </div>
  );
};

export default InvoiceParameter;
