import { useEffect, useState } from "react";
import api from "@services/api";
import toast from "react-hot-toast";
import Modal from "@components/utils/Modal";
import { Combobox } from "@components/utils/Combobox";
import categories from "@data/categories.js";
import { Select } from "@components/utils/Select";
import { currencies } from "@constants";

import { useTranslation } from "react-i18next";
import { Mixpanel } from "../../services/mixpanel";
import { MIXPANEL_EVENTS } from "../../utils/mixpanelEvents";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Edit = ({ product, setProduct, onSave }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState();
  const navigate = useNavigate();
  const organization = useSelector((state) => state.Auth.organization);

  useEffect(() => {
    setValues(product);
  }, [product]);

  if (!product || !values) return <div />;

  async function handleSubmit(e) {
    try {
      e.preventDefault();
      setLoading(true);
      const { ok, code } = await api.put(`/product/${product._id}`, { ...values });
      if (!ok) throw { code };
      toast.success(t("toast.sucess.updated_sucessfully"));
      setProduct(null);
      onSave();
    } catch (e) {
      console.error(t("toast.error"), e);
      toast.error(e.code);
    }
    setLoading(false);
  }

  const navigateToOrgSettings = () => {
    if (!window.confirm(t("confirm_unsaved_message"))) return;
    navigate("/settings/my-organizations");
  };

  return (
    <Modal isOpen={true} onClose={() => setProduct(null)} className="" innerClassName="flex max-w-[50rem] w-[80vw] max-h-[calc(100vh-5rem)]">
      <div className="w-full max-h-full flex flex-col overflow-y-hidden transform rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
        <div className="px-8 py-5 text-left text-lg font-semibold border-b">Editing a Product</div>

        <form onSubmit={handleSubmit} className="overflow-auto flex flex-col">
          <div className="flex-1 flex flex-col gap-4 py-4 px-8 overflow-auto">
            <div className="flex flex-col w-1/2 mr-2">
              <div className="text-base font-semibold text-gray-500 capitalize"> {t("name")} </div>
              <input
                required
                type="text"
                value={values.name ?? ""}
                onChange={(e) => setValues({ ...values, name: e.target.value })}
                className="border-[1px] border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-3 py-2"
                placeholder={t("name")}
              />
            </div>
            <div className="flex flex-col row-start-2">
              <div className="text-base font-semibold text-gray-500 capitalize"> {t("description")} </div>
              <textarea
                type="text"
                value={values.description ?? ""}
                onChange={(e) => setValues({ ...values, description: e.target.value })}
                className="border-[1px] border-gray-300 placeholder:text-gray-300 placeholder:capitalize rounded-lg text-gray-800 px-3 py-2"
                placeholder={t("description")}
              />
            </div>

            <separator className="w-full min-h-[1.5rem]" />

            <div className="flex flex-col w-1/2 mr-2">
              <div className="text-base font-semibold text-gray-500 capitalize"> {t("currency")} </div>
              <Select
                options={currencies}
                value={currencies.find((currency) => currency.id == values.currency)}
                onChange={(e) => setValues({ ...values, currency: e.id })}
                getLabel={(e) => (
                  <span>
                    <span className="bg-neutral-100 px-2 mr-2">{e.symbol}</span>
                    {e.name}
                  </span>
                )}
                buttonClassName="py-2"
                placeholder={t("select_currency")}
                width="border-[1px] border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-3 py-2"
                nullable={false}
              />
            </div>
            <div className="grid grid-cols-3 grid-rows-1 gap-2">
              <div className="flex flex-col">
                <div className="text-base font-semibold text-gray-500 capitalize">{t("products.edit.price_ht")}</div>
                <input
                  type="text"
                  value={values.price ?? ""}
                  onChange={(e) => setValues({ ...values, price: e.target.value })}
                  className="border-[1px] border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-3 py-2"
                  placeholder={t("price")}
                />
              </div>
              <div className="flex flex-col">
                <div className="text-base font-semibold text-gray-500 capitalize">Tax Rate (%)</div>
                <Combobox
                  options={organization.Taxes}
                  value={organization.Taxes.find((value) => value._id === values.TaxId)}
                  onChange={(value) => setValues({ ...values, TaxId: value._id })}
                  inputClassName="py-2"
                  listHeader={
                    <button className="px-3 py-2 bg-black-100 text-white w-full flex items-center justify-between font-semibold text-xs" onClick={navigateToOrgSettings}>
                      {t("add_vat")} <span>+</span>
                    </button>
                  }
                  placeholder="Type"
                  getLabel={(tax) => tax.name}
                />
              </div>
              <div className="flex flex-col">
                <div className="text-base font-semibold text-gray-500 capitalize"> {t("products.edit.price_ttc")} </div>
                <div className="border-[1px] input-height border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-3 py-2 bg-gray-100 disabled:text-gray-300">
                  {(parseFloat(values.price) + parseFloat(organization.Taxes.find((tax) => tax._id === values.TaxId)?.value / 100 || 0) * parseFloat(values.price)).toFixed(2)}{" "}
                  {currencies.find((currency) => currency.id == values.currency)?.symbol}
                </div>
              </div>
            </div>

            <separator className="w-full min-h-[1.5rem]" />

            <h2 className="text-base font-semibold text-gray-500 underline"> {t("products.type_product")} </h2>
            <div className="grid grid-cols-2 grid-rows-1 gap-8">
              <div className="flex flex-col">
                <label htmlFor="categories" className="text-base font-semibold text-gray-500 capitalize">
                  {t("category")}
                </label>
                <Combobox
                  value={categories.find((category) => category.id == values.category)}
                  options={categories}
                  onChange={(value) => setValues({ ...values, category: value ? value.id : null })}
                  getLabel={(e) => (e ? e.id + " - " + t(`transactions.${e.id}`) : null)}
                  placeholder={t("transactions.edit.placeholder.select_category")}
                />
              </div>

              <div className="flex flex-col">
                <div className="text-base font-semibold text-gray-500 capitalize"> {t("type")} </div>
                <Select
                  options={[
                    { id: "SERVICE", name: "Service" },
                    { id: "PRODUCT", name: "Product" },
                  ]}
                  buttonClassName="py-2"
                  value={{ id: values.type, name: values.type }}
                  onChange={(e) => setValues({ ...values, type: e.id })}
                  getLabel={(e) => e.name}
                  placeholder="Select a type"
                  width="w-full"
                  nullable={false}
                />
              </div>
            </div>

            <separator className="w-full min-h-[1.5rem]" />

            <div className="flex flex-col">
              <div className="text-base font-semibold text-gray-500 capitalize"> {t("reference")} </div>
              <input
                type="text"
                value={values.reference ?? ""}
                onChange={(e) => setValues({ ...values, reference: e.target.value })}
                className="border-[1px] border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-3 py-2"
                placeholder={t("reference")}
              />
            </div>
          </div>
          <div className="flex justify-between w-full mt-3 border-t py-3 px-8">
            <button type="button" className="btn-secondary" onClick={() => setProduct(null)} disabled={loading}>
              {t("cancel")}
            </button>
            <button type="submit" className="btn-primary" disabled={loading} onClick={() => Mixpanel.track(MIXPANEL_EVENTS.btn_save_product)}>
              {t("save")}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};
export default Edit;
