import { useEffect, useState } from "react";
import api from "@services/api";
import { Mixpanel } from "@services/mixpanel";
import Loader from "@components/utils/Loader";
import { useTranslation } from "react-i18next";
import { toast } from "react-hot-toast";
import { MIXPANEL_EVENTS } from "../../../utils/mixpanelEvents";
import { useSelector } from "react-redux";

const INVOICES = "INVOICES";

const Products = ({ setTab, setNewDataCount }) => {
  const { t } = useTranslation();
  const [products, setProducts] = useState();
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const { organization } = useSelector((state) => state.Auth);

  useEffect(() => {
    try {
      const syncProducts = async () => {
        setLoading(true);
        const res = await api.get(`/xero/products/`);
        setLoading(false);
        setProducts(res.data);
      };
      syncProducts();
    } catch (error) {
      setLoading(false);
      toast.error(t("toast.error.products_imported"));
    }
  }, []);

  useEffect(() => {
    setSelectedProducts(products || []);
  }, [products]);

  const importProducts = async () => {
    try {
      setLoading(true);
      const res = await api.post("/xero/products/import", { products: selectedProducts, OrganizationId: organization?._id });
      setNewDataCount((prevData) => ({
        ...prevData,
        newProducts: res.count,
      }));
      toast.success(t("toast.sucess.products_imported"));
      setLoading(false);
      setTab(INVOICES);
    } catch (error) {
      toast.error(t("toast.error.products_imported"));
      setLoading(false);
    }
  };

  if (loading) return <Loader />;
  return (
    <div className="border rounded-md mt-4  ">
      <div className="flex flex-col justify-between px-3.5 py-3.5 cursor-pointer">
        <div className="flex  justify-between  ">
          <div className="flex items-center gap-2 w-full">
            <div className="text-dull-black text-xl font-bold "> {t("sales_products")}</div>
          </div>

          <div className="w-full flex items-center justify-end gap-2">
            <button className={`flex-1 max-w-[10rem] btn-secondary btn-small`} onClick={() => setTab(INVOICES)}>
              {t("skip")}
            </button>

            <button
              disabled={selectedProducts?.length === 0}
              className={`flex-1 max-w-[12rem] btn-primary btn-small`}
              onClick={async (e) => {
                e.stopPropagation();
                await importProducts();
                Mixpanel.track(MIXPANEL_EVENTS["btn_import_xero-products"]);
              }}>
              {t("import_xero.import_products", { productsLenght: selectedProducts.length })}
            </button>
          </div>
        </div>
      </div>

      {!products || products?.length === 0 ? (
        <div className="px-3.5 py-3.5"> {t("import_xero.no_products")} </div>
      ) : (
        <div className="overflow-x-auto my-4 flow-root  align-middle overflow-hidden rounded-lg border mx-3">
          <table className="min-w-full">
            <thead className="border-b">
              <tr>
                <th scope="col" className="w-2 px-2 py-2">
                  <input
                    type="checkbox"
                    checked={selectedProducts.length === products.length}
                    onClick={() => {
                      if (selectedProducts.length === products.length) {
                        setSelectedProducts([]);
                      } else {
                        setSelectedProducts(products);
                      }
                    }}
                  />
                </th>
                <th scope="col" className="whitespace-nowrap px-2 py-2 text-left text-sm font-bold text-gray-900 uppercase">
                  {t("name")}
                </th>
                <th scope="col" className="whitespace-nowrap px-2 py-2 text-left text-sm font-bold text-gray-900 uppercase">
                  {t("description")}
                </th>
                <th scope="col" className="whitespace-nowrap px-2 py-2 text-left text-sm font-bold text-gray-900 uppercase">
                  {t("price")}
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {products.map((product, index) => (
                <tr
                  key={index}
                  className="hover:bg-[#dee2e6]"
                  onClick={() => {
                    if (selectedProducts.find((c) => c.itemID === product.itemID)) {
                      setSelectedProducts(selectedProducts.filter((c) => c.itemID !== product.itemID));
                    } else {
                      setSelectedProducts([...selectedProducts, product]);
                    }
                  }}>
                  <td className="whitew-2 px-2 py-2">
                    <input type="checkbox" checked={selectedProducts.find((c) => c.itemID === product.itemID)} />
                  </td>
                  <td className="whitespace-nowrap px-2  text-sm">{product.name}</td>
                  <td className="whitespace-nowrap px-2  text-sm">{product.description}</td>
                  <td className="whitespace-nowrap px-2  text-sm">{product.salesDetails.unitPrice}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Products;
