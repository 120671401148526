import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import "react-tagsinput/react-tagsinput.css";

import Loader from "@components/utils/Loader";
import LoadingButton from "@components/utils/LoadingButton";
import { Select } from "../../components/utils/Select";

import { setUser } from "@redux/auth/actions";
import api from "@services/api";
import { Mixpanel } from "../../services/mixpanel";
import { MIXPANEL_EVENTS } from "../../utils/mixpanelEvents";

const Settings = () => {
  const { t, i18n } = useTranslation();
  const user = useSelector((state) => state.Auth.user);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    email: user.email,
    firstname: user.firstname,
    lastname: user.lastname,
    language: user.language,
  });

  useEffect(() => {
    Mixpanel.track(MIXPANEL_EVENTS.page_view_profile);
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await api.put(`/user/${user._id}`, values);
      if (!res.ok) {
        toast.error(t("toast.error.updating_profile"));
      } else {
        i18n.changeLanguage(values.language);
        toast.success(t("toast.sucess.updated"));
        dispatch(setUser(res.data));
      }
    } catch (e) {
      console.log(e);
      toast.error(t("toast.error.some_error") + e);
    }
    setLoading(false);
  }

  if (!user) return <Loader />;

  return (
    <div className="">
      <div className="flex flex-col gap-y-4 bg-white rounded-xl p-4">
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3 mt-2">
            <UserInput
              label={t("first_name")}
              value={values.firstname || ""}
              onChange={(e) => setValues({ ...values, firstname: e.target.value })}
              placeholder={t("enter_first_name")}
            />
            <UserInput
              label={t("last_name")}
              value={values.lastname || ""}
              onChange={(e) => setValues({ ...values, lastname: e.target.value })}
              placeholder={t("enter_last_name")}
            />
            <UserInput label={t("email")} value={values.email} disabled />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3 mt-2">
            <UserSelect
              label={t("profile.lang.my_lang")}
              options={["FR", "EN"]}
              value={values.language}
              onChange={(lang) => setValues({ ...values, language: lang })}
              placeholder={t("profile.lang.select_lang")}
              getLabel={(lang) => t(`profile.lang.${lang}`)}
            />
          </div>
          <div className="flex justify-end">
            <LoadingButton
              className="ml-[10px] bg-[#17a2b8] hover:bg-[#138496] text-[1rem] text-[#fff] py-[0.375rem] px-[0.75rem] rounded-[0.25rem]"
              loading={loading}
              onClick={handleSubmit}>
              {t("update")}
            </LoadingButton>
          </div>
        </form>
      </div>
    </div>
  );
};

const UserSelect = ({ label, options, value, onChange, placeholder, getLabel, className }) => (
  <div className={`${className} w-full`}>
    <div className="px-1 text-sm text-gray-600 font-medium capitalize">{label}</div>
    <Select options={options} value={value} onChange={onChange} placeholder={placeholder} getLabel={getLabel} />
  </div>
);

const UserInput = ({ name, value, type = "text", onChange, label, placeholder, className, disabled }) => (
  <div className={`${className} w-full`}>
    <div className="px-1 text-sm text-gray-600 font-medium capitalize">{label}</div>
    <input
      className="w-full rounded-md border-[1px] bg-[#ffffff] disabled:bg-gray-100 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2"
      name={name}
      value={value}
      onChange={onChange}
      type={type}
      disabled={disabled}
      placeholder={placeholder}
    />
  </div>
);

export default Settings;
