import { Field, Formik } from "formik";
import { useState } from "react";
import validator from "validator";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

import LoadingButton from "@components/utils/LoadingButton";
import api from "@services/api";
import { useTranslation } from "react-i18next";

const Forgot = () => {
  const { t } = useTranslation();
  const [done, setDone] = useState(false);

  const validateEmail = (value) => {
    if (!validator.isEmail(value)) {
      return "Invalid email address";
    }
  };

  if (done) {
    return (
      // Auth Wrapper
      <div className="flex items-center justify-center text-center h-full w-full">
        <div className="authWrapper font-myfont">
          <div className="font-[Helvetica] text-center text-[32px] font-semibold	mb-[15px]"> {t("forgot_password.reset")} </div>
          {/* How Reset */}
          <div className="text-[16px] text-center mb-[30px] py-0	px-[30px] text-[#555]">{t("forgot_password.desc_confirm")}</div>
          <div className="text-sm mt-4">
            {t("have_account")}{" "}
            <Link to="/auth" className="font-medium hover:underline">
              {t("signin")}
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    // Auth Wrapper
    <div className="flex items-center justify-center p-4 text-center h-full w-full">
      <div className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-8 text-left align-middle shadow-xl transition-all">
        <div className="font-[Helvetica] text-center text-[32px] font-semibold	mb-[15px]">{t("forgot_password.reset")}</div>
        {/* How Reset */}
        <div className="text-[16px] text-center mb-[30px] py-0	px-[30px] text-[#555]"> {t("forgot_password.desc")} </div>
        <Formik
          initialValues={{ email: "" }}
          onSubmit={async (values, actions) => {
            try {
              await api.post("/user/forgot_password", values);
              toast.success(t("toast.sucess.sent"));
              setDone(true);
            } catch (e) {
              toast.error("Error", e.code);
            }
            actions.setSubmitting(false);
          }}>
          {({ values, errors, isSubmitting, handleChange, handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div className="mb-[25px]">
                  <div className="flex flex-col-reverse">
                    <Field
                      validate={validateEmail}
                      className={`peer border-[1px] border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-3 py-2`}
                      name="email"
                      type="email"
                      id="email"
                      value={values.email}
                      onChange={handleChange}
                    />
                    <label className="peer-focus:text-[#116eee]" htmlFor="email">
                      {t("auth_email")}
                    </label>
                  </div>
                  <p className="text-[12px] text-[#FD3131]">{errors.email ? t("error_email_field") : null}</p>
                </div>
                <LoadingButton
                  disabled={isSubmitting || values.email === ""}
                  className="mt-5 font-[Helvetica] w-[220px] bg-blue hover:bg-blue-300 text-white rounded-[30px] m-auto block text-[16px] p-[8px] min-h-[42px]"
                  type="submit"
                  loading={isSubmitting}>
                  {t("forgot_password.send_link")}
                </LoadingButton>
              </form>
            );
          }}
        </Formik>
        <div className="text-sm mt-4">
          {t("have_account")}{" "}
          <Link to="/auth" className="font-medium hover:underline">
            {t("signin")}
          </Link>
        </div>
      </div>
    </div>
  );
};
export default Forgot;
