export const statuses = ["DRAFT", "SENT", "PAID", "DUE", "ARCHIVED"];

// TODO: get currencies and conversion rates from an API
export const currencies = [
  { id: "EUR", name: "Euro", symbol: "€" },
  { id: "USD", name: "US Dollar", symbol: "$" },
  { id: "GBP", name: "British Pound", symbol: "£" },
  { id: "CHF", name: "Swiss Franc", symbol: "CHF" },
  { id: "CAD", name: "Canadian Dollar", symbol: "CA$" },
  { id: "AUD", name: "Australian Dollar", symbol: "AU$" },
  { id: "YEN", name: "Japanese Yen", symbol: "¥" },
  { id: "JPY", name: "Japanese Yen", symbol: "¥" },
  { id: "NZD", name: "New Zealand Dollar", symbol: "NZ$" },
  { id: "SEK", name: "Swedish Krona", symbol: "kr" },
  { id: "CNH", name: "Chinese Yuan", symbol: "CN¥" },
  { id: "PLN", name: "Polish Zloty", symbol: "zł" },
  { id: "AED", name: "UAE Dirham", symbol: "AED" },
  { id: "SGD", name: "Singapore Dollar", symbol: "SGD" },
  { id: "HUF", name: "Hungarian Forint", symbol: "Ft" },
  { id: "RON", name: "Romanian Leu", symbol: "RON" },
  { id: "KRW", name: "South Korean Won", symbol: "₩" },
  { id: "TRY", name: "Turkish Lira", symbol: "₺" },
  { id: "RSD", name: "Serbian Dinar", symbol: "RSD" },
  { id: "BAM", name: "Bosnia-Herzegovina Convertible Mark", symbol: "BAM" },
  { id: "XOF", name: "West African CFA Franc", symbol: "CFA" },
];
