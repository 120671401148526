import { useTranslation } from "react-i18next";
import { currencyToStr } from "@utils";

const TransactionsStripe = ({ transactions }) => {
  const { t } = useTranslation();

  if (!transactions.length) return null;

  return (
    <>
      <div className="text-xl font-semibold capitalize">{t("related_stripe_transactions")}</div>
      <div className="overflow-x-auto flow-root min-w-full align-middle overflow-hidden rounded-lg border">
        <table className="min-w-full">
          <thead className="bg-gray-300">
            <tr>
              <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 uppercase">
                {t("creation_date")}
              </th>
              <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 uppercase">
                {t("name")}
              </th>
              <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 uppercase">
                {t("client")}
              </th>
              <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 uppercase">
                {t("amount")}
              </th>
              <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 uppercase">
                {t("fees")}
              </th>
              <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 uppercase">
                {t("status")}
              </th>
              <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 uppercase">
                {t("type")}
              </th>
              <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 uppercase">
                {t("account")}
              </th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {transactions.map((transaction) => (
              <tr key={transaction._id}>
                <td className="whitespace-nowrap px-2 py-2 text-sm">
                  <div>{transaction.created.slice(0, 10)}</div>
                </td>
                <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                  <div className="">{transaction.description?.substring(0, 30)}...</div>
                </td>

                <td className="whitespace-nowrap px-2 py-2 text-sm">
                  <div>{transaction.Client ? transaction.Client.companyName : "-"}</div>
                </td>
                <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                  <div className="flex items-center gap-1">
                    <Amount amount={transaction.amount} currency={currencyToStr(transaction.currency)} />
                  </div>
                </td>
                <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">{transaction.fee} </td>
                <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">{transaction.status}</td>
                <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900 uppercase">
                  {transaction.type ? t(`stripe_transaction.status.${transaction.type.toUpperCase()}`) : null}
                </td>
                <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">{transaction.accountName}</td>
              </tr>
            ))}

            <tr>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900 capitalize">{t("sub_total")}</td>
              <td></td>
              <td></td>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                <Amount amount={transactions.reduce((acc, transaction) => acc + parseFloat(transaction.amount), 0).toFixed(2)} />
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                <Amount amount={transactions.reduce((acc, transaction) => acc - parseFloat(transaction.fee), 0).toFixed(2)} />
              </td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900 uppercase"> {t("total")} </td>
              <td></td>
              <td></td>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                <Amount amount={transactions.reduce((acc, transaction) => acc + parseFloat(transaction.amount) - parseFloat(transaction.fee), 0).toFixed(2)} />
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

const Amount = ({ amount, currency }) => {
  let color = amount > 0 ? "bg-green-50 text-green-700 border-[1px] border-green-100" : "bg-red-50 text-red-700 border-[1px] border-red-100";
  return (
    <div className={`px-2 py-1 rounded-sm flex justify-center items-center text-sm font-bold ${color}`}>
      {amount}
      {currency}
    </div>
  );
};

export default TransactionsStripe;
