import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { setUser } from "@redux/auth/actions";
import api from "@services/api";
import { AiOutlineMenu } from "react-icons/ai";
import { HiUserCircle } from "react-icons/hi2";
import Organizations from "./Organizations";
import { truncate } from "@utils";

import { useTranslation } from "react-i18next";

const Header = ({ setSideBarOpen }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  const user = useSelector((state) => state.Auth.user);
  const location = useLocation();

  if (location.pathname.indexOf("/auth") !== -1) return null;

  async function logout() {
    await api.post(`/user/logout`);
    dispatch(setUser(null));
  }

  const menuStyling = {
    menuVisibility: `${open ? "block p-4 sm:p-0" : "hidden"}`,
    menuTransition: `${open ? "translate-x-0" : "translate-x-[105%]"}`,
  };

  return (
    <div className="p-2 bg-white border-b border-[#a0a6b124] flex justify-between items-center w-full right-4 z-50">
      <div className="flex gap-1 items-center">
        <div id="menu-icon" onClick={() => setSideBarOpen((state) => !state)} className="md:hidden cursor-pointer hover:bg-[#f5f5f5] rounded-full p-2">
          <AiOutlineMenu className="text-lg" />
        </div>
      </div>
      <Organizations />
      <div className="flex items-center gap-1">
        <div className="my-auto">
          {user.avatar ? (
            <img className="min-w-[32px] w-9 sm:h-9 bg-[#aaa] rounded-full cursor-pointer object-cover" onClick={() => setOpen(!open)} src={user.avatar} />
          ) : (
            <HiUserCircle className="min-w-[32px] w-9 sm:h-9 cursor-pointer" onClick={() => setOpen(!open)} />
          )}
        </div>
        <div className="relative flex gap-2">
          <div
            className={`w-screen md:w-[250px] h-screen md:h-fit rounded overflow-hidden !z-[100] bg-[#fff]
          ${menuStyling.menuVisibility} ${menuStyling.menuTransition} transition duration-100 ease-in fixed top-0 right-0  md:absolute md:top-[calc(100%_+_20px)] md:right-8 z-50 md:z-20 shadow-menuShadow `}
            open={open}>
            <div className="px-[15px] py-[8px] border-b-2 border-[#e5e7eb]">
              <span className="block text-[15px] font-[Arial] text-[#333]">{truncate(`${user.firstname ?? ""} ${user.lastname ?? ""}`, 25)}</span>
              <span className="block text-[15px] font-[Arial] text-[#888888]">{truncate(user.email, 30)}</span>
            </div>

            <div className="flex flex-col" onClick={() => setOpen(false)}>
              <div className="text-[15px] font-[Arial] text-left text-[#888888] cursor-pointer border-l-4 border-[#ffffff] hover:border-[#4d90fb] hover:bg-[#d3bfc731] hover:text-[#333]">
                <Link className="text-inherit p-[10px] hover:text-[#333] hover:no-underline block" to="/settings/my-profile">
                  {t("header.settings")}
                </Link>
              </div>
              <div
                className="text-[15px] font-[Arial] p-[10px] text-left text-[#888888] cursor-pointer border-l-4 border-[#ffffff] hover:border-red-400 hover:bg-[#d3bfc731] hover:text-[#333]"
                onClick={logout}>
                <Link style={{ textDecoration: "none" }} className="text-inherit hover:text-[#333]" to="#">
                  {t("logout")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
