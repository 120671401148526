import { useState, useEffect } from "react";
import api from "@services/api";
import toast from "react-hot-toast";

import EditClient from "./editClient";
import CreateClient from "@components/modals/createClient";

import ActionsMenu from "@components/utils/ActionsMenu";
import { Paginator } from "@components/utils/Pagination";
import { SearchBar } from "@components/utils/SearchBar";
import TruncatedText from "@components/utils/TruncatedText";

import { capitalizeFirstLetter, currencyToStr } from "@utils";

import { useTranslation } from "react-i18next";
import { Mixpanel } from "../../services/mixpanel";
import { ENVIRONMENT } from "@config";
import { MIXPANEL_EVENTS } from "../../utils/mixpanelEvents";
import { useSelector } from "react-redux";

const List = () => {
  const { t } = useTranslation();
  const [clients, setClients] = useState();
  const [selectedClient, setSelectedClient] = useState();
  const [filter, setFilter] = useState({ search: "", page: 1, per_page: 10 });
  const [total, setTotal] = useState(0);
  const [showCreateClient, setShowCreateClient] = useState(false);
  const { organization } = useSelector((state) => state.Auth);

  useEffect(() => {
    if (!organization) return;
    getAllClients();
  }, [filter, organization]);

  useEffect(() => {
    Mixpanel.track(MIXPANEL_EVENTS.page_view_clients);
  }, []);

  async function getAllClients() {
    const { data } = await api.post("/client/search", {
      per_page: filter.per_page,
      page: filter.page,
      search: filter.search,
      OrganizationId: organization._id,
    });
    setClients(data.clients);
    setTotal(data.total);
  }

  async function handleDelete(id) {
    const confirm = window.confirm("Are you sure ?");
    if (!confirm) return;
    await api.remove(`/client/${id}`);
    toast.success(t("toast.sucess.removed"));
    getAllClients();
  }

  return (
    <div className="mx-auto">
      <div className="py-6 px-6">
        <div className="flex flex-col bg-white rounded-xl p-6">
          <div className="flex justify-between items-start">
            <div>
              <h1 className="text-2xl font-bold">{t("clients")}</h1>
              <p className="mt-2 text-slate-600">{t("clients.intro")}</p>
            </div>
            <div className="flex gap-2">
              <button className="btn-primary hover:bg-gray-700 focus:bg-blue float-right mb-2"> {t("import_from_csv")} </button>
              <button
                className="btn-primary focus:bg-blue float-right mb-2"
                onClick={() => {
                  setShowCreateClient(true);
                }}>
                {t("clients.create_a_client")}
              </button>
            </div>
          </div>
          <EditClient client={selectedClient} setClient={() => setSelectedClient(null)} onSave={getAllClients} />
          <div className="flex flex-col space-y-5 mt-8">
            <SearchBar search={filter.search} setFilter={setFilter} />
            <Table
              clients={clients}
              onEdit={(client) => {
                setSelectedClient(client);
              }}
              onDelete={handleDelete}
            />
            <Paginator page={filter.page} setPage={(p) => setFilter({ ...filter, page: p })} last={Math.ceil(total / filter.per_page)} />
          </div>
        </div>
      </div>
      <CreateClient
        onCreated={(el) => {
          getAllClients();
          setSelectedClient(el);
        }}
        open={showCreateClient}
        onClose={() => setShowCreateClient(false)}
      />
    </div>
  );
};

const Table = ({ clients, onEdit, onDelete }) => {
  const { t } = useTranslation();
  return (
    <div className="overflow-x-auto flow-root min-w-full align-middle overflow-hidden rounded-lg border">
      <table className="min-w-full">
        <thead className="border-b">
          <tr>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-bold border-r uppercase">
              {t("name")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-500 uppercase">
              {t("client_type")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-500 uppercase">
              {t("email")}
            </th>

            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-500">
              {t("clients.edit.landline_number")}
            </th>

            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-500 uppercase">
              {t("phone_number")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-500 uppercase">
              Source
            </th>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-500 uppercase">
              {t("total_spend")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-500 uppercase">
              {t("actions")}
            </th>
          </tr>
        </thead>
        <tbody className="bg-white">
          {clients &&
            clients.map((client) => (
              <tr key={client._id}>
                <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900 border-r">
                  <span
                    className="cursor-pointer font-bold underline capitalize"
                    onClick={() => {
                      onEdit(client);
                      Mixpanel.track(MIXPANEL_EVENTS.page_view_clients_edit);
                    }}>
                    <TruncatedText str={client.entityType === "COMPANY" ? client?.companyName || "" : `${client?.firstname || ""} ${client?.lastname || ""}`} />
                  </span>
                </td>
                <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                  {client.entityType === "COMPANY" ? capitalizeFirstLetter(t("company")) : client.entityType === "INDIVIDUAL" ? capitalizeFirstLetter(t("individual")) : null}
                </td>
                <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                  {client.emails && client.emails.length > 0 ? (
                    <>
                      <a href={`mailto:${client?.emails}`}>
                        <TruncatedText str={client?.emails[0]} n={40} />
                      </a>
                      {client.emails.length > 1 && ` + ${client.emails.length - 1}`}
                    </>
                  ) : (
                    "-"
                  )}
                </td>
                <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                  {client.landlinePhone ? (
                    <a href={`tel:${client?.landlinePhone}`} className="">
                      <TruncatedText str={client?.landlinePhone} />
                    </a>
                  ) : (
                    <TruncatedText str={client?.landlinePhone} />
                  )}
                </td>
                <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                  {client.mobilePhone ? (
                    <a href={`tel:${client?.mobilePhone}`} className="">
                      <TruncatedText str={client?.mobilePhone} />
                    </a>
                  ) : (
                    <TruncatedText str={client?.mobilePhone} />
                  )}
                </td>
                <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">{client.source}</td>
                <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                  {client?.totalSpend}
                  {currencyToStr(client?.preferredCurrency)}
                </td>
                <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                  <ActionsMenu
                    actions={[
                      {
                        label: t("edit"),
                        callback: () => {
                          onEdit(client);
                          Mixpanel.track(MIXPANEL_EVENTS.page_view_clients_edit);
                        },
                      },
                      { label: t("delete"), callback: () => onDelete(client._id) },
                    ]}
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default List;
