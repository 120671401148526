import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import "react-tagsinput/react-tagsinput.css";

import api from "@services/api";
import Loader from "@components/utils/Loader";
import { useTranslation } from "react-i18next";
import FileInput from "../../components/utils/FileInput";
import { useDispatch, useSelector } from "react-redux";
import { setOrganization } from "@redux/auth/actions";
import { ENVIRONMENT } from "../../config";

const MyOrganizations = () => {
  const { t } = useTranslation();
  const organization = useSelector((state) => state.Auth.organization);
  const [values, setValues] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    setValues(organization);
  }, [organization]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = await api.put(`/organization/${values._id}`, { ...values });
    if (!res.ok) return toast.error(t("toast.error.organization_updating"));
    dispatch(setOrganization(values));
    toast.success(t("toast.sucess.organization_updated"));
  };

  const handleDeleteTax = async (id) => {
    try {
      if (!window.confirm(t("confirm_delete_message"))) return;
      const res = await api.remove(`/tax/${id}`);
      if (!res.ok) return toast.error(t("toast.error.deleting_tax"));
      const taxes = values.Taxes.filter((tax) => tax._id !== id);
      dispatch(setOrganization({ ...values, Taxes: taxes }));
    } catch (err) {
      console.log(err);
    }
  };

  const handleAddTax = async () => {
    try {
      const res = await api.post(`/tax`, { name: "tax", value: 0, description: "" });
      if (!res.ok) return toast.error(t("toast.error.adding_tax"));
      const taxes = values.Taxes;
      taxes.push(res.data.tax);
      dispatch(setOrganization({ ...values, Taxes: taxes }));
    } catch (err) {
      console.log(err);
    }
  };

  if (!values) return <Loader />;

  return (
    <div className="flex flex-col w-full">
      <form className="grid grid-cols-2 gap-4 mt-4" onSubmit={handleSubmit}>
        <div className="flex items-center gap-2 col-span-2">
          <FileInput value={values.logo} onChange={(e) => setValues({ ...values, logo: e.target.value })} folder={`/organization`} name={`logo-${values._id}`} />
          <div className="font-semibold text-lg">{values.name}</div>
        </div>
        <div className="flex flex-col">
          <div className="text-gray-800 text-base mb-1"> {t("email")} </div>
          <input
            className="border-[1px] border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-3 py-2"
            value={values?.email ?? ""}
            onChange={(e) => setValues({ ...values, email: e.target.value })}
            placeholder={t("enter_email")}
          />
        </div>
        <div className="flex flex-col">
          <div className="text-gray-800 text-base mb-1"> {t("iban")} </div>
          <input
            className="border-[1px] border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-3 py-2"
            value={values?.iban ?? ""}
            onChange={(e) => setValues({ ...values, iban: e.target.value })}
            placeholder="What is the organization's IBAN?"
          />
        </div>
        <div className="flex flex-col">
          <div className="text-gray-800 text-base mb-1"> {t("business_registration_number")} </div>
          <input
            className="border-[1px] border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-3 py-2"
            value={values?.registrationNumber ?? ""}
            onChange={(e) => setValues({ ...values, registrationNumber: e.target.value })}
            placeholder="What is the organization's registration number? (e.g. SIREN)"
          />
        </div>

        <div className="flex flex-col">
          <div className="text-gray-800 text-base mb-1"> {t("vat_number")} </div>
          <input
            className="border-[1px] border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-3 py-2"
            value={values?.vat ?? ""}
            onChange={(e) => setValues({ ...values, vat: e.target.value })}
            placeholder={t("organization.vat_placeholder")}
          />
        </div>

        <separator className="w-full h-6" />

        <div className="w-full col-span-2">
          <h2 className="text-primary text-base mb-1 font-semibold underline"> {t("vat")} </h2>
          <div className="flex flex-col gap-4">
            {values.Taxes?.map((tax, index) => (
              <div key={index} className="flex items-end w-full gap-4">
                <div className="w-54 flex flex-col">
                  <div className="text-gray-800 text-base mb-1"> {t("name")} </div>
                  <input
                    type="text"
                    value={tax.name ?? ""}
                    onChange={(e) => {
                      const taxes = values.Taxes;
                      taxes[index].name = e.target.value;
                      setValues({ ...values, Taxes: taxes });
                    }}
                    className="border-[1px] border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-3 py-2"
                    placeholder={t("name")}
                  />
                </div>
                <div className="w-54 flex flex-col">
                  <div className="text-gray-800 text-base mb-1"> {t("VAT_rate")} </div>
                  <input
                    type="text"
                    value={tax.value ?? ""}
                    onChange={(e) => {
                      const taxes = values.Taxes;
                      taxes[index].value = e.target.value;
                      setValues({ ...values, Taxes: taxes });
                    }}
                    className="border-[1px] border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-3 py-2"
                    placeholder={t("VAT_rate")}
                  />
                </div>
                <div className="w-full flex flex-col">
                  <div className="text-gray-800 text-base mb-1"> {t("description")} </div>
                  <input
                    type="text"
                    value={tax.description ?? ""}
                    onChange={(e) => {
                      const taxes = values.Taxes;
                      taxes[index].description = e.target.value;
                      setValues({ ...values, Taxes: taxes });
                    }}
                    className="border-[1px] border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-3 py-2"
                    placeholder={t("description")}
                  />
                </div>
                <button type="button" onClick={() => handleDeleteTax(tax._id)} className="text-white bg-red-500 hover:bg-red-600 rounded-lg p-2 text-center">
                  {t("delete")}
                </button>
              </div>
            ))}
            <button type="button" onClick={handleAddTax} className="w-fit px-1 text-primary text-base mb-1 font-semibold border-b border-black">
              + {t("add_vat")}
            </button>
          </div>
        </div>

        <separator className="w-full h-6" />

        <div className="col-span-2">
          <h2 className="text-primary text-base mb-1 font-semibold underline"> {t("billing_address")} </h2>
          <div className="grid grid-cols-2 grid-rows-2 gap-4">
            <div className="flex flex-col">
              <div className="text-gray-800 text-base mb-1">{t("street")}</div>
              <input
                type="text"
                value={values.billingAddressStreet ?? ""}
                onChange={(e) => setValues({ ...values, billingAddressStreet: e.target.value })}
                className="border-[1px] border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-3 py-2"
                placeholder={t("organization.street_placeholder")}
              />
            </div>
            <div className="flex flex-col">
              <div className="text-gray-800 text-base mb-1">{t("city")}</div>
              <input
                type="text"
                value={values.billingAddressCity ?? ""}
                onChange={(e) => setValues({ ...values, billingAddressCity: e.target.value })}
                className="border-[1px] border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-3 py-2"
                placeholder={t("organization.city_placeholder")}
              />
            </div>
            <div className="flex flex-col row-start-2">
              <div className="text-gray-800 text-base mb-1"> {t("zip_code")} </div>
              <input
                type="text"
                value={values.billingAddressZipCode ?? ""}
                onChange={(e) =>
                  setValues({
                    ...values,
                    billingAddressZipCode: e.target.value,
                  })
                }
                className="border-[1px] border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-3 py-2"
                placeholder={t("organization.zip_placeholder")}
              />
            </div>
            <div className="flex flex-col row-start-2">
              <div className="text-gray-800 text-base mb-1"> {t("country")} </div>
              <input
                type="text"
                value={values.billingAddressCountry ?? ""}
                onChange={(e) =>
                  setValues({
                    ...values,
                    billingAddressCountry: e.target.value,
                  })
                }
                className="border-[1px] border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-3 py-2"
                placeholder={t("organization.country_placeholder")}
              />
            </div>
          </div>
        </div>

        <div className="flex mt-4 w-full">
          <button type="submit" className="btn-primary">
            {t("save")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default MyOrganizations;
