import { useTranslation } from "react-i18next";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { IoIosCheckmark } from "react-icons/io";
import { useNavigate } from "react-router-dom";

const Review = ({ newDataCount }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="min-w-[400px] mx-auto border border-gray-200 rounded-lg p-3 mt-5">
      <div className="flex flex-col items-center text-xl font-bold text-center mb-4">
        <IoIosCheckmarkCircleOutline className="text-green-600 text-5xl" />
        {t("import_xero.completed")}
      </div>
      <div className="flex items-center gap-2">
        <IoIosCheckmark />
        {newDataCount.newContacts || 0} {t("import_xero.new_contacts")}
      </div>
      <div className="flex items-center gap-2">
        <IoIosCheckmark />
        {newDataCount.newProducts || 0} {t("import_xero.new_products")}
      </div>
      <div className="flex items-center gap-2">
        <IoIosCheckmark />
        {newDataCount.newInvoices || 0} {t("import_xero.new_invoices")}
      </div>
      <div className="flex items-center gap-2">
        <IoIosCheckmark />
        {newDataCount.newInvoicesPurchase || 0} {t("import_xero.new_invoices-purchase")}
      </div>
      <div className="flex justify-center mt-5">
        <button className={`flex-1 max-w-[10rem] btn-secondary btn-small`} onClick={() => navigate("/")}>
          Ok
        </button>
      </div>
    </div>
  );
};

export default Review;
