import { Combobox } from "@components/utils/Combobox";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";

const ClientComboBox = ({
  transaction,
  onUpdateTransaction,
  index,
  setShowCreateClient,
  setCreatedClientIndex,
  setCreatedByMultiple,
  clientOptions,
  selectedTransactions,
  width,
}) => {
  const getSelectedClient = () => {
    return selectedTransactions.every((transaction) => transaction.ClientId === selectedTransactions[0].ClientId)
      ? clientOptions.find((client) => client._id === selectedTransactions[0].ClientId)?._id
      : null;
  };

  const { t } = useTranslation();
  const [selectedClientId, setSelectedClientId] = useState(selectedTransactions ? getSelectedClient() : transaction?.ClientId || transaction?.ClientIdSuggestion || null);

  useEffect(() => {
    setSelectedClientId(transaction?.ClientId || transaction?.ClientIdSuggestion || null);
  }, [transaction]);

  useEffect(() => {
    if (selectedTransactions) setSelectedClientId(getSelectedClient());
  }, [selectedTransactions]);

  const handleOnChange = (e) => {
    if (selectedTransactions) {
      onUpdateTransaction({ params: { ClientId: e?._id || null }, needTransactionsUpdate: true });
    } else {
      onUpdateTransaction({
        params: {
          ...transaction,
          ClientId: e?._id || null,
          category: transaction.category || e?.defaultCategory,
        },
        needTransactionsUpdate: true,
      });
      setSelectedClientId(e?._id || null);
    }
  };

  const clientOption = (item) => {
    if (!item) return null;
    const clientLabel = item.entityType === "INDIVIDUAL" ? item.firstname + " " + item.lastname : item.companyName || "";
    if (!transaction?.ClientId && transaction?.ClientIdSuggestion === item._id) return `${clientLabel} (${t("suggested")})`;
    return clientLabel;
  };

  return (
    <div className="relative" data-tooltip-id={`tooltip-client-${transaction?._id}`}>
      <Combobox
        width={width}
        options={clientOptions}
        value={clientOptions.find((client) => client._id === selectedClientId)}
        onChange={async (e) => handleOnChange(e)}
        placeholder={t("client")}
        getLabel={(client) => clientOption(client)}
        nullable={true}
        listHeader={
          <button
            className="px-3 py-2 w-full flex items-center justify-between font-semibold text-sm"
            onClick={() => {
              selectedTransactions ? setCreatedByMultiple(true) : setCreatedClientIndex(index);
              setShowCreateClient(true);
            }}>
            {t("clients.create_a_client")} <span>+</span>
          </button>
        }
        displayValue={(client) => {
          return clientOption(client);
        }}
      />
      {selectedClientId && !selectedTransactions && (
        <Tooltip id={`tooltip-client-${transaction?._id}`} style={{ zIndex: 100, borderRadius: "6px" }}>
          {clientOption(clientOptions.find((client) => client._id === selectedClientId))}
        </Tooltip>
      )}
    </div>
  );
};

export default ClientComboBox;
