import { useState, Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { MdUpload, MdDeleteOutline } from "react-icons/md";
import Loader from "../../components/utils/Loader";

import { readFileAsync } from "@utils/index";
import api from "@services/api";
import { AiFillFilePdf } from "react-icons/ai";

import { useTranslation } from "react-i18next";

const FilesInput = ({ values, onChange, name, folder }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleDeleteFile = async (index) => {
    const updatedValues = [...values];
    updatedValues.splice(index, 1);

    if (updatedValues.length === 0) {
      updatedValues.splice(0, 1);
    }
    onChange({ target: { values: updatedValues, name } });
  };

  return (
    <div className="relative flex gap-2 flex-wrap  ">
      {values &&
        Array.isArray(values) &&
        values.map((url, index) => {
          return (
            <div className="rounded-xl w-20 h-20 border border-gray-200 relative overflow-visible" key={url || index}>
              <a href={url} target="_blank" rel="noopener noreferrer">
                {url.indexOf(".pdf") !== -1 ? (
                  <div className="absolute top-0 left-0 w-20 h-20 bg-gray-300 opacity-80 hover:opacity-90 flex items-center justify-center cursor-pointer text-[12px] text-black font-normal">
                    <AiFillFilePdf size={48} />
                  </div>
                ) : (
                  <img className="h-full" src={url} alt={url} />
                )}
              </a>

              <div className="absolute top-0 ">
                <Popover className="relative">
                  <Popover.Button className="p-1 group inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
                    <div className="p-1 flex items-center justify-center rounded-full bg-gray-100 opacity-60 group-hover:opacity-100">
                      <BiDotsVerticalRounded className="text-lg text-black" />
                    </div>
                  </Popover.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1">
                    <Popover.Panel className="absolute left-1/2 z-10 flex w-screen max-w-min -translate-x-1/2 px-4">
                      <div className="flex flex-col w-40 shrink rounded-xl overflow-hidden bg-white text-sm leading-6 text-gray-900 shadow-lg ring-1 ring-gray-900/5 divide-y divider-gray-200">
                        <button className="px-3 py-2 w-full hover:bg-gray-50 text-left flex gap-1 items-center" onClick={() => handleDeleteFile(index)}>
                          <MdDeleteOutline />
                          {t("transactions.delete_invoice")}
                        </button>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </Popover>
              </div>
            </div>
          );
        })}
      {loading ? (
        <Loader size="small" />
      ) : (
        <label htmlFor={`openFile-${name}`} className=" top-0 right-0 px-3 py-2 w-20 h-20 hover:backdrop-blur-sm cursor-pointer flex flex-col items-center justify-center">
          <MdUpload />
          <span className="text-sm capitalize"> {t("upload")} </span>
        </label>
      )}

      <input
        id={`openFile-${name}`}
        type="file"
        multiple
        className="hidden"
        disabled={loading}
        onChange={async (e) => {
          const files = Array.from(e.target.files);
          if (files.length == 0) return;

          setLoading(true);
          const fileDataArray = [];
          for (const f of files) {
            const rawBody = await readFileAsync(f);
            fileDataArray.push({ rawBody, name: f.name });
          }

          const postData = {
            files: fileDataArray,
            folder,
          };

          const { data } = await api.post(`/file/multiple`, postData);
          let newData = [data];
          if (values) {
            newData = [...values, ...data];
          }

          onChange({
            target: {
              values: newData,
              name,
            },
          });

          setLoading(false);
        }}
      />
    </div>
  );
};

export default FilesInput;
