import Modal from "@components/utils/Modal";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import onboarding from "../../assets/onboarding.png";
import finotor from "../../assets/logo-full.svg";
import { useNavigate } from "react-router-dom";
import api from "@services/api";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { setUser, setOrganization } from "@redux/auth/actions";
const STEPS = {
  WELCOME: "WELCOME",
  FIRST_STEPS: "FIRST_STEPS",
};
const Onboarding = ({ open, setOpen }) => {
  const { t } = useTranslation();
  const [step, setStep] = useState(STEPS.WELCOME);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, organization } = useSelector((state) => state.Auth);

  const onClose = async () => {
    try {
      const res = await api.put(`/user/${user._id}`, { onboardedAt: new Date() });
      if (!res.ok) {
        toast.error(t("toast.error.updating_profile"));
      } else {
        dispatch(setUser(res.data));
      }
      if (organization.plan === "free" && !organization.freeTrialEndAt) {
        const organizationResponse = await api.put(`/organization/${organization._id}/start-free-trial`);
        if (!organizationResponse.ok) {
          toast.error(t("toast.error"));
        } else {
          dispatch(setOrganization(organizationResponse.data));
        }
      }
    } catch (e) {
      console.log(e);
      toast.error(t("toast.error.some_error") + e);
    }
  };
  const redirect = async () => {
    await onClose();
    navigate(`/bank`);
  };

  return (
    <Modal isOpen={open} onClose={() => onClose()} innerClassName="flex max-w-[50rem] w-[50vw] max-h-[calc(100vh-5rem)]">
      {step === STEPS.WELCOME && <Welcome nextStep={() => setStep(STEPS.FIRST_STEPS)} />}
      {step === STEPS.FIRST_STEPS && <FirstSteps onClose={onClose} redirect={redirect} />}
    </Modal>
  );
};

const Welcome = ({ nextStep }) => {
  const { t } = useTranslation();
  return (
    <div className=" w-full text-center py-12 px-16">
      <img src={finotor} alt="Finotor" />
      <h1 className="my-10 font-bold">{t("onboarding.welcome_finotor")}</h1>
      <p className="mt-6">{t("onboarding.description_1")}</p>
      <p className="mt-6">{t("onboarding.description_2")}</p>
      <button className="btn-primary bg-blue hover:bg-blue-700 border-none mt-6 mx-auto px-16 " onClick={() => nextStep()}>
        {t("lets_go")}
      </button>
    </div>
  );
};
const FirstSteps = ({ onClose, redirect }) => {
  const { t } = useTranslation();
  return (
    <div className=" w-full text-center py-12 px-16">
      <div className="bg-blue-200	text-black-100 rounded-md w-fit mx-auto p-3">
        <p className="text-lg font-bold">{t("onboarding.congrat1")}</p>
        <p>{t("onboarding.congrat2")}</p>
      </div>
      <h1 className="my-10 font-bold">{t("onboarding.first_step.title")}</h1>
      <p className="mt-6">{t("onboarding.first_step.description_1")}</p>
      <img src={onboarding} alt="" className="mt-6" />
      <ul className="list-['-'] mt-6 text-xs">
        <li className="mt-2">{t("onboarding.first_step.list.secure")}</li>
        <li className="mt-2">{t("onboarding.first_step.list.read_info")}</li>
        <li className="mt-2">{t("onboarding.first_step.list.protected_data")}</li>
      </ul>
      <div className="flex justify-between gap-2 mt-6">
        <button className="btn-tertiary underline underline-offset-4" onClick={() => onClose()}>
          {t("later")}
        </button>
        <button
          className="btn-primary bg-blue hover:bg-blue-700 border-none"
          onClick={() => {
            redirect();
          }}>
          {t("connect_pro")}
        </button>
      </div>
    </div>
  );
};

export default Onboarding;
