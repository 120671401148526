import { Combobox } from "@components/utils/Combobox";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";

const EmployeeComboBox = ({
  transaction,
  onUpdateTransaction,
  index,
  setShowCreateEmployee,
  setCreatedEmployeeIndex,
  setCreatedByMultiple,
  employeeOptions,
  selectedTransactions,
  width,
}) => {
  const getSelectedEmployee = () => {
    return selectedTransactions.every((transaction) => transaction.EmployeeId === selectedTransactions[0].EmployeeId)
      ? employeeOptions.find((employee) => employee._id == selectedTransactions[0].EmployeeId)?._id
      : null;
  };

  const { t } = useTranslation();
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(selectedTransactions ? getSelectedEmployee() : transaction?.EmployeeId || transaction?.EmployeeIdSuggestion || null);

  useEffect(() => {
    setSelectedEmployeeId(transaction?.EmployeeId || transaction?.EmployeeIdSuggestion || null);
  }, [transaction]);

  useEffect(() => {
    if (selectedTransactions) setSelectedEmployeeId(getSelectedEmployee());
  }, [selectedTransactions]);

  const handleOnChange = (e) => {
    if (selectedTransactions) {
      onUpdateTransaction({ params: { EmployeeId: e?._id || null }, needTransactionsUpdate: true });
      setSelectedEmployeeId(e?._id || null);
    } else {
      onUpdateTransaction({
        params: {
          ...transaction,
          EmployeeId: e?._id || null,
          category: transaction.category || e?.defaultCategory,
        },
        needTransactionsUpdate: true,
      });
      setSelectedEmployeeId(e?._id || null);
    }
  };

  return (
    <div className="relative" data-tooltip-id={`tooltip-employee-${transaction?._id}`}>
      <Combobox
        width={width}
        options={employeeOptions}
        value={employeeOptions.find((employee) => employee._id === selectedEmployeeId)}
        onChange={async (e) => handleOnChange(e)}
        placeholder={t("employee")}
        getLabel={(employee) =>
          transaction?.EmployeeId && transaction?.EmployeeIdSuggestion === employee._id
            ? `${employee.firstname} ${employee.lastname} (${t("suggested")})`
            : employee.firstname + " " + employee.lastname
        }
        listHeader={
          <button
            className="px-3 py-2 w-full flex items-center justify-between font-semibold text-sm"
            onClick={() => {
              selectedTransactions ? setCreatedByMultiple(true) : setCreatedEmployeeIndex(index);
              setShowCreateEmployee(true);
            }}>
            {t("employees.create")} <span>+</span>
          </button>
        }
        displayValue={(employee) => {
          if (!employee) return null;
          const employeeLabel = employee.firstname + " " + employee.lastname;
          if (!transaction?.EmployeeId && transaction?.EmployeeIdSuggestion === employee._id) return `${employeeLabel} (${t("suggested")})`;
          return employeeLabel;
        }}
      />

      {selectedEmployeeId && !selectedTransactions && (
        <Tooltip id={`tooltip-employee-${transaction?._id}`} style={{ zIndex: 100, borderRadius: "6px" }}>
          {employeeOptions.find((employee) => employee._id === selectedEmployeeId)?.firstname} {employeeOptions.find((employee) => employee._id === selectedEmployeeId)?.lastname}{" "}
          {!transaction?.EmployeeId && transaction?.EmployeeIdSuggestion === selectedEmployeeId ? `(${t("suggested")})` : ""}
        </Tooltip>
      )}
    </div>
  );
};

export default EmployeeComboBox;
