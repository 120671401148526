import "isomorphic-fetch";

import { apiURL } from "../config.js";

class Api {
  constructor() {
    this.token = "";
  }

  getToken() {
    return this.token;
  }

  setToken(token) {
    this.token = token;
  }

  get(path) {
    return new Promise((resolve, reject) => {
      fetch(`${apiURL}${path}`, {
        mode: "cors",
        method: "GET",
        credentials: "include",
        headers: { "Content-Type": "application/json", Authorization: `JWT ${this.token}` },
      })
        .then((response) => response.json())
        .then((res) => resolve(res))
        .catch((error) => reject(error));
    });
  }

  put(path, body) {
    return new Promise((resolve, reject) => {
      try {
        fetch(`${apiURL}${path}`, {
          mode: "cors",
          method: "PUT",
          credentials: "include",
          headers: { "Content-Type": "application/json", Authorization: `JWT ${this.token}` },
          body: typeof body === "string" ? body : JSON.stringify(body),
        })
          .then((response) => response.json())
          .then((res) => resolve(res))
          .catch((error) => reject(error));
      } catch (e) {
        reject(e);
      }
    });
  }

  remove(path) {
    return new Promise((resolve, reject) => {
      try {
        fetch(`${apiURL}${path}`, {
          mode: "cors",
          credentials: "include",
          method: "DELETE",
          headers: { "Content-Type": "application/json", Authorization: `JWT ${this.token}` },
        })
          .then((response) => response.json())
          .then((res) => resolve(res))
          .catch((error) => reject(error));
      } catch (e) {
        reject(e);
      }
    });
  }

  post(path, body) {
    return new Promise((resolve, reject) => {
      try {
        fetch(`${apiURL}${path}`, {
          mode: "cors",
          method: "POST",
          credentials: "include",
          headers: { "Content-Type": "application/json", Authorization: `JWT ${this.token}` },
          body: typeof body === "string" ? body : JSON.stringify(body),
        })
          .then(async (response) => {
            const res = await response.json();
            if (response.status !== 200) {
              console.log("ERROR STATUS CODE != 200", res);
              return reject(res);
            }
            resolve(res);
          })
          .catch((error) => {
            console.log("error2", error);
            reject(error);
          });
      } catch (e) {
        console.log("error3", e);
        reject(e);
      }
    });
  }

  download(path, body) {
    return new Promise((resolve, reject) => {
      try {
        fetch(`${apiURL}${path}`, {
          mode: "cors",
          method: "POST",
          credentials: "include",
          headers: { "Content-Type": "application/json", Authorization: `JWT ${this.token}` },
          body: typeof body === "string" ? body : JSON.stringify(body),
        })
          .then((response) => {
            if (response.status !== 200) {
              return reject(response);
            }
            resolve(response);
          })
          .catch((error) => reject(error));
      } catch (e) {
        reject(e);
      }
    });
  }
}

const api = new Api();
export default api;
