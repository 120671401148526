import React from "react";

export default function useDevice(breakpoint = 768) {
  const [innerWidth, setInnerWidth] = React.useState(window.innerWidth);

  React.useLayoutEffect(() => {
    function onResize() {
      setInnerWidth(window.innerWidth);
    }
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, []);

  if (innerWidth <= breakpoint) return "mobile";
  else return "desktop";
}
