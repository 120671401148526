import { Formik, Field } from "formik";
import queryString from "query-string";
import toast from "react-hot-toast";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useState } from "react";

import api from "@services/api";
import LoadingButton from "@components/utils/LoadingButton";

import { useTranslation } from "react-i18next";

const Reset = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const search = useLocation().search;
  const { token } = queryString.parse(search);
  const [isTokenValid, setIsTokenValid] = useState(true);

  return (
    <div className="flex items-center justify-center p-4 text-center h-full w-full">
      <div className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-8 text-left align-middle shadow-xl transition-all">
        <div className="font-[Helvetica] text-center text-[32px] font-semibold	mb-[15px]"> {t("forgot_password.create_new_password")} </div>
        {token && isTokenValid ? (
          <Formik
            initialValues={{ password: "", password1: "" }}
            onSubmit={async (values, actions) => {
              try {
                const res = await api.post("/user/forgot_password_reset", { ...values, token });
                if (!res.ok) throw res;
                toast.success(t("toast.sucess"));
                navigate("/");
              } catch (e) {
                switch (e?.code) {
                  case "PASSWORD_TOKEN_EXPIRED_OR_INVALID":
                    setIsTokenValid(false);
                    return toast.error(t("toast.error.token"));
                  case "PASSWORD_NOT_VALIDATED":
                    return toast.error(t("toast.error.password_not_validated"));
                  default:
                    break;
                }
                toast.error(`Error\n${e?.code}`);
              }
              actions.setSubmitting(false);
            }}>
            {({ values, isSubmitting, handleChange, handleSubmit }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <div className="border-[1px] border-gray-200 bg-gray-50 text-gray-500 p-2 rounded-md"> {t("forgot_password.format")} </div>
                  <div className="mb-[25px] mt-4">
                    <div className="flex flex-col-reverse">
                      <Field
                        className={`peer border-[1px] border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-3 py-2`}
                        name="password"
                        type="password"
                        id="password"
                        value={values.password}
                        onChange={handleChange}
                      />
                      <label className="peer-focus:text-[#116eee]" htmlFor="password">
                        {t("forgot_password.new_password")}
                      </label>
                    </div>
                  </div>
                  <div className="mb-[25px]">
                    <div className="flex flex-col-reverse">
                      <Field
                        className={`peer border-[1px] border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-3 py-2`}
                        name="password1"
                        type="password"
                        id="password1"
                        value={values.password1}
                        onChange={handleChange}
                      />
                      <label className="peer-focus:text-[#116eee]" htmlFor="password1">
                        {t("forgot_password.retype_password")}
                      </label>
                    </div>
                  </div>
                  <LoadingButton
                    className="mt-5 font-[Helvetica] w-[220px] bg-blue hover:bg-blue-300 text-[#fff] rounded-[30px] m-auto block text-[16px] p-[8px] min-h-[42px] "
                    loading={isSubmitting}
                    type="submit"
                    disabled={isSubmitting}>
                    {t("create")}
                  </LoadingButton>
                </form>
              );
            }}
          </Formik>
        ) : (
          <div className="text-[16px] text-center mb-[30px] py-0	px-[30px] text-[#555]">
            {t("toast.error.token")}
            <br />
            <Link to="/auth/forgot" className="text-primary font-bold">
              {t("forgot_password.reset")}
            </Link>
            .
          </div>
        )}
      </div>
    </div>
  );
};
export default Reset;
