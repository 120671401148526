import categories from "@data/categories.js";
import api from "@services/api";
import { Mixpanel } from "@services/mixpanel";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { classNames, currencyToStr, getAccountName } from "@utils";
import CreateClient from "@components/modals/createClient";
import CreateEmployee from "@components/modals/createEmployee";
import CreateSupplier from "@components/modals/createSupplier";
import CreateTransaction from "@components/modals/createTransaction";
import { Combobox } from "@components/utils/Combobox";
import Loader from "@components/utils/Loader";
import Modal from "@components/utils/Modal";
import { SearchBar } from "@components/utils/SearchBar";
import { Select } from "@components/utils/Select";

import TransactionModal from "./editTransaction";
import { Pagination } from "./pagination";

import AllTransactions from "./allTransactions";
import TransactionsToReconcile from "./transactionsToReconcile";
import { MIXPANEL_EVENTS } from "../../utils/mixpanelEvents";

const ALL = "ALL";
const TO_RECONCILE = "TO_RECONCILE";

const List = () => {
  const { t } = useTranslation();
  const query = new URLSearchParams(useLocation().search);
  const [transactions, setTransaction] = useState([]);
  const [transactionsToReconcile, setTransactionsToReconcile] = useState([]);
  const [total, setTotal] = useState(0);
  const [totalToReconcile, setTotalToReconcile] = useState(0);
  const [filter, setFilter] = useState({ per_page: 20, page: 1, search: "", account: null, category: null, type: "" });
  const [accountOptions, setAccountOptions] = useState([]);
  const [pettyCash, setPettyCash] = useState(null);
  const [clientOptions, setClientsOptions] = useState([]);
  const [supplierOptions, setSuppliersOptions] = useState([]);
  const [open, setOpen] = useState(query.get("id"));
  const [openBankAccountToRenew, setOpenBankAccountToRenew] = useState(false);
  const navigate = useNavigate();
  const [tab, setTab] = useState(TO_RECONCILE);
  const [showCreateClient, setShowCreateClient] = useState(false);
  const [createdClient, setCreatedClient] = useState(null);
  const [showCreateSupplier, setShowCreateSupplier] = useState(false);
  const [createdSupplier, setCreatedSupplier] = useState(null);
  const [showCreateEmployee, setShowCreateEmployee] = useState(false);
  const [createdEmployee, setCreatedEmployee] = useState(null);
  const [showCreateTransaction, setShowCreateTransaction] = useState(false);
  const [selectedTransactions, setSelectedTransactions] = useState([]);
  const { organization } = useSelector((state) => state.Auth);

  const getTransactions = async () => {
    const { data } = await api.post("/transaction/search", {
      per_page: filter.per_page,
      page: filter.page,
      search: filter.search,
      type: filter.type,
      accountId: filter.account?._id,
      category: filter.category?.id,
      ClientId: filter.ClientId,
      SupplierId: filter.SupplierId,
      OrganizationId: organization?._id,
    });

    setTransaction(data.transactions);
    setTotal(data.total);
  };

  const getTransactionsToReconciled = async () => {
    const { data } = await api.post("/transaction/search", {
      per_page: filter.per_page,
      page: filter.page,
      search: filter.search,
      status: TO_RECONCILE,
      type: filter.type,
      accountId: filter.account?._id,
      category: filter.category?.id,
      ClientId: filter.ClientId,
      SupplierId: filter.SupplierId,
      OrganizationId: organization?._id,
      orderBy: "date",
      order: "DESC",
    });

    setTransactionsToReconcile(data.transactions);
    setTotalToReconcile(data.total);
  };

  useEffect(() => {
    if (!organization) return;
    const promises = [getTransactions(), getTransactionsToReconciled()];
    Promise.all(promises);
  }, [filter, organization]);

  useEffect(() => {
    if (!organization) return;
    const getAccounts = async () => {
      const responseAccount = await api.post("/account/search", { OrganizationId: organization?._id });
      if (responseAccount?.data.find((account) => ["EXPIRED", "ERROR", "SUSPENDED"].includes(account.status))) {
        setOpenBankAccountToRenew(true);
      }
      setAccountOptions(responseAccount?.data);
    };
    const getPettyCash = async () => {
      const res = await api.post("/account/search-pettycash", { OrganizationId: organization?._id });
      setPettyCash(res.data);
    };
    getAccounts();
    getPettyCash();
    Mixpanel.track(MIXPANEL_EVENTS.page_view_transactions);
  }, [organization]);

  useEffect(() => {
    if (!organization) return;
    const getClients = async () => {
      const { data } = await api.post("/client/search", { per_page: 1000, OrganizationId: organization?._id });
      setClientsOptions(data.clients);
    };
    const getSuppliers = async () => {
      const { data } = await api.post("/supplier/search", { per_page: 1000, OrganizationId: organization?._id });
      setSuppliersOptions(data.suppliers);
    };

    const promises = [getClients(), getSuppliers()];
    Promise.all(promises);
  }, [organization]);

  useEffect(() => {
    if (query.get("id")) setOpen(true);
  }, [query]);

  if (!transactions) return <Loader />;

  return (
    <div className="mx-auto">
      <TransactionModal
        isOpen={open}
        onClose={() => {
          navigate("/transactions");
          setOpen(false);
        }}
        onSave={() => {
          getTransactionsToReconciled();
          getTransactions();
        }}
        setShowCreateClientModal={() => {
          setShowCreateClient(true);
        }}
        setShowCreateEmployeeModal={() => {
          setShowCreateEmployee(true);
        }}
        createdEmployee={createdEmployee}
        createdClient={createdClient}
        showCreateSupplierModal={() => {
          setShowCreateSupplier(true);
        }}
        createdSupplier={createdSupplier}
      />
      <CreateTransaction
        isOpen={showCreateTransaction}
        onClose={() => setShowCreateTransaction(false)}
        onCreated={() => {
          getTransactions();
          getTransactionsToReconciled();
        }}
        onSave={() => {
          getTransactionsToReconciled();
          getTransactions();
        }}
        setShowCreateClientModal={() => {
          setShowCreateClient(true);
        }}
        createdClient={createdClient}
        showCreateSupplierModal={() => {
          setShowCreateSupplier(true);
        }}
        createdSupplier={createdSupplier}
        pettyCashAccount={pettyCash}
        accountOptions={accountOptions}
      />
      <RenewAccountModal
        isOpen={openBankAccountToRenew}
        onClose={() => {
          setOpen(false);
          setOpenBankAccountToRenew(false);
        }}
      />
      <CreateClient
        open={showCreateClient}
        onClose={() => {
          setShowCreateClient(false);
        }}
        onCreated={(data) => {
          setCreatedClient({ ...data });
        }}
      />
      <CreateSupplier
        open={showCreateSupplier}
        onClose={() => {
          setShowCreateSupplier(false);
        }}
        onCreated={(data) => {
          setCreatedSupplier({ ...data });
        }}
      />
      <CreateEmployee
        open={showCreateEmployee}
        onClose={() => {
          setShowCreateEmployee(false);
        }}
        onCreated={(data) => {
          setCreatedEmployee({ ...data });
        }}
      />
      <div className="py-6 px-6">
        <div className="flex flex-col bg-white rounded-xl rounded-br-none rounded-bl-none py-6">
          <div className="flex gap-3 items-center justify-between px-6">
            <div className="mb-5">
              <h1 className="text-2xl font-bold ">{t("transactions.title_page")}</h1>
              <p className="mt-2 text-slate-600">{t("transactions_intro")}</p>
            </div>
            <div>
              <button className="btn-secondary" onClick={() => setShowCreateTransaction(true)}>
                {t("transactions.add_transaction")}
              </button>
            </div>
          </div>
          <div className="w-full grid mt-2 px-6 grid-cols-1 md:grid-cols-4 items-end gap-3">
            <SearchBar search={filter.search} setFilter={setFilter} />
            <div className="w-full">
              <label className="text-xs text-gray-500 capitalize">{t("account")}</label>
              <Select
                options={pettyCash ? [...accountOptions, pettyCash] : accountOptions}
                value={filter.account}
                onChange={(e) => setFilter((f) => ({ ...f, account: e }))}
                placeholder={t("account")}
                getLabel={(account) => <AccountOption account={account} />}
                width="w-full"
                nullable={true}
              />
            </div>

            <div className="w-full">
              <label className="text-xs text-gray-500 capitalize"> {t("clients")} </label>
              <Combobox
                options={clientOptions}
                value={clientOptions.find((client) => client._id === filter?.ClientId)}
                onChange={async (e) => {
                  setFilter((f) => ({ ...f, ClientId: e._id }));
                }}
                placeholder={t("client")}
                getLabel={(client) => {
                  if (client.entityType === "COMPANY") return client?.companyName || "";
                  return `${client?.firstname || ""} ${client?.lastname || ""}`;
                }}
                displayValue={(client) => {
                  if (!client) return null;
                  if (client.entityType === "COMPANY") return client?.companyName;
                  return `${client?.firstname || ""} ${client?.lastname || ""}`;
                }}
              />
            </div>
            <div className="w-full">
              <label className="text-xs text-gray-500 capitalize"> {t("supplier")} </label>
              <Combobox
                options={supplierOptions}
                value={supplierOptions.find((supplier) => supplier._id === filter?.SupplierId)}
                onChange={async (e) => {
                  setFilter((f) => ({ ...f, SupplierId: e._id }));
                }}
                placeholder={t("supplier")}
                getLabel={(supplier) => {
                  if (supplier.entityType === "COMPANY") return supplier?.companyName || "";
                  return `${supplier?.firstname || ""} ${supplier?.lastname || ""}`;
                }}
                displayValue={(supplier) => {
                  if (!supplier) return null;
                  if (supplier.entityType === "COMPANY") return supplier?.companyName;
                  return `${supplier?.firstname || ""} ${supplier?.lastname || ""}`;
                }}
              />
            </div>
          </div>
          <div className="w-full grid mt-2 px-6 grid-cols-1 md:grid-cols-4 items-center gap-3">
            <div className="w-full">
              <label className="text-xs text-gray-500 capitalize"> {t("amount")} </label>
              <Select
                options={["INCOME", "EXPENSE"]}
                value={filter.type}
                onChange={(e) => setFilter((f) => ({ ...f, type: e }))}
                placeholder={t("type")}
                getLabel={(type) => <span>{t(`transactions.${type}`)} </span>}
                width="w-full"
                nullable={true}
              />
            </div>
            <div className="w-full">
              <label className="text-xs text-gray-500 capitalize"> {t("categories")} </label>
              <Select
                options={categories}
                value={filter.category}
                onChange={(e) => setFilter((f) => ({ ...f, category: e }))}
                placeholder={t("category")}
                getLabel={(category) => (category ? category.id + " - " + t(`transactions.${category.id}`) : null)}
                width="w-full"
                nullable={true}
              />
            </div>
          </div>
          <div className="mt-4 sticky top-0 w-full bg-white pb-3 z-10">
            <nav className="mt-2 flex flex-row w-full justify-start items-center border-b border-gray-100">
              <TabItem
                active={tab === TO_RECONCILE}
                title={t("to_reconcile")}
                setTab={setTab}
                tab={TO_RECONCILE}
                RightComponent={<span className="text-sm bg-orange-200 px-2 rounded-full flex justify-center items-center text-orange-700">{totalToReconcile}</span>}
              />
              <TabItem
                active={tab === ALL}
                title={t("all")}
                setTab={setTab}
                tab={ALL}
                RightComponent={<span className="text-sm bg-blue-200 px-2 rounded-full flex justify-center items-center text-blue-700">{total}</span>}
              />
            </nav>
          </div>

          {tab === ALL && (
            <div className="w-full px-1">
              <AllTransactions transactions={transactions} />
              <Pagination total={total} filter={filter} setFilter={setFilter} />
            </div>
          )}
          {tab === TO_RECONCILE && (
            <div className="w-full px-1">
              <TransactionsToReconcile
                selectedTransactions={selectedTransactions}
                setSelectedTransactions={setSelectedTransactions}
                transactions={transactionsToReconcile}
                onSave={() => {
                  getTransactionsToReconciled();
                  getTransactions();
                }}
              />
              <Pagination total={totalToReconcile} filter={filter} setFilter={setFilter} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const TabItem = ({ tab, title, setTab, active, RightComponent }) => {
  return (
    <button
      onClick={() => setTab(tab)}
      className={classNames(
        "group inline-flex items-center px-4 py-2 gap-2 cursor-pointer text-sm  hover:border-gray-300 text-gray-500 hover:text-gray-700  border-b-2 border-transparent  ",
        active ? " !border-black" : "",
      )}>
      <div className={classNames("flex items-center gap-2", active ? "text-black-100" : "")}>{title}</div>
      {RightComponent ?? null}
    </button>
  );
};

const AccountOption = ({ account }) => {
  const logo = account?.Requisition?.NordigenInstitution?.logo;
  if (!account) return;
  return (
    <span className="flex">
      {logo && <img src={logo} height={20} width={20} />}
      <span className="ml-2">{getAccountName(account)}</span>
      <span className="ml-1">
        {account.amount} {currencyToStr(account.currency)}
      </span>
    </span>
  );
};

const RenewAccountModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Modal isOpen={isOpen} onClose={onClose} innerClassName="flex max-w-[40rem] w-[40vw] max-h-[calc(100vh-5rem)]">
      <div className="flex flex-col items-center justify-center w-full p-5">
        <div className="mb-5">{t("myintegrations.need_reconnection")}</div>
        <button
          className="btn-primary w-max"
          onClick={(e) => {
            Mixpanel.track(MIXPANEL_EVENTS.btn_renew_account_requisition);
            navigate("/bank");
          }}>
          {t("myintegrations.renew_account")}
        </button>
      </div>
    </Modal>
  );
};

export default List;
