import { Listbox } from "@headlessui/react";
import { FaGlobe, FaChevronDown } from "react-icons/fa";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

export default ({ onChange }) => {
  i18next.addResourceBundle("EN", "translations", EN);
  i18next.addResourceBundle("FR", "translations", FR);
  const { t, i18n } = useTranslation();

  const data = ["EN", "FR"];
  const value = t(i18n.language);

  const handleChange = (v) => {
    i18n.changeLanguage(v);
    if (onChange) onChange(v);
  };

  return (
    <Listbox className="relative" value={value} onChange={handleChange}>
      <div className="relative w-full">
        <Listbox.Button className="flex items-center justify-between w-full px-4 py-3 space-x-2">
          <FaGlobe className="text-base text-gray-300" />
          <div className="flex items-center">
            <p className="text-sm font-medium capitalize">{value}</p>
          </div>
          <FaChevronDown className="text-xs text-gray-600" />
        </Listbox.Button>
        <Listbox.Options className="absolute z-50 w-full overflow-auto bg-white shadow-md max-h-64 list-none">
          {data.map((item, index) => (
            <Listbox.Option className="capitalize cursor-pointer px-4 py-1.5 text-sm text-black-100 font-medium transition-all ursor-pointer hover:bg-gray-100" key={index} value={item}>
              {t(item)}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </div>
    </Listbox>
  );
};

const FR = {
  EN: "Anglais",
  FR: "Français",
};
const EN = {
  EN: "English",
  FR: "French",
};
