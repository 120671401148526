import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import "react-tagsinput/react-tagsinput.css";

import api from "@services/api";
import Loader from "@components/utils/Loader";
import { useTranslation } from "react-i18next";
import InviteUser from "./InviteUser";

const MyUsers = () => {
  const { t } = useTranslation();
  const [organizations, setOrganizations] = useState(null);

  const get = async () => {
    const res = await api.post("/organization/search");
    if (!res.ok) toast.error(t("toast.error.fetching_org"));
    setOrganizations(res.data);
  };

  useEffect(() => {
    get();
  }, []);

  if (!organizations) return <Loader />;

  return (
    <div className="flex container mx-auto">
      <div className="flex flex-col gap-4 bg-white rounded-xl w-full">
        {organizations.map((org) => {
          return <Users key={org._id} organization={org} get={get} />;
        })}
      </div>
    </div>
  );
};

const Users = ({ organization, get }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col">
      <div className="flex justify-between items-center mb-2">
        <h2 className="text-primary text-base mb-1 font-semibold underline">
          {t("users_from")} {organization.name}
        </h2>
        {/* todo : freeze till ready */}
        <InviteUser onCreated={get} />
      </div>

      <div className="border border-black-30 rounded-md divide-y divide-dotted ">
        {organization.Users.map((user) => (
          <div key={user._id} className="h-20 w-full flex flex-col items-start justify-center p-4">
            <div>
              <p className="text-sm font-semibold">
                {user.firstname} {user.lastname}
              </p>
              <p className="text-sm text-gray-500">{user.email}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MyUsers;
