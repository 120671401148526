import { useEffect, useState } from "react";
import api from "@services/api";
import { Mixpanel } from "@services/mixpanel";
import { Combobox } from "@components/utils/Combobox";
import Loader from "@components/utils/Loader";
import { useTranslation } from "react-i18next";
import { toast } from "react-hot-toast";
import { MIXPANEL_EVENTS } from "../../../utils/mixpanelEvents";
import { useSelector } from "react-redux";

const PRODUCTS = "PRODUCTS";

const Contacts = ({ setTab, setNewDataCount }) => {
  const { t } = useTranslation();
  const [contacts, setContacts] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const count = (selectedContacts || []).filter((contact) => !(contact.isSupplier || contact.isCustomer)).length;
  const { organization } = useSelector((state) => state.Auth);

  useEffect(() => {
    try {
      const syncContacts = async () => {
        setLoading(true);
        const res = await api.get(`/xero/contacts/`);
        setLoading(false);
        const defaultContacts = res.data.map((contact) => ({
          ...contact,
          isCustomer: !contact.isSupplier,
        }));
        setContacts(defaultContacts);
      };
      syncContacts();
    } catch (error) {
      setLoading(false);
      toast.error(t("toast.error.contacts_imported"));
    }
  }, []);

  useEffect(() => {
    setSelectedContacts(contacts || []);
  }, [contacts]);

  const importContacts = async () => {
    try {
      setLoading(true);
      const res = await api.post("/xero/contacts/import", { contacts: selectedContacts, OrganizationId: organization?._id });
      setNewDataCount((prevData) => ({
        ...prevData,
        newContacts: res.count,
      }));
      toast.success(t("toast.sucess.contacts_imported"));
      setLoading(false);
      setTab(PRODUCTS);
    } catch (error) {
      toast.error(t("toast.error.contacts_imported"));
      setLoading(false);
    }
  };

  if (loading) return <Loader />;

  return (
    <div className="border  rounded-md mt-4  ">
      <div className="flex flex-col justify-between px-3.5 py-3.5 cursor-pointer">
        <div className="flex justify-between">
          <div className="flex items-center gap-2 w-full">
            <div className="text-dull-black text-xl  font-bold ">
              {t("contacts")} {count ? <span className="text-red-300 text-sm font-normal pl-2">{t("import_xero.tips", { count })}</span> : null}
            </div>
          </div>

          <div className="w-full flex items-center justify-end gap-2">
            <button
              className={`flex-1 max-w-[10rem] btn-secondary btn-small`}
              onClick={() => {
                setTab(PRODUCTS);
                Mixpanel.track(MIXPANEL_EVENTS["btn_skip_xero-contacts"]);
              }}>
              {t("skip")}
            </button>
            <button
              className={`flex-1 max-w-[15rem] btn-primary btn-small`}
              disabled={!selectedContacts || selectedContacts.length === 0 || selectedContacts.some((contact) => !contact.isSupplier && !contact.isCustomer)}
              onClick={async (e) => {
                e.stopPropagation();
                await importContacts();
                Mixpanel.track(MIXPANEL_EVENTS["btn_import_xero-contacts"]);
              }}>
              {t("import_xero.import", { contactsLenght: selectedContacts.length })}
            </button>
          </div>
        </div>
      </div>

      {!contacts || contacts.length === 0 ? (
        <div className="px-3.5 py-3.5"> {t("import_xero.no_contacts")} </div>
      ) : (
        <div className="overflow-x-auto my-4 mx-4 flow-root  align-middle overflow-hidden rounded-lg border">
          <table className="min-w-full">
            <thead className="border-b">
              <tr>
                <th scope="col" className="w-2 px-2 py-2">
                  <input
                    type="checkbox"
                    checked={selectedContacts.length === contacts.length}
                    onClick={() => {
                      if (selectedContacts.length === contacts.length) {
                        setSelectedContacts([]);
                      } else {
                        setSelectedContacts(contacts);
                      }
                    }}
                  />
                </th>
                <th scope="col" className="whitespace-nowrap px-2 py-2 text-left text-sm font-bold text-gray-900 uppercase">
                  {t("name")}
                </th>
                <th scope="col" className="whitespace-nowrap px-2 py-2 text-left text-sm font-bold text-gray-900 uppercase">
                  {t("email")}
                </th>
                <th scope="col" className="whitespace-nowrap px-2 py-2 text-left text-sm font-bold text-gray-900 uppercase">
                  {t("status")}
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {contacts.map((contact, index) => (
                <tr
                  key={index}
                  className="hover:bg-[#dee2e6]"
                  onClick={() => {
                    if (selectedContacts.find((c) => c.contactID === contact.contactID)) {
                      setSelectedContacts(selectedContacts.filter((c) => c.contactID !== contact.contactID));
                    } else {
                      setSelectedContacts([...selectedContacts, contact]);
                    }
                  }}>
                  <td className="whitew-2 px-2 py-2">
                    <input type="checkbox" checked={selectedContacts.find((c) => c.contactID === contact.contactID)} />
                  </td>
                  <td className="whitespace-nowrap px-2 text-sm">{contact.name}</td>
                  <td className="whitespace-nowrap px-2 text-sm">{contact.emailAddress ? contact.emailAddress : "-"}</td>
                  <td
                    className="whitespace-nowrap px-2 py-2  text-sm w-6"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}>
                    <Combobox
                      value={contact.isSupplier ? "Supplier" : contact.isCustomer ? "Client" : ""}
                      options={["Supplier", "Client"]}
                      onChange={(value) => {
                        const updatedContacts = [...contacts];
                        updatedContacts[index].isSupplier = value === "Supplier";
                        updatedContacts[index].isCustomer = value === "Client";
                        setContacts(updatedContacts);
                      }}
                      placeholder={t("select_status")}
                      getLabel={(option) => t(`${option}`.toLowerCase())}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Contacts;
