import React, { useState } from "react";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";

const MultipleSelectInput = ({ value, label = "", placeholder = "", onChange, className = "" }) => {
  const [inputValue, setInputValue] = useState("");

  React.useEffect(() => {
    setInputValue("");
  }, [value]);

  return (
    <div className={className}>
      {label ? <div className="px-1 text-sm text-gray-600 font-medium">{label}</div> : null}
      <div className={`relative ${label ? "mt-2" : ""}`}>
        <TagsInput
          className="rounded-lg bg-white border-[1px] border-gray-200 text-primary text-sm rounded-large block pl-3"
          inputProps={{
            placeholder: placeholder,
            className: "border-none focus:border-none h-10 w-96",
            onBlur: () => {
              if (inputValue) {
                onChange([...value, inputValue]);
                setInputValue("");
              }
            },
          }}
          renderLayout={(tagComponents, inputComponent) => (
            <div className="flex gap-2 flex-wrap">
              <div className="flex gap-2 flex-wrap items-center">{tagComponents}</div>
              <div className="flex items-center">{inputComponent}</div>
            </div>
          )}
          renderTag={({ tag, key }) => (
            <span key={key} className="px-[8px] h-8 leading-4 flex items-center justify-between rounded-full bg-gray-200 text-primary transition duration-200 ease">
              {tag}
              <button
                type="button"
                onClick={() => onChange(value.filter((e) => e !== tag))}
                className="rounded-full w-4 h-4 bg-gray-600 hover focus:outline-none ml-2 font-normal text-gray-200">
                &times;
              </button>
            </span>
          )}
          value={value}
          onChange={onChange}
          inputValue={inputValue}
          onChangeInput={setInputValue}
        />
      </div>
    </div>
  );
};

export default MultipleSelectInput;
