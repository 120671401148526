import { useEffect, useState } from "react";
import api from "@services/api";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Mixpanel } from "../../services/mixpanel";
import { MIXPANEL_EVENTS } from "../../utils/mixpanelEvents";

const CreateInvoice = ({ onCreated }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const organization = useSelector((state) => state.Auth.organization);
  const { search } = useLocation();

  useEffect(() => {
    if (new URLSearchParams(search).get("modal") !== "open") return;
    onSave();
  }, [search]);

  async function onSave(event) {
    try {
      event?.preventDefault();
      setLoading(true);
      const createReq = await api.post("/invoice", { OrganizationId: organization._id });
      if (!createReq.ok) throw createReq.code;

      // update invoice name
      const invoice = createReq.data;
      const updateReq = await api.put(`/invoice/${invoice._id}`, {
        name: t("invoices.editor.subject_default", { organization: organization.name, reference: invoice.reference }),
      });
      if (!updateReq.ok) throw updateReq.code;

      toast.success(t("toast.sucess.created"));
      onCreated(invoice);
    } catch (e) {
      console.error(t("toast.error"), e);
      toast.error(e.code);
    }
    setLoading(false);
  }

  return (
    <form onSubmit={onSave}>
      <button className="btn-primary focus:bg-blue float-right mb-2" type="submit" disabled={loading} onClick={() => Mixpanel.track(MIXPANEL_EVENTS.btn_create_invoice)}>
        {t("invoices.create")}
      </button>
    </form>
  );
};

export default CreateInvoice;
