import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "@services/api";
import { Mixpanel } from "@services/mixpanel";
import Loader from "@components/utils/Loader";
import { useTranslation } from "react-i18next";

import Contacts from "./Contacts";
import Products from "./Products";
import Invoices from "./Invoices";
import PurchaseInvoices from "./PurchaseInvoices";
import Review from "./Review";
import { MIXPANEL_EVENTS } from "../../../utils/mixpanelEvents";

const CONTACTS = "CONTACTS";
const PRODUCTS = "PRODUCTS";
const INVOICES = "INVOICES";
const PURCHASE_INVOICES = "PURCHASE_INVOICES";
const REVIEW = "REVIEW";

function MigrationXero() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [tab, setTab] = useState(CONTACTS);
  const [newDataCount, setNewDataCount] = useState({
    newContacts: 0,
    newProducts: 0,
    newInvoices: 0,
    newInvoicesPurchase: 0,
  });
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    Mixpanel.track(MIXPANEL_EVENTS.page_view_migration_xero);
    (async () => {
      try {
        const url = location.pathname + location.search;
        await api.post(`/xero/callback`, { url: url });
        setLoading(false);
      } catch (error) {
        navigate("/");
      }
    })();
  }, []);

  useEffect(() => {
    const tabProgress = {
      CONTACTS: 5,
      PRODUCTS: 25,
      INVOICES: 50,
      PURCHASE_INVOICES: 75,
      REVIEW: 100,
    };

    setProgress(tabProgress[tab] || 0);
  }, [tab]);

  if (loading) return <Loader />;

  return (
    <div className="py-6 px-6">
      <div className="flex flex-col bg-white rounded-xl p-6">
        <div className="flex flex-col">
          <h1 className="text-2xl font-bold"> {t("import_xero.title")} </h1>
          <p className="text-dull-black text-sm mt-2"> {t("import_xero.subtitle")} </p>

          <ProgressBar progress={progress} />
          {tab === CONTACTS && <Contacts setTab={setTab} setNewDataCount={setNewDataCount} />}
          {tab === PRODUCTS && <Products setTab={setTab} setNewDataCount={setNewDataCount} />}
          {tab === INVOICES && <Invoices setTab={setTab} setNewDataCount={setNewDataCount} />}
          {tab === PURCHASE_INVOICES && <PurchaseInvoices setTab={setTab} setNewDataCount={setNewDataCount} />}
          {tab === REVIEW && <Review newDataCount={newDataCount} />}
        </div>
      </div>
    </div>
  );
}

const ProgressBar = ({ progress }) => {
  return (
    <div className="rounded-3xl w-full bg-[#f3f3f3] my-2">
      <div style={{ width: `${progress}%` }} className="bg-blue-700 h-4 rounded-3xl" />
    </div>
  );
};

export default MigrationXero;
