import { useEffect, useState } from "react";
import api from "@services/api";
import toast from "react-hot-toast";
import Loader from "@components/utils/Loader";
import Modal from "@components/utils/Modal";
import { Combobox } from "@components/utils/Combobox";
import categories from "@data/categories.js";
import MultipleSelectInput from "../../components/utils/MultipleSelectInput";
import { useTranslation } from "react-i18next";
import { Mixpanel } from "../../services/mixpanel";
import { IoAddOutline } from "react-icons/io5";
import { MIXPANEL_EVENTS } from "../../utils/mixpanelEvents";

const Edit = ({ client, setClient, onSave }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState();

  useEffect(() => {
    setValues({ ...client });
  }, [client]);

  if (!client || !values) return <div />;

  async function handleSubmit(e) {
    try {
      e.preventDefault();
      if (!values.entityType) return toast.error(t("clients.edit.entity_type_required"));
      setLoading(true);
      const { ok, code, data } = await api.put(`/client/${client._id}`, { ...values });
      if (!ok) throw { code };
      toast.success(t("toast.sucess.updated_sucessfully"));
      setClient(null);
      onSave(data);
    } catch (e) {
      console.error(t("toast.error"), e);
      toast.error(e.code);
    }
    setLoading(false);
  }

  if (loading) return <Loader />;

  return (
    <Modal isOpen={true} onClose={() => setClient(null)} className="" innerClassName="flex max-w-[50rem] w-[80vw] max-h-[calc(100vh-5rem)]">
      <div className="w-full max-h-full flex flex-col overflow-y-hidden transform rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
        <div className="px-8 py-5 text-left text-lg font-semibold border-b"> {t("clients.edit")} </div>

        <form onSubmit={handleSubmit} className="overflow-auto flex flex-col">
          <div className="flex-1 flex flex-col gap-4 py-4 px-8 overflow-auto">
            {values.entityType === "INDIVIDUAL" ? (
              <Individual client={client} values={values} setValues={setValues} />
            ) : (
              <Company client={client} values={values} setValues={setValues} />
            )}
          </div>
          <div className="flex justify-between w-full mt-3 border-t py-3 px-8">
            <button type="button" className="btn-secondary" onClick={() => setClient(null)}>
              {t("cancel")}
            </button>
            <button type="submit" className="btn-primary" onClick={() => Mixpanel.track(MIXPANEL_EVENTS.btn_save_client)}>
              {t("save")}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

const isShippingAddressDefined = (values) => {
  return values?.shippingAddressStreet || values?.shippingAddressCity || values?.shippingAddressZipCode || values?.shippingAddressCountry ? true : false;
};

const ShippingAddress = ({ client, values, setValues }) => {
  const { t } = useTranslation();
  const [hasShippingAddress, setHasShippingAddress] = useState();

  useEffect(() => {
    setHasShippingAddress(isShippingAddressDefined(client));
  }, [client]);

  return (
    <div className="flex-column items-center ml-2">
      <div className="flex items-center gap-2">
        <input
          type="checkbox"
          className=" mb-1 my-auto inline-flex items-center cursor-pointer text-primary bg-[#F3F4ED] rounded-full"
          checked={hasShippingAddress ?? false}
          onChange={(e) => {
            setHasShippingAddress(e.target.checked);
            if (!e.target.checked) {
              setValues({ ...values, shippingAddressStreet: null, shippingAddressCity: null, shippingAddressZipCode: null, shippingAddressCountry: null });
            }
          }}
          name="hasShippingAddress"
          id="hasShippingAddress"
        />
        <label htmlFor="hasShippingAddress" className="inline-flex text-primary text-base mb-1 select-none">
          {t("shipping_address_differs")}
        </label>
      </div>

      {hasShippingAddress && (
        <>
          <h2 className="text-primary text-base mb-2 font-bold"> {t("shipping_address")} </h2>

          <div className="flex flex-col mb-3 w-1/2 ">
            <div className="text-base mb-1 ml-2 text-slate-600 ">Adresse</div>
            <input
              type="text"
              value={values?.shippingAddressStreet ?? ""}
              onChange={(e) => setValues({ ...values, shippingAddressStreet: e.target.value })}
              className="border-[1px] focus:ring-blue-500 focus:ring-1 focus:border-blue-500 border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-4 py-2"
              // placeholder={t("clients.edit.street_placeholder")}
              placeholder="Ex. 8 Rue des Fleurs"
            />
          </div>
          <div className="flex gap-2">
            <div className="flex flex-col w-28">
              <div className="text-base mb-1 ml-2 text-slate-600 "> {t("zip_code")} </div>
              <input
                type="text"
                value={values?.shippingAddressZipCode ?? ""}
                onChange={(e) => setValues({ ...values, shippingAddressZipCode: e.target.value })}
                className="border-[1px] focus:ring-blue-500 focus:ring-1 focus:border-blue-500 border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-4 py-2"
                // placeholder={t("clients.edit.zip_placeholder")}
                placeholder="Ex. 60200"
              />
            </div>
            <div className="flex flex-col mb-3 w-1/3 ">
              <div className="text-base mb-1 ml-2 text-slate-600 "> {t("city")} </div>
              <input
                type="text"
                value={values?.shippingAddressCity ?? ""}
                onChange={(e) => setValues({ ...values, shippingAddressCity: e.target.value })}
                className="border-[1px] focus:ring-blue-500 focus:ring-1 focus:border-blue-500 border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-4 py-2"
                // placeholder={t("clients.edit.city_placeholder")}
                placeholder="Ex. Compiègne"
              />
            </div>
          </div>
          <div className="flex flex-col mb-3 w-1/2 ">
            <div className="text-base mb-1 ml-2 text-slate-600 "> {t("country")} </div>
            <input
              type="text"
              value={values?.shippingAddressCountry ?? ""}
              onChange={(e) => setValues({ ...values, shippingAddressCountry: e.target.value })}
              className="border-[1px] focus:ring-blue-500 focus:ring-1 focus:border-blue-500 border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-4 py-2"
              // placeholder={t("clients.edit.country_placeholder")}
              placeholder="Ex. France"
            />
          </div>
        </>
      )}
    </div>
  );
};

const ContactFields = ({ values, setValues }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-8">
      {(values.contacts || [])?.map((contact, index) => (
        <div key={index}>
          <div className="grid grid-cols-3 grid-rows-1 gap-4 items-end">
            <div className="flex flex-col">
              <div className="text-base mb-1 font-semibold"> {t("last_name")} </div>
              <input
                type="text"
                value={contact.lastname}
                onChange={(e) => {
                  const updatedContacts = [...values.contacts];
                  updatedContacts[index].lastname = e.target.value;
                  setValues({ ...values, contacts: updatedContacts });
                }}
                className="flex-1 border-[1px] border-gray-300 rounded-lg text-gray-800 px-3 py-2"
                placeholder={t("last_name")}
              />
            </div>
            <div className="flex flex-col">
              <div className="text-base mb-1 font-semibold"> {t("firstname")} </div>
              <input
                type="text"
                value={contact.firstname}
                onChange={(e) => {
                  const updatedContacts = [...values.contacts];
                  updatedContacts[index].firstname = e.target.value;
                  setValues({ ...values, contacts: updatedContacts });
                }}
                className="flex-1 border-[1px] border-gray-300 rounded-lg text-gray-800 px-3 py-2"
                placeholder={t("firstname")}
              />
            </div>

            <div className="flex flex-col">
              <div className="text-base mb-1 font-semibold"> {t("email")} </div>
              <input
                type="text"
                value={contact.email}
                onChange={(e) => {
                  const updatedContacts = [...values.contacts];
                  updatedContacts[index].email = e.target.value;
                  setValues({ ...values, contacts: updatedContacts });
                }}
                className="flex-1 border-[1px] border-gray-300 rounded-lg text-gray-800 px-3 py-2"
                placeholder={t("clients.edit.email_placeholder")}
              />
            </div>
          </div>

          <div className="grid grid-cols-3 grid-rows-1 gap-4">
            <div className="flex flex-col">
              <div className="text-base mb-1 font-semibold"> {t("clients.edit.landline_number")} </div>
              <input
                type="text"
                value={contact.landlinePhone}
                onChange={(e) => {
                  const updatedContacts = [...values.contacts];
                  updatedContacts[index].landlinePhone = e.target.value;
                  setValues({ ...values, contacts: updatedContacts });
                }}
                className="flex-1 border-[1px] border-gray-300 rounded-lg text-gray-800 px-3 py-2"
                placeholder={t("clients.edit.landline_number_placeholder")}
              />
            </div>
            <div className="flex flex-col">
              <div className="text-base mb-1 font-semibold"> {t("clients.edit.mobile_number")} </div>
              <input
                type="text"
                value={contact.mobilePhone}
                onChange={(e) => {
                  const updatedContacts = [...values.contacts];
                  updatedContacts[index].mobilePhone = e.target.value;
                  setValues({ ...values, contacts: updatedContacts });
                }}
                className="flex-1 border-[1px] border-gray-300 rounded-lg text-gray-800 px-3 py-2"
                placeholder={t("clients.edit.mobile_number_placeholder")}
              />
            </div>
          </div>

          <div className="border-t border-t-gray-300 mt-10 mb-5"></div>
        </div>
      ))}
      <button
        type="button"
        onClick={() => setValues({ ...values, contacts: [...(values.contacts || []), { firstname: "", lastname: "", email: "" }] })}
        className="btn-secondary hover:bg-blue-100 flex gap-2 items-center w-fit">
        <IoAddOutline /> {t("new_contact")}
      </button>
    </div>
  );
};

const Individual = ({ client, values, setValues }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-col mb-3">
        <div className="text-base mb-1 font-semibold"> {t("type")} </div>
        <div className="inline-flex items-center gap-4">
          <div className="flex items-center">
            <input
              type="radio"
              id="INDIVIDUAL"
              name="entityType"
              value="INDIVIDUAL"
              checked={values.entityType === "INDIVIDUAL"}
              onChange={() => setValues({ ...values, entityType: "INDIVIDUAL" })}
            />
            <label htmlFor="INDIVIDUAL" className="ml-2">
              {t("individual")}
            </label>
          </div>
          <div className="flex items-center">
            <input
              type="radio"
              id="COMPANY"
              name="entityType"
              value="COMPANY"
              checked={values.entityType === "COMPANY"}
              onChange={() => setValues({ ...values, entityType: "COMPANY" })}
            />
            <label htmlFor="COMPANY" className="ml-2">
              {t("company")}
            </label>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 grid-rows-1 gap-4">
        <div className="flex flex-col">
          <div className="text-base mb-1 font-semibold"> {t("first_name")} </div>
          <input
            required
            type="text"
            value={values.firstname || ""}
            onChange={(e) => setValues({ ...values, firstname: e.target.value })}
            className="border-[1px] border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-3 py-2"
            placeholder={t("clients.edit.first_name_placeholder")}
          />
        </div>
        <div className="flex flex-col">
          <div className="text-base mb-1 font-semibold"> {t("last_name")} </div>
          <input
            required
            type="text"
            value={values.lastname || ""}
            onChange={(e) => setValues({ ...values, lastname: e.target.value })}
            className="border-[1px] border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-3 py-2"
            placeholder={t("clients.edit.last_name_placeholder")}
          />
        </div>
      </div>
      <separator className="w-full h-6" />

      <div className="flex flex-col mr-1/2">
        <div className="text-base mb-1 font-semibold capitalize"> {t("clients.edit.recipient_emails")} </div>

        <MultipleSelectInput
          value={values.emails ?? []}
          placeholder={t("clients.edit.recipient_emails_placeholder")}
          onChange={(e) => setValues({ ...values, emails: e.map((e) => e.trim()) })}
        />
      </div>
      <div className="grid grid-cols-2 grid-rows-1 gap-4">
        <div className="flex flex-col">
          <div className="text-base mb-1 font-semibold"> {t("clients.edit.landline_number")} </div>
          <input
            type="text"
            value={values.landlinePhone ?? ""}
            onChange={(e) => setValues({ ...values, landlinePhone: e.target.value })}
            className="flex-1 border-[1px] border-gray-300 rounded-lg text-gray-800 px-3 py-2"
            placeholder={t("clients.edit.landline_number_placeholder")}
          />
        </div>
        <div className="flex flex-col">
          <div className="text-base mb-1 font-semibold"> {t("clients.edit.mobile_number")} </div>
          <input
            type="text"
            value={values.mobilePhone ?? ""}
            onChange={(e) => setValues({ ...values, mobilePhone: e.target.value })}
            className="flex-1 border-[1px] border-gray-300 rounded-lg text-gray-800 px-3 py-2"
            placeholder={t("clients.edit.mobile_number_placeholder")}
          />
        </div>
      </div>

      <separator className="w-full h-6" />

      <h2 className="text-primary text-base mb-1 font-semibold underline"> {t("contacts")} </h2>

      <ContactFields values={values} setValues={setValues} />

      <separator className="w-full h-6" />

      <h2 className="text-primary text-base mb-1 font-semibold underline"> {t("billing_address")} </h2>
      <div className="grid grid-cols-2 grid-rows-2 gap-y-2 gap-x-4">
        <div className="flex flex-col">
          <div className="text-base mb-1 font-semibold"> {t("street")} </div>
          <input
            type="text"
            value={values.billingAddressStreet ?? ""}
            onChange={(e) => setValues({ ...values, billingAddressStreet: e.target.value })}
            className="border-[1px] border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-3 py-2"
            placeholder={t("clients.edit.street_placeholder")}
          />
        </div>
        <div className="flex flex-col">
          <div className="text-base mb-1 font-semibold"> {t("city")} </div>
          <input
            type="text"
            value={values.billingAddressCity ?? ""}
            onChange={(e) => setValues({ ...values, billingAddressCity: e.target.value })}
            className="border-[1px] border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-3 py-2"
            placeholder={t("clients.edit.city_placeholder")}
          />
        </div>
        <div className="flex flex-col row-start-2">
          <div className="text-base mb-1 font-semibold"> {t("zip_code")} </div>
          <input
            type="text"
            value={values.billingAddressZipCode ?? ""}
            onChange={(e) => setValues({ ...values, billingAddressZipCode: e.target.value })}
            className="border-[1px] border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-3 py-2"
            placeholder={t("clients.edit.zip_placeholder")}
          />
        </div>
        <div className="flex flex-col row-start-2">
          <div className="text-base mb-1 font-semibold"> {t("clients.edit.country_placeholder")} </div>
          <input
            type="text"
            value={values.billingAddressCountry ?? ""}
            onChange={(e) => setValues({ ...values, billingAddressCountry: e.target.value })}
            className="border-[1px] border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-3 py-2"
            placeholder={t("clients.edit.country_placeholder")}
          />
        </div>
      </div>

      <ShippingAddress client={client} values={values} setValues={setValues} />

      <separator className="w-full h-6" />

      <h2 className="text-primary text-base mb-1 font-semibold underline"> {t("clients.edit.client_activity")} </h2>
      <div className="grid grid-cols-2 grid-rows-1 gap-4">
        <div className="flex flex-col">
          <label htmlFor="categories" className="text-base mb-1 font-semibold">
            {t("default_category")}
          </label>
          <Combobox
            value={categories.find((category) => category.id == values.defaultCategory)}
            options={categories}
            onChange={(value) => setValues({ ...values, defaultCategory: value.id || null })}
            getLabel={(e) => (e ? e.id + " - " + t(`transactions.${e.id}`) : null)}
            placeholder={t("select_category")}
          />
        </div>

        <div className="flex flex-col">
          <div className="text-base mb-1 font-semibold"> {t("website")} </div>
          <input
            type="text"
            value={values.website ?? ""}
            onChange={(e) => setValues({ ...values, website: e.target.value })}
            className="border-[1px] border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-3 py-2"
            placeholder={t("clients.edit.website_placeholder")}
          />
        </div>
      </div>

      <separator className="w-full h-6" />

      <div className="flex flex-col">
        <div className="text-base mb-1 font-semibold"> {t("memo_note")} </div>
        <textarea
          type="text"
          value={values.memo ?? ""}
          onChange={(e) => setValues({ ...values, memo: e.target.value })}
          className="border-[1px] border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-3 py-2"
          placeholder={t("memo_note.to_enter.placeholder")}
        />
      </div>
    </div>
  );
};

const Company = ({ client, values, setValues }) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-col mb-2">
        <div className="text-base tracking-tight uppercase mb-2 font-semibold inline-flex w-full items-center gap-4">
          {t("type")}
          <hr className="w-full h-px   bg-gray-200 border-0 " />
        </div>
        <div className="inline-flex items-center gap-4">
          <div className="flex items-center">
            <input
              type="radio"
              id="INDIVIDUAL"
              name="entityType"
              value="INDIVIDUAL"
              checked={values.entityType === "INDIVIDUAL"}
              onChange={() => setValues({ ...values, entityType: "INDIVIDUAL" })}
            />
            <label htmlFor="INDIVIDUAL" className="ml-2 cursor-pointer">
              {t("individual")}
            </label>
          </div>
          <div className="flex items-center">
            <input
              type="radio"
              id="COMPANY"
              name="entityType"
              value="COMPANY"
              checked={values.entityType === "COMPANY"}
              onChange={() => setValues({ ...values, entityType: "COMPANY" })}
            />
            <label htmlFor="COMPANY" className="ml-2 cursor-pointer">
              {t("company")}
            </label>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-1/2 pr-2">
        <div className="text-base mb-1 ml-2 text-slate-600 "> Nom de l'entreprise </div>
        <input
          required
          type="text"
          value={values.companyName || ""}
          onChange={(e) => setValues({ ...values, companyName: e.target.value })}
          className="border-[1px] focus:ring-blue-500 focus:ring-1 focus:border-blue-500 border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-4 py-2"
          placeholder={t("clients.company_name_placeholder")}
        />
      </div>
      <div className="grid grid-cols-2 grid-rows-1 gap-4">
        <div className="flex flex-col">
          <div className="text-base mb-1 ml-2 text-slate-600 "> {t("business_registration_number")} </div>
          <input
            type="text"
            value={values.registrationNumber || ""}
            onChange={(e) => setValues({ ...values, registrationNumber: e.target.value })}
            className="border-[1px] focus:ring-blue-500 focus:ring-1 focus:border-blue-500 border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-4 py-2"
            // placeholder={t("clients.edit.business_registration_number_placeholder")}
            placeholder="Ex. SIREN"
          />
        </div>
        <div className="flex flex-col">
          <div className="text-base mb-1 ml-2 text-slate-600 "> {t("vat_number")} </div>
          <input
            type="text"
            defaultValue={values.vatNumber ?? ""}
            onChange={(e) => setValues({ ...values, vatNumber: e.target.value })}
            className="border-[1px] focus:ring-blue-500 focus:ring-1 focus:border-blue-500 border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-4 py-2"
            // placeholder={t("vat_number_placeholder")}
            placeholder="Ex. FR12345678901"
          />
        </div>
      </div>

      <div className="flex flex-col mr-1/2">
        <div className="text-base mb-1 ml-2 text-slate-600 "> {t("clients.edit.recipient_emails")} </div>
        <MultipleSelectInput
          value={values.emails ?? []}
          placeholder={t("clients.edit.recipient_emails_placeholder")}
          onChange={(e) => setValues({ ...values, emails: e.map((e) => e.trim()) })}
        />
      </div>

      <separator className="w-full h-3" />
      <div className="text-base mb-2  inline-flex  w-full items-center gap-4">
        <h2 className="text-primary text-base tracking-tight uppercase  font-bold"> {t("contacts")} </h2>
        <hr className="w-full h-px  bg-gray-200 border-0 " />
      </div>

      <ContactFields values={values} setValues={setValues} />

      <separator className="w-full h-3" />
      <div className="text-base mb-2  inline-flex  w-full items-center gap-4">
        <h2 className="text-primary text-base w-80 tracking-tight uppercase  font-bold"> {t("billing_address")} </h2>
        <hr className="w-full h-px  bg-gray-200 border-0 " />
      </div>

      <div className="flex flex-col w-1/2">
        <div className="text-base mb-1 ml-2 text-slate-600 ">Adresse</div>
        <input
          type="text"
          value={values.billingAddressStreet ?? ""}
          onChange={(e) => setValues({ ...values, billingAddressStreet: e.target.value })}
          className="border-[1px] focus:ring-blue-500 focus:ring-1 focus:border-blue-500 border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-4 py-2"
          // placeholder={t("clients.edit.street_placeholder")}
          placeholder="Ex. 12 Rue de la Paix"
        />
      </div>
      <div className="flex gap-2">
        <div className="flex flex-col w-28">
          <div className="text-base mb-1 ml-2 text-slate-600 "> {t("zip_code")} </div>
          <input
            type="text"
            value={values.billingAddressZipCode ?? ""}
            onChange={(e) => setValues({ ...values, billingAddressZipCode: e.target.value })}
            className="border-[1px] focus:ring-blue-500 focus:ring-1 focus:border-blue-500 border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-4 py-2"
            // placeholder={t("clients.edit.zip_placeholder")}
            placeholder="Ex. 75008"
          />
        </div>

        <div className="flex flex-col w-1/3">
          <div className="text-base mb-1 ml-2 text-slate-600 "> {t("city")} </div>
          <input
            type="text"
            value={values.billingAddressCity ?? ""}
            onChange={(e) => setValues({ ...values, billingAddressCity: e.target.value })}
            className="border-[1px] focus:ring-blue-500 focus:ring-1 focus:border-blue-500 border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-4 py-2"
            // placeholder={t("clients.edit.city_placeholder")}
            placeholder="Ex. Paris"
          />
        </div>
      </div>
      <div className="flex flex-col w-1/2">
        <div className="flex flex-col row-start-2">
          <div className="text-base mb-1 ml-2 text-slate-600 "> {t("country")} </div>
          <input
            type="text"
            value={values.billingAddressCountry ?? ""}
            onChange={(e) => setValues({ ...values, billingAddressCountry: e.target.value })}
            className="border-[1px] focus:ring-blue-500 focus:ring-1 focus:border-blue-500 border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-4 py-2"
            // placeholder={t("clients.edit.country_placeholder")}
            placeholder="Ex. France"
          />
        </div>
      </div>
      <ShippingAddress client={client} values={values} setValues={setValues} />

      <separator className="w-full h-3 " />
      <div className="flex flex-col">
        <div className="text-base mb-2  inline-flex  w-full items-center gap-4">
          <h2 className="text-primary text-base w-40 tracking-tight uppercase  font-bold"> {t("clients.edit.client_activity")}</h2>
          <hr className="w-full h-px  bg-gray-200 border-0 " />
        </div>

        <div className="grid grid-cols-2 grid-rows-1 gap-4">
          <div className="flex flex-col">
            <label htmlFor="categories" className="text-base mb-1 ml-2 text-slate-600 ">
              {t("default_category")}
            </label>
            <Combobox
              value={categories.find((category) => category.id == values.defaultCategory)}
              options={categories}
              onChange={(value) => setValues({ ...values, defaultCategory: value.id || null })}
              getLabel={(e) => (e ? e.id + " - " + t(`transactions.${e.id}`) : null)}
              placeholder={t("select_category")}
            />
          </div>

          <div className="flex flex-col">
            <div className="text-base mb-1 ml-2 text-slate-600 "> {t("website")} </div>
            <input
              type="text"
              value={values.website ?? ""}
              onChange={(e) => setValues({ ...values, website: e.target.value })}
              className="border-[1px] focus:ring-blue-500 focus:ring-1 focus:border-blue-500 border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-4 py-2"
              // placeholder={t("clients.edit.website_placeholder")}
              placeholder="www.example.com"
            />
          </div>
        </div>
      </div>

      <separator className="w-full h-3" />

      <div className="flex flex-col">
        <div className="text-base mb-2  inline-flex  w-full items-center gap-4">
          <h2 className="text-primary text-base w-28 tracking-tight uppercase  font-bold"> {t("memo_note")} </h2>
          <hr className="w-full h-px  bg-gray-200 border-0 " />
        </div>

        <textarea
          type="text"
          value={values.memo ?? ""}
          onChange={(e) => setValues({ ...values, memo: e.target.value })}
          className="border-[1px] focus:ring-blue-500 focus:ring-1 focus:border-blue-500 border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-4 py-2"
          // placeholder={t("memo_note.to_enter.placeholder")}
          placeholder="Ex. Informations complémentaires sur l'entreprise"
        />
      </div>
    </div>
  );
};

export default Edit;
