import { useState, useEffect } from "react";
import toast from "react-hot-toast";

import api from "@services/api";
import Loader from "@components/utils/Loader";
import { useTranslation } from "react-i18next";
import { MdContentCopy } from "react-icons/md";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { copyToClipboard } from "../../utils";

const ApiKey = () => {
  const { t } = useTranslation();
  const [organizations, setOrganizations] = useState(null);

  const get = async () => {
    const res = await api.post("/organization/search");
    if (!res.ok) toast.error(t("toast.error.fetching_org"));
    setOrganizations(res.data);
  };

  useEffect(() => {
    get();
  }, []);

  if (!organizations) return <Loader />;

  return (
    <div className="flex container mx-auto">
      <div className="flex flex-col gap-4 bg-white rounded-xl w-full">
        {organizations.map((org) => {
          return <Key key={org._id} organization={org} />;
        })}
      </div>
    </div>
  );
};

const Key = ({ organization }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(organization);
  const [isApiKeyVisible, setApiKeyVisible] = useState(false);

  const createApiKey = async () => {
    if (!confirm("Are you sure you want to create a new API key?")) return;
    const res = await api.put(`/organization/${organization._id}/generate-apikey`);
    if (!res.ok) return toast.error(t("toast.error.creating_api_key"));
    setValue(res.data);
  };

  return (
    <div className="flex flex-col">
      <div className="flex justify-between items-center mb-2">
        <h2 className="text-primary text-base mb-1 font-semibold underline">
          {t("apikey_from")} {organization.name}
        </h2>
        <button onClick={createApiKey} className="bg-primary text-white px-4 py-2 rounded-md">
          Create
        </button>
      </div>

      <div className="border border-black-30 rounded-md divide-y divide-dotted">
        <div className="h-20 w-full flex flex-col items-start justify-center p-4">
          <div className="flex justify-between w-full items-center">
            <input
              type={isApiKeyVisible ? "text" : "password"}
              value={value?.apiKey}
              readOnly
              className="text-sm font-semibold bg-transparent border-none focus:outline-none bg-red-600 w-full"
            />
            {value?.apiKey && (
              <div className="flex items-center">
                <button onClick={() => setApiKeyVisible(!isApiKeyVisible)} className="ml-4 bg-gray-200 text-sm text-gray-700 px-3 py-1 rounded-md">
                  {isApiKeyVisible ? <FaEyeSlash /> : <FaEye />}
                </button>
                <button onClick={() => copyToClipboard(value?.apiKey)} className="ml-2 bg-gray-200 text-sm text-gray-700 px-3 py-1 rounded-md">
                  <MdContentCopy />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApiKey;
