import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import api from "@services/api";
import categories from "@data/categories.js";
import { Mixpanel } from "@services/mixpanel";
import { currencyToStr, getAccountName } from "@utils";
import { useTranslation } from "react-i18next";
import { toast } from "react-hot-toast";
import { AiFillFilePdf } from "react-icons/ai";
import { MdDeleteOutline } from "react-icons/md";
import { useSelector } from "react-redux";

import TransactionsStripe from "./transactionsStripe";
import Loader from "@components/utils/Loader";
import { Combobox } from "@components/utils/Combobox";
import FileInput from "@components/utils/FileInput";
import Modal from "@components/utils/Modal";
import { Select } from "@components/utils/Select";
import { Tooltip } from "react-tooltip";
import { MIXPANEL_EVENTS } from "../../utils/mixpanelEvents";

const TransactionModal = ({
  isOpen,
  onClose,
  onSave,
  setShowCreateClientModal,
  setShowCreateEmployeeModal,
  showCreateSupplierModal,
  createdEmployee,
  createdClient,
  createdSupplier,
}) => {
  const { search } = useLocation();
  const [clientOptions, setClientOptions] = useState([]);
  const [supplierOptions, setSupplierOptions] = useState([]);
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [accountOptions, setAccountOptions] = useState([]);
  const [invoiceOptions, setInvoiceOptions] = useState([]);
  const [transaction, setTransaction] = useState({});
  const [linkedStripeTransactions, setLinkedStripeTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { organization } = useSelector((state) => state.Auth);

  const fetchTransaction = async (transactionId) => {
    const res = await api.get(`/transaction/${transactionId}`);
    if (!res.ok) toast.error(t("toast.error.fetching_trans"));
    if (res.data.linkedStripeTransactionId) {
      const { ok, data: stripeTransactions } = await api.get(`/stripe/transaction/payout/${res.data.linkedStripeTransactionId}?OrganizationId=${organization._id.toString()}`);
      if (!ok) toast.error(t("toast.error.fetching_stripe_trans"));
      setLinkedStripeTransactions(stripeTransactions.transactions);
    }
    if (!res.data.type) {
      res.data.type = res.data.amount < 0 ? "SUPPLIER" : "CLIENT";
    }
    setTransaction(res.data);
  };

  useEffect(() => {
    const query = new URLSearchParams(search);
    const transactionId = query.get("id");
    if (!transactionId) return onClose();
    fetchTransaction(transactionId);
  }, [isOpen]);

  const getClients = async () => {
    const { data: clientRes } = await api.post(`/client/search`, { per_page: 1000, OrganizationId: organization?._id });
    setClientOptions(clientRes.clients);
  };

  const getSuppliers = async () => {
    const { data: supplierRes } = await api.post(`/supplier/search`, { per_page: 1000, OrganizationId: organization?._id });
    setSupplierOptions(supplierRes.suppliers);
  };

  const getEmployees = async () => {
    const { data: employeeRes } = await api.post(`/employee/search`, { per_page: 1000, OrganizationId: organization?._id });
    setEmployeeOptions(employeeRes.employees);
  };

  const getAccounts = async () => {
    const responseAccount = await api.post("/account/search", { OrganizationId: organization?._id });
    setAccountOptions(responseAccount?.data);
  };

  const getInvoices = async () => {
    const { data } = await api.post("/invoice/search", { per_page: 1000, statuses: ["SENT", "PAID", "DUE"], OrganizationId: organization?._id });

    setInvoiceOptions(data.invoices);
  };

  useEffect(() => {
    getInvoices();
    getClients();
    getSuppliers();
    getEmployees();
    getAccounts();
  }, [transaction]);

  useEffect(() => {
    if (createdClient) setTransaction((transaction) => ({ ...transaction, ClientId: createdClient?._id || null }));
  }, [createdClient]);

  useEffect(() => {
    if (createdSupplier) setTransaction((transaction) => ({ ...transaction, SupplierId: createdSupplier?._id || null }));
  }, [createdSupplier]);

  useEffect(() => {
    if (createdEmployee) setTransaction((transaction) => ({ ...transaction, EmployeeId: createdEmployee?._id || null }));
  }, [createdEmployee]);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      const { ok, code } = await api.put(`/transaction/${transaction._id}`, { ...transaction });
      if (!ok) throw { code };
      onSave();
      toast.success(t("toast.sucess.updated_sucessfully"));
      handleClose();
    } catch (e) {
      console.error(t("toast.error"), e);
      toast.error(e.code);
    }
    setLoading(false);
  };
  const canReconcile = () => {
    return transaction.category && (transaction.ClientId || transaction.SupplierId || transaction.EmployeeId || transaction.TransferAccountId);
  };

  const handleSaveAndReconcile = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      const { ok, code } = await api.put(`/transaction/${transaction._id}`, { ...transaction, status: "RECONCILED" });
      if (transaction.Invoices) {
        transaction.Invoices.forEach(async (invoice) => {
          await api.put(`/invoice/${invoice._id}`, { status: "PAID" });
        });
      }
      if (!ok) throw { code };
      onSave();
      toast.success(t("toast.success.reconciled"));
      handleClose();
    } catch (e) {
      console.error(t("toast.error"), e);
      toast.error(e.code);
    }
    setLoading(false);
  };

  const handleCancelReconcile = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      const { ok, code } = await api.put(`/transaction/${transaction._id}`, { ...transaction, status: "TO_RECONCILE" });
      if (!ok) throw { code };
      onSave();
      toast.success(t("toast.success.cancel_reconcile"));
      handleClose();
    } catch (e) {
      console.error(t("toast.error"), e);
      toast.error(e.code);
    }
    setLoading(false);
  };

  const handleDelete = async () => {
    try {
      const confirm = window.confirm(t("confirm_delete_message"));
      if (!confirm) return;
      const { ok, code } = await api.remove(`/transaction/${transaction._id}`);
      if (!ok) throw code;
      toast.success(t("toast.sucess.removed"));
      onSave();
      handleClose();
    } catch (e) {
      console.error(t("toast.error"), e);
      toast.error(e.code);
    }
  };

  const handleClose = () => {
    setTransaction(null);
    setLinkedStripeTransactions([]);
    onClose();
  };

  const supplierOption = (item) => {
    if (!item) return null;
    const supplierLabel = item.entityType === "INDIVIDUAL" ? item.firstname + " " + item.lastname : item.companyName || "";
    if (!transaction?.SupplierId && transaction?.SupplierIdSuggestion === item._id) return `${supplierLabel} (${t("suggested")})`;
    return supplierLabel;
  };

  const clientOption = (item) => {
    if (!item) return null;
    const clientLabel = item.entityType === "INDIVIDUAL" ? item.firstname + " " + item.lastname : item.companyName || "";
    if (!transaction?.ClientId && transaction?.ClientIdSuggestion === item._id) return `${clientLabel} (${t("suggested")})`;
    return clientLabel;
  };

  if (loading) return <Loader />;
  if (!transaction) return null;

  return (
    <Modal isOpen={isOpen} onClose={handleClose} innerClassName="flex min-w-[50rem] max-w-[90vw] max-h-[calc(100vh-5rem)]">
      <div className="w-full max-h-full flex flex-col overflow-y-hidden transform rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
        <div className="px-8 py-5 text-left text-lg font-semibold border-b">{t("transactions.edit_transaction")}</div>
        <div className="py-4 px-8 overflow-auto">
          <div className="flex flex-col gap-4">
            <div className="grid grid-cols-2 grid-rows-3 gap-4">
              <div className="flex flex-col row-start-1">
                <div className="text-base font-semibold text-gray-500 capitalize"> {t("date")} </div>
                <div className="placeholder:text-gray-300 pr-3 py-2">{new Date(transaction.date).toLocaleString()}</div>
              </div>
              <div className="flex flex-col row-start-1">
                <div className="text-base font-semibold text-gray-500 capitalize"> {t("description")}</div>
                <div className="border-gray-300 placeholder:text-gray-300 pr-3 py-2">
                  {transaction.name}, {transaction.description}, {transaction.additionalInformation}
                </div>
              </div>
              <div className="flex flex-col row-start-2">
                <div className="text-base font-semibold text-gray-500 capitalize"> {t("account")} </div>
                <div className="border-gray-300 placeholder:text-gray-300 pr-3 py-2">
                  <img src={transaction.bankLogo} height={20} width={20} className="mr-2" />
                  {transaction.accountDetails} {transaction.accountOwner}
                </div>
              </div>
              <div className="flex flex-col row-start-2">
                <div className="text-base font-semibold text-gray-500 capitalize"> {t("amount")} </div>
                <div className=" border-gray-300 placeholder:text-gray-300 pr-3 py-2">
                  {transaction.amount} {currencyToStr(transaction.currency)}
                </div>
              </div>
              <div className="flex flex-col row-start-3">
                <label htmlFor="categories" className="text-base font-semibold text-gray-500 capitalize">
                  {t("category")}
                </label>
                <Combobox
                  value={categories.find((category) => category.id == transaction.category)}
                  options={categories}
                  onChange={(category) => setTransaction({ ...transaction, category: category?.id || null })}
                  getLabel={(e) => (e ? e.id + " - " + t(`transactions.${e.id}`) : null)}
                  placeholder={t("transactions.edit.placeholder.select_category")}
                />
              </div>
            </div>
            <hr className="mb-2" />
            <TransactionsStripe transactions={linkedStripeTransactions} />
            {transaction.category !== "2100" && (
              <div className="grid grid-cols-2 grid-rows-1 gap-4">
                <div className="flex flex-col row-start-1">
                  <div className="text-base font-semibold text-gray-500 capitalize"> {t("type")} </div>
                  <Select
                    options={["CLIENT", "SUPPLIER", "EMPLOYEE", "TRANSFER"]}
                    value={transaction.type ? transaction.type : transaction.amount < 0 ? "SUPPLIER" : "CLIENT"}
                    onChange={(type) => {
                      const body = { type };
                      if (type === "TRANSFER") {
                        body.category = "8080";
                      }
                      setTransaction((transaction) => ({
                        ...transaction,
                        ...body,
                      }));
                    }}
                    getLabel={(e) => t(e.toLowerCase())}
                    placeholder="Select a type"
                    nullable={false}
                  />
                </div>
                <div className="flex flex-col row-start-1">
                  {transaction.type == "SUPPLIER" ? (
                    <>
                      <div className="text-base font-semibold text-gray-500 capitalize"> {t("supplier")} </div>
                      <Combobox
                        options={supplierOptions}
                        value={supplierOptions.find((supplier) => supplier._id === (transaction?.SupplierId || transaction?.SupplierIdSuggestion))}
                        onChange={(e) =>
                          setTransaction((transaction) => ({
                            ...transaction,
                            SupplierId: e?._id || null,
                            category: transaction.category || e?.defaultCategory,
                          }))
                        }
                        placeholder={t("supplier")}
                        getLabel={(supplier) => supplierOption(supplier)}
                        nullable={true}
                        listHeader={
                          <button className="px-3 py-2 w-full flex items-center justify-between font-semibold text-sm" onClick={() => showCreateSupplierModal()}>
                            {t("supplier.create_supplier")} <span>+</span>
                          </button>
                        }
                        displayValue={(supplier) => supplierOption(supplier)}
                      />
                    </>
                  ) : transaction.type == "EMPLOYEE" ? (
                    <>
                      <div className="text-base font-semibold text-gray-500 capitalize"> {t("employee")} </div>
                      <Combobox
                        options={employeeOptions}
                        value={employeeOptions.find((employee) => employee._id === (transaction?.EmployeeId || transaction?.EmployeeIdSuggestion))}
                        onChange={(e) => {
                          setTransaction((transaction) => ({ ...transaction, EmployeeId: e?._id || null }));
                        }}
                        placeholder={t("employee")}
                        getLabel={(employee) =>
                          transaction?.EmployeeId && transaction?.EmployeeIdSuggestion === employee._id
                            ? `${employee.firstname} ${employee.lastname} (${t("suggested")})`
                            : employee.firstname + " " + employee.lastname
                        }
                        listHeader={
                          <button className="px-3 py-2 w-full flex items-center justify-between font-semibold text-sm" onClick={() => setShowCreateEmployeeModal()}>
                            {t("employees.create")} <span>+</span>
                          </button>
                        }
                        displayValue={(employee) => {
                          if (!employee) return null;
                          const employeeLabel = employee.firstname + " " + employee.lastname;
                          if (!transaction?.EmployeeId && transaction?.EmployeeIdSuggestion === employee._id) return `${employeeLabel} (${t("suggested")})`;
                          return employeeLabel;
                        }}
                      />
                    </>
                  ) : transaction.type == "CLIENT" ? (
                    <>
                      <div className="text-base font-semibold text-gray-500 capitalize"> {t("client")} </div>
                      <Combobox
                        options={clientOptions}
                        value={clientOptions.find((client) => client._id === (transaction?.ClientId || transaction?.ClientIdSuggestion))}
                        onChange={(e) => {
                          setTransaction((transaction) => ({
                            ...transaction,
                            ClientId: e?._id || null,
                            category: transaction.category || e?.defaultCategory,
                          }));
                        }}
                        placeholder={t("client")}
                        getLabel={(client) => clientOption(client)}
                        listHeader={
                          <button className="px-3 py-2 w-full flex items-center justify-between font-semibold text-sm" onClick={() => setShowCreateClientModal()}>
                            {t("clients.create_a_client")} <span>+</span>
                          </button>
                        }
                        displayValue={(client) => clientOption(client)}
                      />
                    </>
                  ) : transaction.type == "TRANSFER" ? (
                    <>
                      <div className="text-base font-semibold text-gray-500 capitalize"> {t("account")} </div>
                      <Select
                        options={accountOptions}
                        value={accountOptions.find((account) => account._id == transaction.TransferAccountId) || null}
                        onChange={(e) => {
                          setTransaction((transaction) => ({
                            ...transaction,
                            TransferAccountId: e._id,
                          }));
                        }}
                        placeholder={t("account")}
                        getLabel={(account) => <AccountOption account={account} />}
                        width="w-full"
                        nullable={true}
                      />
                    </>
                  ) : null}
                </div>
              </div>
            )}

            {/* not payout and not transfer */}
            {!["2100", "8080"].includes(transaction.category) &&
              (transaction.type === "CLIENT" ? (
                <div className="flex flex-col row-start-1">
                  <div className="text-base font-semibold text-gray-500 "> {t("sales_invoices")} </div>

                  <Select
                    options={invoiceOptions}
                    value={transaction?.Invoices || []}
                    onChange={(selectedOptions) => {
                      setTransaction({
                        ...transaction,
                        Invoices: selectedOptions,
                      });
                    }}
                    multiple
                    placeholder={t("invoice")}
                    getLabel={(invoice) => (invoice ? invoice.reference + " " + (invoice.name || "") : null)}
                    listHeader={
                      <Link to={`/invoices?modal=open`} className="px-4 py-2 flex items-center justify-between font-semibold text-sm">
                        {t("invoices.create")} <span>+</span>
                      </Link>
                    }
                    by={(a, b) => a._id === b._id}
                  />
                </div>
              ) : (
                <div className="flex flex-col row-start-1">
                  <div className="text-base font-semibold text-gray-500 capitalize"> {t("purchase_invoices")} </div>
                  <div className="flex gap-3 ">
                    <TableAssociatedInvoices transaction={transaction} setTransaction={setTransaction} />
                  </div>
                </div>
              ))}
            <hr className="mb-2" />

            <div className="flex flex-col">
              <div className="text-base font-semibold text-gray-500 capitalize"> {t("memo_note")} </div>
              <textarea
                type="text"
                value={transaction.memo ?? ""}
                onChange={(e) => setTransaction({ ...transaction, memo: e.target.value })}
                className="border-[1px] border-gray-300 placeholder:text-gray-300 rounded-lg px-3 py-2"
                placeholder={t("memo_note.transaction.placeholder")}
              />
            </div>
          </div>
          <hr className="mt-2" />
          <button className="btn-primary mt-2" onClick={handleDelete}>
            {t("transactions.delete")}
          </button>
        </div>
        <div className="flex justify-between w-full mt-3 border-t py-3 px-8">
          <button type="submit" className="btn-secondary" onClick={handleClose}>
            {t("cancel")}
          </button>
          <div className="flex gap-2">
            <button
              className="btn-primary w-max"
              onClick={(e) => {
                Mixpanel.track(MIXPANEL_EVENTS.btn_transaction_updated);
                handleSubmit(e);
              }}>
              {t("save")}
            </button>
            {transaction.status === "TO_RECONCILE" ? (
              <>
                <button
                  disabled={!canReconcile()}
                  className="btn-primary w-max"
                  onClick={(e) => {
                    Mixpanel.track(MIXPANEL_EVENTS.btn_transaction_reconciled);
                    handleSaveAndReconcile(e);
                  }}
                  data-tooltip-id={`button-tooltip`}
                  data-tooltip-content={canReconcile() ? "" : t("transaction.reconcile_disable")}>
                  {t("save_reconcile")}
                </button>
                <Tooltip id={`button-tooltip`} opacity={1} style={{ zIndex: 1000, backgroundColor: "#40a5c6", borderRadius: "6px" }} />
              </>
            ) : (
              <button
                className="btn-primary w-max"
                onClick={(e) => {
                  Mixpanel.track(MIXPANEL_EVENTS.btn_transaction_cancel_reconciled);
                  handleCancelReconcile(e);
                }}>
                {t("cancel_reconcile")}
              </button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

const TableAssociatedInvoices = ({ transaction, setTransaction }) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [purchaseInvoices, setPurchaseInvoices] = useState([]);

  const getPurchaseInvoices = async () => {
    const { data } = await api.post("/purchase-invoice/search", { per_page: 1000 });
    setPurchaseInvoices(data.purchaseInvoices);
  };

  const removePurchaseInvoice = (index) => {
    setTransaction({
      ...transaction,
      PurchaseInvoices: [...transaction.PurchaseInvoices.slice(0, index), ...transaction.PurchaseInvoices.slice(index + 1)],
    });
  };

  useEffect(() => {
    getPurchaseInvoices();
  }, []);

  return (
    <div className="flex w-full gap-3">
      <div className=" mt-4  w-1/2 align-middle overflow-visible  flex flex-col gap-3">
        <div className="flex flex-col gap-3">
          <div className="flex gap-3 ">
            <Select
              options={purchaseInvoices}
              value={transaction?.PurchaseInvoices || []}
              onChange={(selectedOptions) => {
                setTransaction({
                  ...transaction,
                  PurchaseInvoices: selectedOptions,
                });
              }}
              multiple
              placeholder={t("choose_invoice")}
              getLabel={(purchaseInvoice) => {
                const amount = purchaseInvoice.amount;
                let supplierName = purchaseInvoice.Supplier?.companyName ?? t("not_defined");
                let date;
                if (purchaseInvoice.issueDate) {
                  date = new Date(purchaseInvoice.issueDate);
                }
                return (
                  <div className="flex w-full gap-1">
                    <span className="flex-1">{supplierName}</span>
                    <span className="px-2 bg-blue-100">{amount} €</span>
                    {date ? <span className="px-2 bg-green-100">{date.toLocaleDateString()}</span> : null}
                  </div>
                );
              }}
              by={(a, b) => a._id === b._id}
            />
          </div>
          <div className="flex gap-3">
            <FileInput
              onChange={async (file) => {
                if (!file) return;
                setLoading(true);
                const { data } = await api.post("/purchase-invoice", {
                  reference: decodeURIComponent(file.target.value.split("/").pop().split(".").shift()),
                  file: file.target.value,
                });
                setLoading(false);

                toast.success(t("toast.sucess.created"));

                setTransaction({
                  ...transaction,
                  PurchaseInvoices: [data, ...transaction.PurchaseInvoices],
                });
                getPurchaseInvoices();
              }}
              name={transaction._id}
              folder="purchaseInvoiceTransaction"
              width="w-full"
            />
            {loading && <Loader size="small" />}
          </div>
        </div>

        {transaction.PurchaseInvoices?.map((invoiceAssociated, index) => {
          return (
            <div key={invoiceAssociated._id} className="hover:bg-[#dee2e6] rounded-lg border flex">
              <div className="w-1/4 relative">
                {invoiceAssociated.file && (
                  <a href={invoiceAssociated.file} target="_blank" rel="noopener noreferrer" className="flex flex-col">
                    {invoiceAssociated.file.indexOf(".pdf") !== -1 ? (
                      <div className="absolute top-0 left-0 w-full bg-gray-300 opacity-80 hover:opacity-90 flex items-center justify-center cursor-pointer text-[12px] text-black font-normal h-full">
                        <AiFillFilePdf size={48} />
                      </div>
                    ) : (
                      <img className="h-full" src={invoiceAssociated.file} alt={`Invoice Image 1`} />
                    )}
                  </a>
                )}
              </div>

              <div className="w-3/4 p-2">
                <div className="font-medium"> {invoiceAssociated.reference} </div>
                <div className="text-sm">{invoiceAssociated.amount}</div>
                {invoiceAssociated.issueDate && (
                  <div className="text-sm">
                    {t("purchase_invoices.issued_on")} {new Date(invoiceAssociated.issueDate).toLocaleDateString()}
                  </div>
                )}
                {invoiceAssociated.files && (
                  <div className="flex gap-2 my-2">
                    {invoiceAssociated.files?.map((file, index) => {
                      return (
                        <a href={file} target="_blank" rel="noopener noreferrer" className="flex flex-col h-5 w-5" key={index}>
                          {file.indexOf(".pdf") !== -1 ? (
                            <div className=" w-full bg-gray-300 opacity-80 hover:opacity-90 flex items-center justify-center   h-full">
                              <AiFillFilePdf />
                            </div>
                          ) : (
                            <img className=" h-full rounded-sm hover:border " src={file} />
                          )}
                        </a>
                      );
                    })}
                  </div>
                )}

                <div>
                  <button type="button" onClick={() => removePurchaseInvoice(index)}>
                    <MdDeleteOutline />
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const AccountOption = ({ account }) => {
  const logo = account?.Requisition?.NordigenInstitution?.logo;
  if (!account) return;
  return (
    <span className="flex">
      {logo && <img src={logo} height={20} width={20} />}
      <span className="ml-2">{getAccountName(account)}</span>
    </span>
  );
};

export default TransactionModal;
