import { useTranslation } from "react-i18next";
import { useState } from "react";
import api from "@services/api";
import toast from "react-hot-toast";
import FileInput from "@components/utils/FileInput";
import Modal from "@components/utils/Modal";

const UpdateOrganization = ({ organization, open, onClose, onSave }) => {
  const { t } = useTranslation();
  const [values, setValues] = useState(organization);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = await api.put(`/organization/${values._id}`, { ...values });
    if (!res.ok) toast.error(t("toast.error.organization_updating"));
    onSave();
    onClose();
    toast.success(t("toast.sucess.organization_updated"));
  };

  return (
    <Modal isOpen={open} onClose={() => onClose()} innerClassName="flex flex-col max-w-[50rem] w-[50vw] max-h-[calc(100vh-5rem)] p-5">
      <div className="flex flex-row items-center cursor-pointer float-left relative l-1/2 ">
        <h2>{values?.name}</h2>
      </div>
      <form className="flex flex-col gap-4 mt-4" onSubmit={handleSubmit}>
        <FileInput value={values?.logo} onChange={(e) => setValues({ ...values, logo: e.target.value })} folder={`/organization`} />
        <div className="flex flex-col">
          <div className="text-gray-800 text-base mb-1"> {t("email")} </div>
          <input
            className="border-[1px] border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-3 py-2"
            value={values?.email ?? ""}
            onChange={(e) => setValues({ ...values, email: e.target.value })}
            placeholder={t("enter_email")}
          />
        </div>
        <div className="flex flex-col">
          <div className="text-gray-800 text-base mb-1"> {t("iban")} </div>
          <input
            className="border-[1px] border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-3 py-2"
            value={values?.iban ?? ""}
            onChange={(e) => setValues({ ...values, iban: e.target.value })}
            placeholder="What is the organization's IBAN?"
          />
        </div>
        <div className="flex flex-col">
          <div className="text-gray-800 text-base mb-1"> {t("business_registration_number")} </div>
          <input
            className="border-[1px] border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-3 py-2"
            value={values?.registrationNumber ?? ""}
            onChange={(e) => setValues({ ...values, registrationNumber: e.target.value })}
            placeholder="What is the organization's registration number? (e.g. SIREN)"
          />
        </div>

        <div className="flex flex-col">
          <div className="text-gray-800 text-base mb-1"> {t("vat_number")} </div>
          <input
            className="border-[1px] border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-3 py-2"
            value={values?.vat ?? ""}
            onChange={(e) => setValues({ ...values, vat: e.target.value })}
            placeholder={t("organization.vat_placeholder")}
          />
        </div>

        <separator className="w-full h-6" />

        <h2 className="text-primary text-base mb-1 font-semibold underline"> {t("billing_address")} </h2>
        <div className="grid grid-cols-2 grid-rows-2 gap-4">
          <div className="flex flex-col">
            <div className="text-gray-800 text-base mb-1">{t("street")}</div>
            <input
              type="text"
              value={values?.billingAddressStreet ?? ""}
              onChange={(e) => setValues({ ...values, billingAddressStreet: e.target.value })}
              className="border-[1px] border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-3 py-2"
              placeholder={t("organization.street_placeholder")}
            />
          </div>
          <div className="flex flex-col">
            <div className="text-gray-800 text-base mb-1">{t("city")}</div>
            <input
              type="text"
              value={values?.billingAddressCity ?? ""}
              onChange={(e) => setValues({ ...values, billingAddressCity: e.target.value })}
              className="border-[1px] border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-3 py-2"
              placeholder={t("organization.city_placeholder")}
            />
          </div>
          <div className="flex flex-col row-start-2">
            <div className="text-gray-800 text-base mb-1"> {t("zip_code")} </div>
            <input
              type="text"
              value={values?.billingAddressZipCode ?? ""}
              onChange={(e) =>
                setValues({
                  ...values,
                  billingAddressZipCode: e.target.value,
                })
              }
              className="border-[1px] border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-3 py-2"
              placeholder={t("organization.zip_placeholder")}
            />
          </div>
          <div className="flex flex-col row-start-2">
            <div className="text-gray-800 text-base mb-1"> {t("country")} </div>
            <input
              type="text"
              value={values?.billingAddressCountry ?? ""}
              onChange={(e) =>
                setValues({
                  ...values,
                  billingAddressCountry: e.target.value,
                })
              }
              className="border-[1px] border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-3 py-2"
              placeholder={t("organization.country_placeholder")}
            />
          </div>
        </div>

        <div className="flex mt-4 w-full">
          <button type="submit" className="btn-primary">
            {t("save")}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default UpdateOrganization;
