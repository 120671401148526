import { useState, useEffect } from "react";
import api from "@services/api";
import Loader from "@components/utils/Loader";
import { Paginator } from "@components/utils/Pagination";
import { SearchBar } from "@components/utils/SearchBar";

import { currencyToStr } from "@utils";

import { useTranslation } from "react-i18next";
import { Mixpanel } from "../../services/mixpanel";
import { MIXPANEL_EVENTS } from "../../utils/mixpanelEvents";
import { useSelector } from "react-redux";

const List = () => {
  const { t } = useTranslation();
  const [transactions, setTransaction] = useState([]);
  const [filter, setFilter] = useState({ per_page: 10, page: 1, search: "" });
  const [total, setTotal] = useState(0);
  const { organization } = useSelector((state) => state.Auth);

  useEffect(() => {
    if (!organization) return;
    getStripeTransactions();
  }, [filter, organization]);

  useEffect(() => {
    Mixpanel.track(MIXPANEL_EVENTS["page_view_transactions-stripe"]);
  }, []);

  async function getStripeTransactions() {
    const { data } = await api.post("/stripe/transaction/search", {
      per_page: filter.per_page,
      page: filter.page,
      search: filter.search,
      OrganizationId: organization._id,
    });
    setTransaction(data.transactions);
    setTotal(data.total);
  }

  if (!transactions) return <Loader />;

  return (
    <div className="mx-auto">
      <div className="py-6 px-6">
        <div className="flex flex-col bg-white rounded-xl p-6">
          <div className="text-2xl font-bold mb-5"> {t("stripe_transactions")} </div>
          <SearchBar search={filter.search} setFilter={setFilter} />
          <Table transactions={transactions} />
          <div className="flex flex-row items-center justify-center mt-4">
            <Paginator page={filter.page} setPage={(p) => setFilter({ ...filter, page: p })} last={Math.ceil(total / filter.per_page)} />
          </div>
        </div>
      </div>
    </div>
  );
};

const Table = ({ transactions }) => {
  const { t } = useTranslation();
  return (
    <div className="overflow-x-auto mt-8 flow-root min-w-full align-middle overflow-hidden rounded-lg border">
      <table className="min-w-full">
        <thead className="border-b">
          <tr>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-500 uppercase">
              {t("creation_date")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-bold text-gray-500 uppercase">
              {t("name")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-bold text-gray-500 uppercase">
              {t("client")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-500 uppercase">
              {t("amount")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-500 uppercase">
              {t("fees")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-500 uppercase">
              {t("status")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-500 uppercase">
              {t("type")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-500 uppercase">
              {t("account")}
            </th>
          </tr>
        </thead>
        <tbody className="bg-white">
          {transactions.map((transaction) => (
            <tr key={transaction._id}>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                <div>{transaction.created.slice(0, 10)}</div>
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                <div className="">{transaction.description?.substring(0, 50)}...</div>
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                <div className="">{transaction.Client?.companyName}</div>
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                <div className="flex items-center gap-1">
                  <Amount amount={transaction.amount} currency={currencyToStr(transaction.currency)} />
                </div>
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">{transaction.fee} </td>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">{transaction.status && t(`transactionStripe.status_${transaction.status}`)}</td>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900 capitalize">{transaction.type ? t(`stripe_transaction.status.${transaction.type}`) : null}</td>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">{transaction.accountName}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const Amount = ({ amount, currency }) => {
  let color = amount > 0 ? "bg-green-50 text-green-700 border-[1px] border-green-100" : "bg-red-50 text-red-700 border-[1px] border-red-100";
  return (
    <div className={`px-2 py-1 rounded-sm flex justify-center items-center text-sm font-bold ${color}`}>
      {amount}
      {currency}
    </div>
  );
};

export default List;
