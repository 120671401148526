import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { HiMagnifyingGlass } from "react-icons/hi2";
import { useSelector } from "react-redux";

import api from "@services/api";
import Modal from "@components/utils/Modal";
import { STRIPE_OAUTH_CLIENT_ID } from "../../config";

import { useTranslation } from "react-i18next";
import { Mixpanel } from "../../services/mixpanel";
import { MIXPANEL_EVENTS } from "../../utils/mixpanelEvents";
const AddBank = ({ isOpen = false, closeModal = () => {} }) => {
  const { t } = useTranslation();
  const [banks, setBanks] = useState([]);
  const [selectedBank, setSelectedBank] = useState(null);
  const [search, setSearch] = useState("");
  const query = new URLSearchParams(useLocation().search);
  const navigate = useNavigate();
  const { organization } = useSelector((state) => state.Auth);

  useEffect(() => {
    Mixpanel.track(MIXPANEL_EVENTS.page_view_bank_add);
  }, []);

  async function searchBank() {
    const res = await api.post("/nordigen-institution/search", { search, limit: 15 });
    setBanks(res.data.banks);
  }

  async function sendStripeConnection(code) {
    try {
      await api.post("/stripe/connect", { code, OrganizationId: organization._id });
      toast.success(t("toast.sucess.stripe"));
      navigate("/bank");
    } catch (e) {
      if (e.code == "REQUISITION_ALREADY_EXISTS") return toast.error(t("myintegrations.requisition_already_exists"));
      toast.error(t("toast.error"));
    }
  }

  useEffect(() => {
    if (query.get("stripe_redirect")) sendStripeConnection(query.get("code"));
    searchBank();
  }, [search]);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      if (!selectedBank) return toast.error(t("toast.error.select_bank"));
      const res = await api.post("/nordigen-institution/connect", {
        bankId: selectedBank.nordigenId,
        OrganizationId: organization._id,
      });
      window.open(res.link, "_blank");
    } catch (e) {
      console.log(e);
      toast.error(t("toast.error"), e.code);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={() => closeModal()} className="" innerClassName="flex max-w-[60rem] w-[60rem] h-[calc(100vh-5rem)] max-h-[calc(100vh-5rem)]">
      <div className="flex flex-col p-8 w-full">
        <div className="text-left text-[26px] font-semibold">{t("myintegrations.addbank.connect_bank_title")}</div>
        <div className="flex flex-col gap-2 mt-10">
          <SearchBar search={search} setSearch={setSearch} />
          <div className="overflow-hidden mt-5">
            <div className="overflow-y-scroll h-96">
              {banks.map((bank) => (
                <div
                  key={bank._id}
                  className={`flex justify-between items-center border-t border-gray-300 text-gray-800 px-3 py-2 cursor-pointer mr-2 ${
                    selectedBank?._id == bank._id ? "bg-primary text-white" : ""
                  }`}
                  onClick={() => setSelectedBank(bank)}>
                  <div>
                    <img src={bank.logo} width={20} height={20} className="mr-3" />
                    {bank.name}
                  </div>
                  <div>{bank.bic}</div>
                </div>
              ))}
            </div>
          </div>
          <div className="flex items-center justify-end">
            <button type="submit" className="w-72 px-6 py-3 mt-5 bg-primary text-white rounded-md hover:bg-gray-700 focus:bg-blue" onClick={handleSubmit}>
              {t("myintegrations.addbank.connect_bank_addbank")}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const SearchBar = ({ search, setSearch }) => {
  const handleChange = (e) => {
    e.persist();
    setSearch(e.target.value);
  };
  return (
    <div className="relative rounded-md shadow-sm w-1/3">
      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
        <HiMagnifyingGlass className="h-5 w-5 text-gray-400" aria-hidden="true" />
      </div>
      <input
        type="text"
        name="search"
        value={search}
        onChange={handleChange}
        className="block w-full h-10 rounded-md border border-gray-300 pl-10 focus:border-sky-700 md:text-sm"
        placeholder="Search a bank"
      />
    </div>
  );
};

export default AddBank;
